import jwt_decode from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import useIsMobile from '../hooks/useIsMobile';

function Suporte() {
    const history = useNavigate();
    const [windowSize] = useIsMobile();
    const [sent, setSent] = useState(false);
    const [values, setValues] = useState({
        proposta: '',
        assunto: '',
        textoAssunto: '',
    });

    const isMobile = windowSize <= 768;

    const handleSent = () => {
        // clearForm();
        setSent(true);
    };

    const clearForm = () => {
        setValues({ proposta: '', assunto: '', textoAssunto: '' });
    };

    const userRef = useRef<any>(null);
    const accessRef = useRef<any>({});

    useEffect(() => {
        const user =
            sessionStorage.getItem('@grana-extra') !== 'null'
                ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

        userRef.current = user;

        const decodeJwt: any = jwt_decode(user.access_token);

        userRef.current = user.access_token;
        accessRef.current = {
            ...accessRef.current,
            email: decodeJwt?.response.email,
            name: decodeJwt?.response.name,
            phone_number: decodeJwt?.response.phone_number,
        };
    }, []);

    return (
        <div className="p-4 md:p-10">
            {isMobile ? (
                <>
                    <div className="w-full md:flex justify-between">
                        <h4 className="title font-bold title-campanhas">
                            Área de suporte
                        </h4>
                        <p className="text-black-2 mt-5 mb-5 font-bold">
                            Está com dúvidas sobre suas propostas? <br /> Nos
                            envie uma mensagem!
                        </p>
                    </div>
                    {/* <button
                        onClick={() => history('chamado')}
                        className="mt-5 w-full py-4 ml-2 px-10 text-white font-bold bg-primary hover:bg-primary-hover radius-button"
                    >
                        Abrir chamado
                    </button> */}
                </>
            ) : (
                <>
                    <div className="w-full flex justify-between">
                        <h4 className="title font-bold title-campanhas">
                            Área de suporte
                        </h4>
                        {/* <button
                            onClick={() => history('chamado')}
                            className="py-2 ml-2 px-10 text-white font-bold bg-primary hover:bg-primary-hover radius-button"
                        >
                            Abrir chamado
                        </button> */}
                    </div>
                    <p className="text-black-2 mt-5 mb-5 font-bold">
                        Está com dúvidas sobre suas propostas? <br /> Nos envie
                        uma mensagem!
                    </p>
                </>
            )}

            <div className="bg-white rounded-2xl mt-8 shadow-xl">
                <iframe
                    id="ifrm1"
                    name="ifrm1"
                    title="ifrm1"
                    style={{ display: 'none' }}
                />
                <form
                    action={process.env.REACT_APP_SALESFORCE_URL}
                    // action="https://sulamerica--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                    method="POST"
                    target="ifrm1"
                    onSubmit={handleSent}
                >
                    {/* <input type="hidden" name="orgid" value="00D55000000Tmsg" /> */}
                    <input
                        type="hidden"
                        name="orgid"
                        value={process.env.REACT_APP_SALESFORCE_ORG_ID}
                    />
                    <input type="hidden" name="retURL" value="http://" />

                    <div className="hidden">
                        <label htmlFor="type">Type</label>
                        <select
                            id="type"
                            name="type"
                            defaultValue="Area Logada"
                        >
                            <option value="Area Logada" defaultChecked>
                                Area Logada
                            </option>
                            <option value="Home">Home</option>
                        </select>
                        <label htmlFor="recordType">
                            Tipo de registro do caso
                        </label>
                        <select id="recordType" name="recordType">
                            <option
                                value={
                                    process.env.REACT_APP_SALESFORCE_RECORD_TYPE
                                }
                            >
                                Case Grana Extra
                            </option>
                        </select>
                    </div>

                    <label htmlFor="name" className="hidden">
                        Nome do contato
                    </label>
                    <input
                        id="name"
                        name="name"
                        value={accessRef.current?.name}
                        className="hidden"
                    />
                    <label htmlFor="email" className="hidden">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        value={accessRef.current?.email}
                        className="hidden"
                    />
                    <label htmlFor="phone" className="hidden">
                        Telefone
                    </label>
                    <input
                        id="phone"
                        name="phone"
                        value={accessRef.current?.phone_number}
                        className="hidden"
                    />
                    <div className="w-[100%] sm:w-[55%] flex flex-col gap-4 p-8">
                        <div>
                            <label
                                className="text-normal mb-2 block"
                                htmlFor="proposal"
                            >
                                Número da Proposta:
                            </label>
                            <input
                                id={
                                    process.env
                                        .REACT_APP_SALESFORCE_PROPOSAL_NUMBER
                                }
                                name={
                                    process.env
                                        .REACT_APP_SALESFORCE_PROPOSAL_NUMBER
                                }
                                maxLength={100}
                                type="text"
                                required
                                className="border w-[100%] sm:w-1/2 border-dark-blue rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                // value={values.proposta}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        proposta: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div>
                            <label
                                className="text-normal mb-2 block"
                                htmlFor="subject"
                            >
                                Assunto
                            </label>
                            <select
                                id="subject"
                                name="subject"
                                required
                                // value={values.assunto}
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        assunto: e.target.value,
                                    })
                                }
                            >
                                <option
                                    value="Atualização de cadastro"
                                    selected
                                >
                                    Atualização de cadastro
                                </option>
                                <option value="Proposta não remunerada">
                                    Proposta não remunerada
                                </option>
                                <option value="Problemas com cartão">
                                    Problemas com cartão
                                </option>
                                <option value="Exclusão de proposta">
                                    Exclusão de proposta
                                </option>
                                <option value="Demais assuntos">
                                    Demais assuntos
                                </option>
                            </select>
                            {/* <input
                                id="subject"
                                maxLength={80}
                                size={20}
                                name="subject"
                                required
                                type="text"
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                // value={values.assunto}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        assunto: e.target.value,
                                    })
                                }
                            /> */}
                        </div>
                        <div>
                            <label className="hidden" htmlFor="description">
                                Descrição
                            </label>
                            <textarea
                                name="description"
                                className="border border-dark-blue h-[9.5rem] resize-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Assunto"
                                required
                                // value={values.textoAssunto}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        textoAssunto: e.target.value,
                                    })
                                }
                            ></textarea>
                        </div>
                        {sent && (
                            <div
                                className={`flex gap-2 items-center ${
                                    sent ? 'visible' : 'invisible'
                                }`}
                            >
                                <p>Sua solicitação foi enviada com sucesso! </p>
                                <img src="/images/check.svg" alt="" />
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            transform: 'matrix(1, 0, 0, -1, 0, 0)',
                            boxShadow: '0px 0px 5px rgba(50, 50, 50, 0.25)',
                        }}
                        className="flex
                                  w-full
                                  h-[6.75rem]
                                  items-center
                                  justify-between
                                  p-8
                                  mt-[4rem]
                                "
                    >
                        <Link
                            to="/area-logada/"
                            className=" w-[100%] sm:w-[9.5rem] mt-4 sm:mt-0"
                        >
                            <button
                                style={{
                                    transform: 'matrix(1, 0, 0, -1, 0, 0)',
                                }}
                                type="button"
                                name="submit"
                                className="p-[13px 47px] h-12 w-[100%] sm:w-[9.5rem] text-center text-[#001E64] border-[#001E64] border-[1px] font-bold hover:opacity-80 radius-button"
                            >
                                Voltar
                            </button>
                        </Link>
                        <button
                            style={{ transform: 'matrix(1, 0, 0, -1, 0, 0)' }}
                            type="submit"
                            name="submit"
                            className="p-[13px 47px] h-12 w-[100%] sm:w-[9.5rem] text-center text-white font-bold bg-primary hover:bg-primary-hover radius-button"
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Suporte;
