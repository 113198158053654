import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const defaultHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
};

export const api = axios.create({
    baseURL: apiUrl,
    headers: { ...defaultHeaders },
});
