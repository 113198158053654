/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLocalStorage } from '../hooks/useLocalStorage';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { api } from '../services/api';

type UserProps = {
    cpf: string;
    senha: string;
    permanecerConectado?: boolean;
    token?: string;
};

type AuthContextData = {
    userLocalStorage: UserProps | null;
    userSessionStorage: UserProps | null;
    login(cpf: string, senha: string, permanecerConectado: boolean): any;
    logout(): void;
};

type AuthContextProviderProps = {
    children: ReactNode;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const [userLocalStorage, setUserLocalStorage] = useLocalStorage(
        '@grana-extra',
        null
    );

    const [userSessionStorage, setUserSessionStorage] = useSessionStorage(
        '@grana-extra',
        null
    );

    const navigate = useNavigate();

    const login = async (
        cpf: string,
        senha: string,
        permanecerConectado: boolean
    ) => {
        const lastVisitedUrl = localStorage.getItem('lastVisitedUrl');

        try {
            const response = await api.post('/v1/auth/login', {
                cpf,
                password: senha,
            });

            permanecerConectado
                ? setUserLocalStorage({
                      ...response.data,
                  })
                : setUserSessionStorage({
                      ...response.data,
                  });

            sessionStorage.removeItem('modal-suspensao-closed');
            navigate(lastVisitedUrl ? lastVisitedUrl : 'area-logada/');
            localStorage.removeItem('lastVisitedUrl');

            return response;
        } catch (error: any) {
            console.log(error);
            if (error.code !== 'ERR_NETWORK') {
                return error.response;
            }
            return error.code;
        }
    };

    const logout = () => {
        window.localStorage.removeItem('@grana-extra');
        window.sessionStorage.removeItem('@grana-extra');
    };

    const value = useMemo(
        () => ({
            userLocalStorage,
            userSessionStorage,
            login,
            logout,
        }),
        [userLocalStorage, userSessionStorage]
    );
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
