import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Banner() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div
            className={`${isMobile ? 'banner-home-mobile' : 'banner-home'}`}
            id="banner"
        >
            <div
                className="md:grid grid-cols-2 gap-4 h-full"
                style={
                    !isMobile
                        ? { display: 'grid', gridTemplateColumns: '1fr 1.44fr' }
                        : undefined
                }
            >
                {!isMobile ? <div></div> : undefined}
                <div className="flex items-center pr-3 md:pr-20 py-20">
                    <div>
                        <img
                            alt=""
                            className={`${isMobile ? 'ml-3' : 'none'}`}
                            style={{
                                maxWidth: `${isMobile ? '250px' : 'auto'}`,
                            }}
                            src="/images/logo-grana-extra.svg"
                        ></img>
                        <div className="mt-10 ml-3">
                            {isMobile ? (
                                <>
                                    <h4
                                        className="font-bold text-white text-4xl pr-10 title"
                                        style={{ lineHeight: '3rem' }}
                                    >
                                        A Campanha Grana Extra estará suspensa a
                                        partir de 1º de junho de 2024.
                                    </h4>
                                    <br />
                                    <h4
                                        className="font-bold text-white text-4xl pr-10 title"
                                        style={{ lineHeight: '3rem' }}
                                    >
                                        Para informações adicionais sobre suas
                                        propostas, faça o login.
                                    </h4>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </>
                            ) : (
                                <>
                                    <h4
                                        className="font-bold text-white text-4xl title"
                                        style={{ lineHeight: '3rem' }}
                                    >
                                        A Campanha Grana Extra estará suspensa a
                                        partir de 1º de junho de 2024.
                                    </h4>
                                    <br />
                                    <h4
                                        className="font-bold text-white text-4xl title"
                                        style={{ lineHeight: '3rem' }}
                                    >
                                        Para informações adicionais sobre suas
                                        propostas, faça o login.
                                    </h4>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <img
                alt=""
                style={{
                    position: 'absolute',
                    width: '100%',
                    top: `${isMobile ? '905px' : '654px'}`,
                }}
                src="/images/linha.svg"
            ></img>
        </div>
    );
}

export default Banner;
