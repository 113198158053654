import React, { createContext, ReactNode, useState } from 'react';
type RatingProps = {
    ratingState: number;
    setRatingState: React.Dispatch<React.SetStateAction<number>>;
};
type RatingContextProviderProps = {
    children: ReactNode;
};
const initialValues = {
    ratingState: 0,
    setRatingState: () => {
        ('');
    },
};

const RatingFormContext = createContext<RatingProps>(initialValues);

const RatingContextProvider = ({ children }: RatingContextProviderProps) => {
    const [ratingState, setRatingState] = useState(initialValues.ratingState);

    return (
        <RatingFormContext.Provider
            value={{
                ratingState,
                setRatingState,
            }}
        >
            {children}
        </RatingFormContext.Provider>
    );
};
export { RatingContextProvider };
export default RatingFormContext;
