import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccordionLayout from '../components/AccordionLayout';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

function DuvidasFrequentes() {
    const history = useNavigate();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleSetActiveIndex = (index: number) => {
        if (index === activeIndex) return setActiveIndex(null);
        setActiveIndex(index);
    };

    const customText18 = () => {
        return (
            <>
                <p>
                    Comunique imediatamente o fato entrando no site da
                    IncentiCard, no menu "Perda ou roubo", ou ligando para a{' '}
                    <span className="font-bold">
                        Central de Atendimento: 4000 1099
                    </span>{' '}
                    para regiões metropolitanas{' '}
                    <span className="font-bold">
                        (ligações de telefones fixos) ou (19) 3113 1480
                    </span>{' '}
                    para demais regiões e ligações de telefone celular.{' '}
                    <span className="font-bold">
                        Segunda a sexta, das 9h às 16h.
                    </span>{' '}
                    Seu cartão será bloqueado e não poderá mais ser utilizado
                    para compras. Se você possuir saldo no cartão, poderá
                    adquirir um novo e solicitar a transferência do dinheiro.
                </p>
                <br />
                <p>
                    TARIFA DE REEMISÃO: Em casos de perda do cartão, roubo ou
                    perda da senha, ou qualquer solicitação de cancelamento e
                    estorno do valor para um novo cartão Incenticard quando é
                    necessário, a taxa cobrada pelo serviço será de R$ 22,00
                    somados ao montante de 5% sobre o valor do estorno,
                    condicionado ao mínimo de R$ 1,50 (um real e cinquenta
                    centavos) debitados do saldo disponível no cartão. O novo
                    cartão será enviado via Correios ao endereço informado pelo
                    premiado.
                </p>
            </>
        );
    };

    const customText21 = () => {
        return (
            <>
                <p>O crédito pode ser utilizado enquanto tiver saldo.</p>
                <p>
                    Importante: Após 90 dias de inatividade, ou seja, sem que
                    haja qualquer movimentação no cartão (crédito ou débito de
                    valores), será debitada uma tarifa mensal diretamente do
                    saldo disponível do cartão no valor de R$ 5,00. Se não
                    houver saldo suficiente para o débito total da tarifa, a
                    tarifa debitada será o saldo disponível no cartão
                    Incenticard. Após ocorrer qualquer movimentação, seja de
                    crédito ou débito de valores, o prazo de 90 dias é renovado
                    automaticamente.
                </p>
            </>
        );
    };

    const data = [
        {
            title: '1. Não consigo me cadastrar no site da campanha',
            content: `Se você não consegue se cadastrar no site, é provável que você já esteja cadastrado. 
                Neste caso, clique no botão "Entrar" no topo da página principal e digite seu e-mail e senha. 
                Caso você ainda não esteja cadastrado, clique no botão "Cadastrar" na página principal, preencha com os dados solicitados, cadastre seu e-mail e senha.`,
        },
        {
            title: '2. Não consigo acessar o site da campanha com o meu e-mail e senha',
            content:
                'Certifique-se que você esteja inserindo seu e-mail e senha corretamente. Caso não consiga, você pode recuperar sua senha clicando no botão ""Entrar"" no topo da página principal do site e, em seguida, clique em "Esqueceu a senha". ​Depois, digite o e-mail que você cadastrou no site e aguarde para receber sua senha direto na sua caixa de e-mail. Caso não se recorde do e-mail cadastrado, entre em contato com seu gestor da SulAmérica informando seu CPF.',
        },
        {
            title: '3. Como mudar meus dados de cadastro (E-mail/senha)?',
            content:
                'Para alterar sua senha, e-mail ou alguma informação sobre seu cadastro, clique no botão "Entrar" e digite seu e-mail e senha. Após, clique no botão "Editar Perfil" no topo da página e edite as informações necessárias. Para finalizar, clique no botão "Cadastrar".',
        },
        {
            title: '4. Cadastrei a proposta no site,e o status não foi alterado. O que devo fazer?',
            content:
                'Se você cadastrou a proposta no site e recebeu o e-mail de confirmação, você deve aguardar até que a proposta seja implantada pela SulAmérica e acompanhar semanalmente a situação da proposta no site clicando no botão "minhas propostas", observando se o seu crédito já está disponível.',
        },
        {
            title: '5. Como consultar os créditos das propostas cadastradas?',
            content:
                'Para consultar os créditos das propostas que você cadastrou, basta clicar no botão "Minhas Propostas". Nesta página, você pode consultar a situação das suas propostas assim como valores e data de liberação dos créditos. Caso você não esteja apto para receber o crédito de acordo com o regulamento, você também será avisado nessa página. As informações disponibilizadas na área "minhas propostas" serão atualizadas semanalmente.',
        },
        {
            title: '6. Como recebo meu cartão?',
            content:
                'A partir da data de pagamento informada pela SulAmérica em até 24 horas úteis você receberá um e-mail do destinatário Incenticard com o seu cartão virtual.',
        },
        {
            title: '7. Como consulto o saldo/extrato do meu cartão?',
            content:
                'Você pode consultar seu saldo e extrato gratuitamente no site www.incenticard.com.br. Após se cadastrar, basta acessar a área "Meus Cartões", dentro do menu "Minha conta" para visualizar o saldo, além de outras informações.',
        },
        {
            title: '8. Como posso utilizar o cartão virtual Incenticard',
            content:
                'Você pode utilizar o seu cartão para realizar compras online com a função CRÉDITO à vista, fazer recargas de celular, pagamento de contas e transferência bancária.',
        },
        {
            title: '9. Como faço o desbloqueio do meu cartão?',
            content:
                'Você receberá um e-mail com o seu cartão virtual enviado pela Incenticard, e deverá clicar no botão "Clique Aqui para Desbloquear". Ao ser direcionado para a página de login é necessário informar o seu e-mail que está cadastrado no site da campanha, o seu CPF e realizar o aceite de termos. Você fará o preenchimento dos seus dados pessoais e cadastrará uma senha de acesso que será solicitada quando for utilizar o seu cartão. Após este passo o site enviará um token para finalização do desbloqueio para o seu e-mail ou celular que precisará ser informado no campo token e validará o desbloqueio do seu cartão.',
        },
        {
            title: '10. Após o desbloqueio do cartão, em quanto tempo o crédito estará disponível?',
            content:
                'Após o desbloqueio do seu cartão o crédito estará disponível para utilização em até 48 horas úteis.',
        },
        {
            title: '11. A senha do cartão é solicitada toda vez que utilizar o cartão?',
            content:
                'Sim. A senha do seu Incenticard será requisitada todas as vezes em que você utilizar o seu cartão.',
        },
        {
            title: '12. No site Incenticard, quantas vezes posso digitar a senha incorretamente? Após essas tentativas, o que devo fazer?',
            content:
                'São no máximo três tentativas. Na quarta vez que a senha for digitada errada, o acesso será bloqueado e você precisará entrar em contato de Seg. à Sex. das 9hs às 16hs com a Central de Atendimento 4000 1099 para regiões metropolitanas ou (19) 3113 1480 para demais regiões e ligações de telefone celular.',
        },
        {
            title: '13. Posso realizar saques em caixas eletrônicos?',
            content:
                'Não. Seu cartão não possui a função saque habilitada, sendo limitada às compras via internet em sites nacionais, à recarga de celular, pagamento de contas, e transferências do crédito através do site www.incenticard.com.br.',
        },
        {
            title: '14. É possível fazer compras por telefone ou em lojas físicas com o meu cartão?',
            content:
                'Você pode realizar compras com o seu Incenticard por telefone e pela internet em sites nacionais ou internacionais. Em lojas físicas não há a possibilidade de realizar suas compras uma vez que o cartão é virtual.',
        },
        {
            title: '15. Tentei utilizar o meu cartão e ele não foi aceito. O que houve?',
            content:
                'Primeiro, certifique-se de que o saldo disponível é suficiente para a compra que está realizando. Se for, verifique se o cartão está sendo utilizado na função Crédito (Verifique se o site onde você está fazendo suas compras aceita Cartões de Crédito).Caso o problema persista, você precisará entrar em contato de Seg. à Sex. das 9hs às 16hs com a Central de Atendimento 4000 1099 para regiões metropolitanas ou (19) 3113 1480 para demais regiões e ligações de telefone celular.',
        },
        {
            title: '16. Posso parcelar compras com meu Incenticard?',
            content:
                'Não. O serviço de parcelas não é possível no cartão Incenticard, pois ele não é um cartão de crédito, mas sim um pré-pago. Suas compras devem ser realizadas à vista e você precisa ter saldo disponível para efetuar a transação.',
        },
        {
            title: '17. Como posso cancelar o meu cartão?',
            content:
                'Entre em contato de Seg. à Sex. das 9hs às 16hs com a Central de Atendimento 4000 1099 para regiões metropolitanas ou (19) 3113 1480 para demais regiões e ligações de telefone celular.',
        },
        {
            title: '18. Qual o prazo de utilização dos créditos?',
            content:
                'Após 90 dias de inatividade, ou seja, sem que haja qualquer movimentação no cartão (crédito ou débito de valores), será debitada uma tarifa mensal diretamente do saldo disponível do cartão no valor de R$ 5,00. Se não houver saldo suficiente para o débito total da tarifa, a tarifa debitada será o saldo disponível no cartão Incenticard. Após ocorrer qualquer movimentação, seja de crédito ou débito de valores, o prazo de 90 dias é renovado automaticamente.',
        },
        {
            title: '19. Qual a validade do meu cartão Incenticard virtual?',
            content:
                'O cartão é válido por seis meses e não é possível recarregá-lo ou adicionar créditos. Então sempre que você receber uma premiação da campanha receberá um novo cartão.',
        },
        // {
        //     title: '20. Como desbloquear o novo cartão em caso de perda, roubo ou reposição?',
        //     content:
        //         'Você pode desbloquear o seu cartão entrando no site www.incenticard.com.br, na área "Meus Cartões" e na seção "Minha Conta". Basta tê-lo em mãos para inserir os dados solicitados. O prazo limite para desbloqueio é até a data de validade que consta no cartão (MasterCard).',
        // },
        // {
        //     title: '21. Qual o prazo de utilização dos créditos?',
        //     content: customText21(),
        // },
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <>
            <NavBar />
            <div style={{ background: '#F5F5F5' }}>
                <div className="pb-16">
                    <div className="w-full pl-5 pr-32 md:px-48 py-10 bg-dark-blue text-white">
                        <div className="">
                            <h3 className="title title-campanhas font-bold">
                                Dúvidas frequentes
                            </h3>
                        </div>
                    </div>
                    <div className="w-full flex justify-center pt-10 px-5">
                        <div style={{ width: '50rem' }}>
                            <button
                                onClick={() => history('/')}
                                className="py-2 mr-2 mb-7 px-10 text-dark-blue font-bold border border-dark-blue radius-button"
                            >
                                Voltar
                            </button>
                            {data.map((item, index) => (
                                <AccordionLayout
                                    key={index}
                                    title={item.title}
                                    index={index}
                                    activeIndex={activeIndex}
                                    setActiveIndex={handleSetActiveIndex}
                                >
                                    {item.content}
                                </AccordionLayout>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default DuvidasFrequentes;
