import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BackLink } from '../components/BackLink';
import LayoutLeads from '../components/LayoutLeads';
import { api } from '../services/api';

export const DetalhesLead = () => {
    const { idLead } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    const getData = async () => {
        try {
            setIsLoading(true);

            const { data } = await api.get(`/v1/leads/${idLead}`, {
                headers: { Authorization: user.access_token },
            });

            console.log(data);
        } catch (err) {
            console.error(err);

            setError('Erro ao carregar os detalhes do lead.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (idLead) {
            getData();
        }
    }, [idLead]);

    function renderContent(isLoading: any, error: any, idLead: any) {
        if (isLoading) {
            return <p>Carregando...</p>;
        } else if (error) {
            return <p className="text-red-500 font-bold">{error}</p>;
        } else {
            return (
                <div className="bg-white h-screen rounded-lg p-[1.88rem] shadow-lg gap-5 flex flex-col flex-wrap">
                    <div className="flex gap-6">
                        <div className="rounded-[0.9375rem] p-5 bg-[#F4F4F4] w-1/2">
                            <h2 className="font-bold text-xl mb-[0.62rem]">
                                Dados do cliente
                            </h2>
                            <p>{idLead}</p>
                        </div>
                        <div className="rounded-[0.9375rem] p-5 bg-[#F4F4F4] w-1/2">
                            <h2 className="font-bold text-xl mb-[0.62rem]">
                                Dados da campanha
                            </h2>
                            <p>{idLead}</p>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="rounded-l-[0.9375rem] px-5 py-[0.69rem] bg-[#F4F4F4] flex-1">
                            <p>
                                <b>Corretora: </b> {idLead}
                            </p>
                        </div>
                        <p className="px-8 py-[0.69rem] bg-[#001E64] rounded-r-[0.9375rem] text-white">
                            EV: {idLead}
                        </p>
                    </div>
                    <div className="flex gap-6">
                        <div className="rounded-[0.9375rem] p-5 bg-[#F4F4F4] w-1/2">
                            <p>{idLead}</p>
                        </div>
                        <div className="rounded-[0.9375rem] p-5 bg-[#F4F4F4] w-1/2">
                            <h2 className="font-bold text-xl mb-[0.62rem]">
                                Dados da campanha
                            </h2>
                            <p>{idLead}</p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <LayoutLeads>
            <div className="flex flex-col px-40 py-10 gap-[1.88rem]">
                <div className="self-start">
                    <BackLink />
                </div>
                {renderContent(isLoading, error, idLead)}
            </div>
        </LayoutLeads>
    );
};
