import { BrowserRouter } from 'react-router-dom';

import { NotificationToast } from './components/NotificationToast';
import { HeaderContextProvider } from './contexts/HeaderContext';
import { NotificationProvider } from './contexts/NotificationContext';
import AppRoutes from './routes/AppRoutes';

const targeting = () => (window.location.pathname = 'area-logada/notificacoes');

function App() {
    return (
        <>
            <BrowserRouter>
                <HeaderContextProvider>
                    <NotificationProvider>
                        <NotificationToast open={true} action={targeting}>
                            <AppRoutes />
                        </NotificationToast>
                    </NotificationProvider>
                </HeaderContextProvider>{' '}
            </BrowserRouter>
        </>
    );
}

export default App;
