import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import atentionIcon from '../assets/svgs/atention-icon.svg';
import checkIcon from '../assets/svgs/check-icon.svg';
import logo from '../assets/svgs/logo-sulamerica-white.svg';
import { CustomIcon } from '../components/CustomIcon';
import { api } from '../services/api';

export const ConfirmacaoEmail = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const confirmToken = urlParams.get('confirmToken');

    const navigate = useNavigate();

    const [email, setEmail] = useState('rodrigomoreira@email.com');
    const [emailConfirmation, setEmailConfirmation] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleEmailConfirmation = async () => {
        setIsLoading(true);

        try {
            const response = await api.post(
                `v1/notificacoes/contatos/email/verificar`,
                null,
                {
                    headers: {
                        'email-verification-token': confirmToken,
                    },
                }
            );

            console.log(response.data);

            setEmail(response.data.email);
            setEmailConfirmation(response.data.status === 'success');
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleEmailConfirmation();
    }, []);

    return !isLoading ? (
        <div className="flex flex-col items-center">
            <div className="bg-[#001E64] flex justify-between py-[30px] px-[25px] w-screen">
                <img src={logo} className="w-[123px] h-[32px]" alt="Logo" />
            </div>
            <div className="flex flex-col items-center mt-14">
                <CustomIcon
                    src={emailConfirmation ? checkIcon : atentionIcon}
                    width={48}
                    height={48}
                />
                <h2
                    style={{ color: emailConfirmation ? '#006217' : '#AA2C23' }}
                    className="font-bold text-[1.5rem] mt-5"
                >
                    {emailConfirmation
                        ? 'E-mail confirmado com sucesso!'
                        : 'Link de validação de e-mail expirado'}
                </h2>
                <p className="text-[#323232] text-[1.25rem] text-center mt-2 max-w-[40rem]">
                    {emailConfirmation
                        ? `Agora, você começará a receber notificações no endereço de e-mail registrado(${email})`
                        : ' Por favor, volte para a página de envio de e-mail e tente novamente.'}
                </p>
                <button
                    className="rounded-full bg-[#FF5000] py-3 px-12 max-h-12 text-white font-bold mt-8"
                    onClick={() => navigate('/')}
                >
                    Acessar Painel Grana Extra
                </button>
            </div>
        </div>
    ) : (
        <div className="flex flex-col items-center">
            <div className="bg-[#001E64] flex justify-between py-[30px] px-[25px] w-screen">
                <img src={logo} className="w-[123px] h-[32px]" alt="Logo" />
            </div>
            <div className="pt-[7.75rem] pb-[12rem] flex flex-col gap-7 justify-center items-center">
                <div
                    style={{ borderTopColor: 'transparent' }}
                    className="w-20 h-20 border-4 border-[#001E64] border-solid rounded-full animate-spin"
                ></div>
            </div>
        </div>
    );
};
