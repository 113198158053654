import React, { useContext, useState } from 'react';

import RatingFormContext from '../contexts/RatingContext';
import { FilledStar, Star } from './Stars';
const Rating = ({ icon, scale }: any) => {
    const { ratingState, setRatingState } = useContext(RatingFormContext);
    const buttons = [];

    const onClickRating = (idx: any) => {
        if (!isNaN(idx)) {
            // allow user to click first icon and set rating to zero if rating is already 1
            if (ratingState === 1 && idx === 1) {
                setRatingState(0);
            } else {
                setRatingState(idx);
            }
        }
    };

    const RatingIcon = ({ fill }: any) => {
        return fill ? <FilledStar /> : <Star fill={fill} />;
    };

    const RatingButton = ({ idx, fill }: any) => {
        return (
            <button
                //   variant="unstyled"
                className="mx-3"
                onClick={() => {
                    onClickRating(idx);
                }}
            >
                <RatingIcon fill={fill} />
            </button>
        );
    };

    for (let i = 1; i <= scale; i++) {
        buttons.push(
            <RatingButton key={i} idx={i} fill={i <= ratingState} value={i} />
        );
    }

    return (
        <div className="flex justify-center align-center flex-col w-[100%] bg-[#FFFFFF] mt-[1rem] py-[20px]">
            <div className="flex w-full justify-start pl-[20px]">
                <p className="mb:text-start font-bold mb:text-[0.875rem] lg:text-[1.2rem]">
                    Na sua percepção, qual a probabilidade
                    <br /> do cliente concluir a compra?
                </p>
            </div>
            <div className="flex justify-center align-center w-[100%] my-2">
                <input name="rating" type="hidden" value={ratingState} />
                {buttons}
            </div>
            <div className="flex justify-between px-8">
                <p className=" mb:text-[0.75rem] lg:text-[1rem]">Improvável</p>
                <p className=" mb:text-[0.75rem] lg:text-[1rem]">
                    Muito Provável
                </p>
            </div>
        </div>
    );
};

Rating.displayName = 'Rating';

export default Rating;
