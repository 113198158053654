/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import chevronDown from '../assets/svgs/chevron_down-icon.svg';
import chevronUp from '../assets/svgs/chevron_up-icon.svg';
import { useNotificationContext } from '../contexts/NotificationContext';
import { CustomIcon } from './CustomIcon';
import { TooltipComponent } from './Tooltip';

type NotificationType = {
    assunto: string;
    conteudo: { segmento: string | null };
    created_at: string;
    id: string;
    id_notificacao: string;
    id_usuario: string;
    lida: boolean;
    updated_at: string;
};

type HeadersIconsType = {
    icon: string;
    tolltip: string;
    action: (id: unknown[]) => void | Promise<void>;
};

type NotificationTableHeaderProps = {
    title: string;
    subtitle: string;
    data: NotificationType[];
    headersIcons: HeadersIconsType[];
};

export const NotificationTableHeader = ({
    title,
    subtitle,
    data,
    headersIcons,
}: NotificationTableHeaderProps) => {
    const {
        setStatus,
        setCurrentPage,
        setItemsPerPage,
        notificationsFiltered,
        selectAll,
        selectedItems,
        setSelectAll,
        setSelectedItems,
    } = useNotificationContext();

    const [statusLabel, setStatusLabel] = useState<string>('Status mensagem');
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    const statusOptions = ['Todas', 'Lida', 'Não lida'];

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    const toggleDropdownStatus = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    };

    const handleFilterData = async (statusFilter: string) => {
        setStatus(statusFilter);
        setStatusLabel(statusFilter);
        setCurrentPage(1);
        setItemsPerPage(5);
        toggleDropdownStatus();
    };
    const toggleSelectAll = () => {
        const updatedSelectedItems = new Set(selectedItems);

        if (!selectAll) {
            notificationsFiltered.forEach((item) =>
                updatedSelectedItems.add(item.id)
            );
        } else {
            notificationsFiltered.forEach((item) => {
                if (updatedSelectedItems.has(item.id)) {
                    updatedSelectedItems.delete(item.id);
                }
            });
        }

        setSelectedItems(updatedSelectedItems);

        setSelectAll(!selectAll);
    };

    return (
        <>
            <div className="pt-5 px-5 mb-4 md:flex justify-between">
                <div>
                    <p className="text-[#323232] font-bold text-[1.25rem]">
                        {title}
                    </p>
                    <p>{subtitle}</p>
                </div>
                <div className="relative">
                    <button
                        disabled={data.length <= 0}
                        style={{
                            opacity: data.length <= 0 && '0.5',
                            cursor: data.length <= 0 && 'not-allowed',
                        }}
                        className="whitespace-nowrap border flex justify-between items-center border-[#001E64] h-10 rounded-full mt-7 md:mt-0 w-full md:w-52 px-4"
                        onClick={() => toggleDropdownStatus()}
                    >
                        <span>{statusLabel}</span>
                        <CustomIcon
                            src={dropdownIsOpen ? chevronDown : chevronUp}
                        />
                    </button>
                    {dropdownIsOpen && (
                        <div className="absolute bg-white shadow-lg w-full rounded-lg z-10">
                            {statusOptions.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="text-[#323232] hover:text-[#146CFA] hover:bg-[#E6F7FF] hover:font-bold px-3 py-2"
                                        onClick={() => handleFilterData(item)}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        opacity: data.length <= 0 && '0.5',
                        cursor: data.length <= 0 && 'not-allowed',
                    }}
                    className="md:hidden border border-[#001E64] font-bold px-11 h-10 rounded-full mt-5 w-full flex gap-4 items-center justify-center"
                >
                    <input
                        disabled={data.length <= 0}
                        checked={selectAll}
                        type="checkbox"
                        style={{
                            cursor: data.length <= 0 && 'not-allowed',
                        }}
                        className="h-4 w-4"
                        onChange={toggleSelectAll}
                    />
                    <span>{statusLabel}</span>
                </div>
                <div className="hidden md:flex gap-5">
                    {headersIcons.map((icon, index) => {
                        return (
                            <TooltipComponent
                                tooltip={icon.tolltip}
                                key={index}
                            >
                                <button
                                    disabled={data.length <= 0}
                                    style={{
                                        opacity: data.length <= 0 && '0.5',
                                        cursor:
                                            data.length <= 0 && 'not-allowed',
                                    }}
                                    className="rounded-full bg-[#d2d2d2a3] hover:bg-[#E9EDF4] transition-opacity flex items-center justify-center p-3"
                                    onClick={() => {
                                        const idsArray =
                                            Array.from(selectedItems);
                                        icon.action(idsArray);
                                    }}
                                >
                                    <CustomIcon src={icon.icon} />
                                </button>
                            </TooltipComponent>
                        );
                    })}
                </div>
            </div>
            <hr className="mb-5" />
        </>
    );
};
