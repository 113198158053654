import { useEffect, useState } from 'react';

export const StepsLead = ({ color, text, value, ...props }: any) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [dimension, setDimension] = useState<any>();
    const isSmall = width <= 1440;
    const is1440 = width <= 1440 && width >= 1280;
    const is1280 = width <= 1280;
    useEffect(() => {
        if (is1440) {
            return setDimension('310');
        }
        if (is1280) {
            return setDimension('290');
        }
        if (!is1440 && !is1280) {
            return setDimension('460');
        }
    }, []);
    return (
        <div className="flex justify-center items-center" {...props}>
            <div>
                <svg
                    width={dimension}
                    height={isSmall ? '60' : '100'}
                    viewBox="0 0 312 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.1518 8.83642C-1.35184 5.5446 0.995789 0.80957 5.13152 0.80957H282.995C284.538 0.80957 285.995 1.52208 286.942 2.74022L310.66 33.2402C312.064 35.0456 312.064 37.5735 310.66 39.3789L286.942 69.8789C285.995 71.0971 284.538 71.8096 282.995 71.8096H5.13152C0.995786 71.8096 -1.35185 67.0746 1.15179 63.7827L19.7448 39.3364C21.1051 37.5478 21.1051 35.0714 19.7448 33.2827L1.1518 8.83642Z"
                        fill={`${color}`}
                    />
                </svg>
            </div>

            <p className="font-medium text-[#FFFF] absolute text-[2.2vh]">
                {text}
                {value ? ' (' + value + ')' : ''}
            </p>
        </div>
    );
};
