export const getDateFormatted = (dateTime: string) => {
  const newDateTime = new Date(dateTime);

  const dateFormatted = `${newDateTime.getDate()}/${
    newDateTime.getMonth() + 1
  }/${newDateTime.getFullYear()}`;

  const hours = String(newDateTime.getHours()).padStart(2, '0');
  const minutes = String(newDateTime.getMinutes()).padStart(2, '0');

  const timeFormatted = `${hours}:${minutes}`;

  return {
    date: dateFormatted,
    time: timeFormatted
  };
};
