export const meses = [
    { value: 0, mounth: 'Janeiro' },
    { value: 1, mounth: 'Fevereiro' },
    { value: 2, mounth: 'Março' },
    { value: 3, mounth: 'Abril' },
    { value: 4, mounth: 'Maio' },
    { value: 5, mounth: 'Junho' },
    { value: 6, mounth: 'Julho' },
    { value: 7, mounth: 'Agosto' },
    { value: 8, mounth: 'Setembro' },
    { value: 9, mounth: 'Outubro' },
    { value: 10, mounth: 'Novembro' },
    { value: 11, mounth: 'Dezembro' },
];
