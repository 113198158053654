import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
    isShowing: boolean;
    notClosable?: boolean;
    logo?: ReactNode;
    title?: string;
    subTitle?: string;
    children?: ReactNode;
    hide: () => void;
};

export const Modal = ({
    isShowing,
    notClosable = false,
    hide,
    title,
    logo,
    children,
    subTitle,
}: ModalProps) => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    return isShowing
        ? createPortal(
              <>
                  {isShowing ? (
                      <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full">
                              <div
                                  className={`relative ${
                                      isMobile ? 'w-[90%]' : 'w-[39rem]'
                                  }`}
                              >
                                  <div
                                      className={`p-8 justify-center items-center border-0 rounded-md shadow-lg relative flex flex-col bg-white outline-none focus:outline-none gap-4`}
                                  >
                                      {!notClosable ? (
                                          <div className="w-full flex justify-end">
                                              <button
                                                  className="bg-transparent border-0 text-black text-3xl leading-none outline-none focus:outline-none"
                                                  onClick={hide}
                                                  title="Fechar"
                                              >
                                                  <img
                                                      className="h-4 w-4"
                                                      alt=""
                                                      src="/images/icons/x_icon.svg"
                                                  />
                                              </button>
                                          </div>
                                      ) : null}
                                      {logo ? (
                                          <div className="flex w-full justify-center">
                                              <div>{logo}</div>
                                          </div>
                                      ) : null}

                                      {title ? (
                                          <div className="flex items-center justify-between rounded-t">
                                              <h3 className="text-2xl font-semibold text-black">
                                                  {title}
                                              </h3>
                                          </div>
                                      ) : null}

                                      {subTitle ? (
                                          <div className="relative flex-auto">
                                              <p className="text-black text-lg leading-relaxed">
                                                  {subTitle}
                                              </p>
                                          </div>
                                      ) : null}

                                      {children ? (
                                          <div className="flex items-center justify-end rounded-b">
                                              {children}
                                          </div>
                                      ) : null}
                                  </div>
                              </div>
                          </div>

                          <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
                      </>
                  ) : null}
              </>,
              document.body
          )
        : null;
};
