export const Star = ({ fill, ...props }: any) => {
    return (
        <div className="flex justify-center items-center" {...props}>
            <svg
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill={fill ? `fill` : 'none'}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3658 1.89403L22.0898 9.44009C22.3236 9.91317 22.7753 10.2415 23.2974 10.3174L31.6251 11.5274C32.9405 11.7184 33.4657 13.3349 32.5137 14.2628L26.488 20.1367C26.1099 20.5051 25.9375 21.0358 26.0266 21.556L27.4494 29.8502C27.6739 31.1601 26.299 32.1592 25.1225 31.5406L17.6741 27.6248C17.2068 27.3793 16.6485 27.3793 16.1813 27.6248L8.73287 31.5406C7.55638 32.1592 6.18147 31.1601 6.40634 29.8502L7.82871 21.556C7.91781 21.0358 7.74546 20.5051 7.3673 20.1367L1.34166 14.2628C0.389647 13.3349 0.914868 11.7184 2.23025 11.5274L10.5579 10.3174C11.0804 10.2415 11.5317 9.91317 11.7655 9.44009L15.4895 1.89403C16.0778 0.701974 17.7776 0.701974 18.3658 1.89403Z"
                    stroke="#BEBEBE"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export const FilledStar = ({ fill, ...props }: any) => {
    return (
        <div className="justify-center items-center flex" {...props}>
            <svg
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill={'#F6DA47'}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3658 1.89403L22.0898 9.44009C22.3236 9.91317 22.7753 10.2415 23.2974 10.3174L31.6251 11.5274C32.9405 11.7184 33.4657 13.3349 32.5137 14.2628L26.488 20.1367C26.1099 20.5051 25.9375 21.0358 26.0266 21.556L27.4494 29.8502C27.6739 31.1601 26.299 32.1592 25.1225 31.5406L17.6741 27.6248C17.2068 27.3793 16.6485 27.3793 16.1813 27.6248L8.73287 31.5406C7.55638 32.1592 6.18147 31.1601 6.40634 29.8502L7.82871 21.556C7.91781 21.0358 7.74546 20.5051 7.3673 20.1367L1.34166 14.2628C0.389647 13.3349 0.914868 11.7184 2.23025 11.5274L10.5579 10.3174C11.0804 10.2415 11.5317 9.91317 11.7655 9.44009L15.4895 1.89403C16.0778 0.701974 17.7776 0.701974 18.3658 1.89403Z"
                    stroke="#F6DA47"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
