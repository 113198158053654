import jwt_decode from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Layout from '../components/Layout';
import { Modal } from '../components/Modal';
import { ModalSuspensao } from '../components/ModalSuspensao';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../hooks/useModal';
import { ProposalProvider } from '../hooks/useProposal';

type AreaLogadaRoutesProps = {
    redirectPath?: string;
};

export const AreaLogadaRoutes = ({
    redirectPath = '/login',
}: AreaLogadaRoutesProps) => {
    const { isShowing, toggle: toggleModal } = useModal();
    const { pathname } = useLocation();
    const { logout } = useAuth();
    const hoursDiff = useRef(0);
    const [hasLayout, setHasLayout] = useState(false);

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    if (user === 'null' || user === null) {
        localStorage.setItem('lastVisitedUrl', window.location.pathname);
        return <Navigate to={redirectPath} />;
    }

    //Validar sessão do usuário de minuto a minuto (setInterval 60000)
    useEffect(() => {
        if (user?.access_token) {
            const { exp }: any = jwt_decode(user?.access_token);

            const interval = setInterval(() => {
                const date = new Date();

                if (new Date(exp * 1000) <= date) {
                    toggleModal();
                }
            }, 60000);
            return () => clearInterval(interval);
        }
    }, []);

    //Não carrega layout para rotas sem sidebar
    useEffect(() => {
        const validateLayout =
            !pathname.includes('/area-logada/leads') &&
            pathname !== '/area-logada/' &&
            pathname !== '/area-logada';

        setHasLayout(validateLayout);
    }, [pathname]);

    return (
        <ProposalProvider>
            {hasLayout ? (
                <Layout>
                    <Outlet />
                </Layout>
            ) : (
                <>
                    <Outlet />
                </>
            )}
            <Modal
                title="Sessão expirou"
                subTitle="Por favor inicie a sessão novamente."
                hide={toggleModal}
                isShowing={isShowing}
                notClosable
            >
                <a href="/login">
                    <button
                        onClick={logout}
                        className="text-[#ff5000] border-[#ff5000] border-[1px] rounded-[8.3rem] h-12 w-[17.5rem] font-bold"
                    >
                        voltar para login
                    </button>
                </a>
            </Modal>
            <ModalSuspensao />
        </ProposalProvider>
    );
};
