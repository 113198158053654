import { useEffect, useState } from 'react';

function Footer() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;
    return (
        <div>
            {isMobile ? (
                <div className="w-100 bg-primary text-white py-4 px-2">
                    <div className="flex justify-center">
                        <div
                            className="bg-black p-1 mx-2"
                            style={{ width: '90px' }}
                        >
                            <p
                                className="border p-1"
                                style={{ fontSize: '9px' }}
                            >
                                ANS - nº 006246
                            </p>
                        </div>
                        <div className="bg-black p-1">
                            <p
                                className="border p-1"
                                style={{ fontSize: '9px' }}
                            >
                                ANS - nº 006246
                            </p>
                        </div>
                    </div>
                    <p className="text-xs md:ml-16 mt-5 text-center">
                        2022 SulAmérica - Todos os direitos reservados | CNPJ:
                        01.685.053/0013-90 - Rua dos Pinheiros, 1673 - São
                        Paulo/SP - CEP: 05.422-012
                    </p>
                </div>
            ) : (
                <div className="w-100 bg-primary text-white py-10 md:px-20 flex items-center">
                    <div className="bg-black p-1 mx-2">
                        <p className="border p-1" style={{ fontSize: '9px' }}>
                            ANS - nº 006246
                        </p>
                    </div>
                    <div className="bg-black p-1">
                        <p className="border p-1" style={{ fontSize: '9px' }}>
                            ANS - nº 006246
                        </p>
                    </div>
                    <p className="text-xs md:ml-16">
                        2022 SulAmérica - Todos os direitos reservados | CNPJ:
                        01.685.053/0013-90 - Rua dos Pinheiros, 1673 - São
                        Paulo/SP - CEP: 05.422-012
                    </p>
                </div>
            )}
        </div>
    );
}

export default Footer;
