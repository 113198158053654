import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export const ModalSuspensao = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isShowing, setIsShowing] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const isMobile = width <= 768;
    const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const modalSuspensaoClosed = sessionStorage.getItem(
            'modal-suspensao-closed'
        );
        setIsShowing(!(modalSuspensaoClosed === 'true'));
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
        }
    }, [isShowing]);

    return isShowing
        ? createPortal(
              <>
                  {isShowing ? (
                      <>
                          <div
                              ref={wrapperRef}
                              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full"
                          >
                              <div
                                  className={`relative ${
                                      isMobile ? 'w-[90%]' : 'w-[40rem]'
                                  }`}
                              >
                                  <div
                                      className={`p-[40px] justify-center items-center border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none gap-4`}
                                  >
                                      <div className="flex flex-col items-center justify-end rounded-b">
                                          <div className="pb-[32px]">
                                              <img
                                                  className="h-[74.5px] w-[74.5px]"
                                                  alt=""
                                                  src="/images/icons/speaker.svg"
                                              />
                                          </div>
                                          <div className="text-[1.25rem] text-[#5A5A5A] leading-normal">
                                              Informação importante
                                          </div>
                                          <div className="text-[1.5rem] text-[#5A5A5A] leading-loose font-bold">
                                              Campanha Grana Extra será suspensa
                                          </div>
                                          <div className="text-[1rem] pt-[46px] pb-[46px] leading-normal">
                                              <div>
                                                  A Campanha Grana Extra estará
                                                  suspensa{' '}
                                                  <b>
                                                      a partir de 1º de junho de
                                                      2024.
                                                  </b>{' '}
                                                  Serão remuneradas
                                                  exclusivamente as propostas
                                                  transmitidas até 31 de maio de
                                                  2024, que sejam posteriormente
                                                  quitadas, conforme os
                                                  critérios discriminados no
                                                  regulamento vigente até maio
                                                  de 2024.
                                              </div>
                                              <br />
                                              <div>
                                                  O devido{' '}
                                                  <b>
                                                      cadastro no site do Grana
                                                      Extra é indispensável para
                                                      ocorrer o pagamento da
                                                      remuneração
                                                  </b>{' '}
                                                  aqui mencionada.
                                              </div>
                                              <br />
                                              <div>
                                                  Propostas transmitidas até 31
                                                  de maio de 2024 poderão ser
                                                  cadastradas no site do Grana
                                                  Extra até{' '}
                                                  <b>15 de julho de 2024</b> (45
                                                  dias após a data limite de
                                                  transmissão, respeitando o
                                                  prazo de validade da
                                                  proposta). Após esta data, não
                                                  será mais possível cadastrar
                                                  propostas.
                                              </div>
                                          </div>
                                          <div className="flex flex-row items-start w-[100%] gap-[12px] pb-[46px]">
                                              <input
                                                  className="border-[3px] border-solid border-[#0B234C] rounded-[4px] w-[24px] h-[24px] text-[#FF7130]"
                                                  type="checkbox"
                                                  checked={checkboxChecked}
                                                  onChange={(e) => {
                                                      setCheckboxChecked(
                                                          e.target.checked
                                                      );
                                                  }}
                                              />
                                              <div className="leading-normal">
                                                  Confirmo que li e estou ciente
                                                  desta informação.
                                              </div>
                                          </div>
                                          {checkboxChecked ? (
                                              <button
                                                  className="pr-[32px] pl-[32px] pt-[16px] pb-[16px] bg-[#FF7130] text-white font-bold rounded-[4px]"
                                                  onClick={() => {
                                                      setIsShowing(false);
                                                      sessionStorage.setItem(
                                                          'modal-suspensao-closed',
                                                          'true'
                                                      );
                                                  }}
                                              >
                                                  CONTINUAR PARA O PAINEL
                                              </button>
                                          ) : (
                                              <button className="pr-[32px] pl-[32px] pt-[16px] pb-[16px] bg-[#D2D2D2] text-white font-bold rounded-[4px]">
                                                  CONTINUAR PARA O PAINEL
                                              </button>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
                      </>
                  ) : null}
              </>,
              document.body
          )
        : null;
};
