/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';

import chevronDown from '../assets/svgs/chevron_down-icon.svg';
import chevronUp from '../assets/svgs/chevron_up-icon.svg';
import notificationIcon from '../assets/svgs/notification-icon.svg';
import { CustomIcon } from './CustomIcon';

type NotificationSideBarProps = {
    options?: {
        name: string;
        status: string;
        icon: JSX.Element;
        options: {
            name: string;
            link: string;
        }[];
    }[];
};

export const NotificationSideBar = ({ options }: NotificationSideBarProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

    const optionsDefault = options
        ? options
        : [
              {
                  name: 'Notificações',
                  status: 'open',
                  icon: <CustomIcon src={notificationIcon} />,
                  options: [
                      {
                          name: 'Configurar notificação',
                          link: '/area-logada/notificacoes/configuracoes',
                      },
                      {
                          name: 'Todas as notificações',
                          link: '/area-logada/notificacoes',
                      },
                  ],
              },
          ];

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={`flex flex-col h-auto w-[14rem] py-5 bg-[#F8F8F8] text-[#001E64] font-bold text-[0.875rem]`}
        >
            {optionsDefault?.map((link, index) => {
                return (
                    <div key={index} className="flex flex-col justify-between">
                        <div
                            className="flex gap-[8px] items-center justify-between hover:bg-[#E6F7FF] py-3 border-r-4 border-transparent hover:border-r-[#1890FF]  cursor-pointer"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => setDropDownIsOpen(!dropDownIsOpen)}
                        >
                            <div
                                className="flex gap-3 px-6"
                                style={{
                                    color: isHovered ? '#146CFA' : '#001E64',
                                }}
                            >
                                <span>{link.icon}</span>
                                <span>{link.name}</span>
                            </div>
                            <button
                                style={{
                                    color: isHovered ? '#146CFA' : '#001E64',
                                }}
                            >
                                <CustomIcon
                                    src={
                                        dropDownIsOpen ? chevronDown : chevronUp
                                    }
                                />
                            </button>
                        </div>
                        {dropDownIsOpen &&
                            link.options.map((option, cont) => {
                                return (
                                    <button
                                        key={cont}
                                        className="px-6 py-3 hover:bg-[#E6F7FF] hover:text-[#146CFA] border-r-4 border-transparent hover:border-r-[#1890FF] cursor-pointer"
                                        onClick={() =>
                                            (window.location.pathname =
                                                option.link)
                                        }
                                    >
                                        <span>{option.name}</span>
                                    </button>
                                );
                            })}
                    </div>
                );
            })}
        </div>
    );
};
