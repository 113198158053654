/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import sadFaceIcon from '../assets/sad_face-icon.png';
import { useNotificationContext } from '../contexts/NotificationContext';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { getDateFormatted } from '../utils/getDateFormatted';
import { NotificationTableFooter } from './NotificationTableFooter';
import { NotificationTableHeader } from './NotificationTableHeader';

type NotificationType = {
    assunto: string;
    conteudo: { segmento: string | null };
    created_at: string;
    id: string;
    id_notificacao: string;
    id_usuario: string;
    lida: boolean;
    updated_at: string;
};

export type NotificationTableProps = {
    isLoading: boolean;
    data: NotificationType[];
    totalData: number;
    headersIcons: {
        icon: string;
        tolltip: string;
        action: (id: unknown[]) => Promise<void>;
    }[];
    buttonsMobile: (
        | {
              icon: JSX.Element;
              label: string;
              border: string;
              action: (id: unknown[]) => Promise<void>;
              disable: boolean;
          }
        | {
              icon: JSX.Element;
              label: string;
              border: string;
              action: () => void;
              disable: boolean;
          }
    )[];
};

export const NotificationTable = ({
    isLoading,
    data,
    totalData,
    headersIcons,
    buttonsMobile,
}: NotificationTableProps) => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const {
        notificationsFiltered,
        selectAll,
        selectedItems,
        setSelectAll,
        setSelectedItems,
        resetCheckboxes,
        currentPage,
    } = useNotificationContext();

    const title = 'Acompanhamento de notificações';
    const subtitle = `Quantidade total de mensagens: ${totalData}`;

    const notificationObj = {
        'novo lead': 'Você recebeu um novo lead.',
        'novos leads': 'Você recebeu novos leads.',
        'nova venda': 'Uma venda acaba de ser concluída.',
        'novo agendamento': 'Você tem um agendamento',
        'novos agendamentos': 'Você tem novos agendamentos',
    };

    const linePerPage = [5, 10, 15, 20];

    const toggleSelectAll = () => {
        const updatedSelectedItems = new Set(selectedItems);

        if (!selectAll) {
            notificationsFiltered.forEach((item) =>
                updatedSelectedItems.add(item.id)
            );
        } else {
            notificationsFiltered.forEach((item) => {
                if (updatedSelectedItems.has(item.id)) {
                    updatedSelectedItems.delete(item.id);
                }
            });
        }

        setSelectedItems(updatedSelectedItems);

        setSelectAll(!selectAll);
    };

    const toggleSelectItem = (itemId: string) => {
        const updatedSelectedItems = new Set(selectedItems);

        if (updatedSelectedItems.has(itemId)) {
            updatedSelectedItems.delete(itemId);
        } else {
            updatedSelectedItems.add(itemId);
        }
        setSelectedItems(updatedSelectedItems);

        useEffect(() => {
            setSelectedItems(new Set());
            resetCheckboxes();
        }, [currentPage]);
    };

    return !isLoading && notificationsFiltered.length >= 1 ? (
        <div className="bg-white text-[#001E64] shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl">
            <NotificationTableHeader
                title={title}
                subtitle={subtitle}
                data={data}
                headersIcons={headersIcons}
            />
            <div className="">
                <div
                    style={{
                        maxHeight: isMobile && '500px',
                        overflow: isMobile && 'auto',
                    }}
                    className="px-4"
                >
                    <table
                        className="w-full text-[0.875rem]"
                        cellPadding="0"
                        cellSpacing="0"
                    >
                        <thead>
                            <tr
                                style={{
                                    display: isMobile && 'none',
                                }}
                            >
                                <th className="text-left pb-3 flex gap-12 px-7">
                                    <input
                                        checked={selectAll}
                                        type="checkbox"
                                        className="h-5 w-5"
                                        onChange={toggleSelectAll}
                                    />
                                    <span>Mensagem</span>
                                </th>
                                <th className="text-left pb-3 px-7">
                                    Data/hora
                                </th>
                                <th
                                    className="text-left pb-3"
                                    style={{
                                        display: isMobile && 'none',
                                    }}
                                >
                                    Status mensagens
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {notificationsFiltered.map((item, index) => {
                                return (
                                    <tr
                                        key={item.id}
                                        className="h-14"
                                        style={{
                                            borderBottom:
                                                index + 1 !==
                                                notificationsFiltered.length
                                                    ? '0.63rem solid white'
                                                    : '',
                                        }}
                                    >
                                        <td>
                                            <div
                                                className="relative rounded-lg md:rounded-none md:rounded-l-lg py-4 px-6 md:flex gap-12"
                                                style={{
                                                    background:
                                                        index % 2
                                                            ? '#D3DEED'
                                                            : '#F4F4F4',
                                                    borderLeft: `4px solid ${
                                                        item?.lida
                                                            ? '#001E64'
                                                            : '#FF5000'
                                                    }`,
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    style={{
                                                        position: isMobile
                                                            ? 'absolute'
                                                            : null,
                                                    }}
                                                    className="h-5 w-5 right-3.5 top-3.5"
                                                    onChange={() =>
                                                        toggleSelectItem(
                                                            item.id
                                                        )
                                                    }
                                                    checked={selectedItems.has(
                                                        item.id
                                                    )}
                                                />
                                                <span className="hidden md:flex">
                                                    {item.assunto}
                                                </span>
                                                <div
                                                    className="py-2 flex"
                                                    style={{
                                                        display: isMobile
                                                            ? 'flex'
                                                            : 'none',
                                                    }}
                                                >
                                                    <p className="whitespace-nowrap">
                                                        Mensagem -
                                                    </p>
                                                    &nbsp;
                                                    <strong className="underline">
                                                        {
                                                            notificationObj[
                                                                String(
                                                                    item?.assunto
                                                                ).toLowerCase()
                                                            ]
                                                        }
                                                    </strong>
                                                </div>
                                                <div
                                                    className="py-2 flex bg-red"
                                                    style={{
                                                        display: isMobile
                                                            ? 'flex'
                                                            : 'none',
                                                    }}
                                                >
                                                    Data/hora - &nbsp;
                                                    <strong>
                                                        {
                                                            getDateFormatted(
                                                                item?.updated_at
                                                            ).date
                                                        }
                                                        &nbsp;
                                                        {
                                                            getDateFormatted(
                                                                item?.updated_at
                                                            ).time
                                                        }
                                                    </strong>
                                                </div>
                                                <div
                                                    className="py-2 flex items-center"
                                                    style={{
                                                        display: isMobile
                                                            ? 'flex'
                                                            : 'none',
                                                    }}
                                                >
                                                    Status mensagens - &nbsp;
                                                    <div
                                                        className="text-white p-1.5 rounded-lg"
                                                        style={{
                                                            background:
                                                                item?.lida
                                                                    ? '#001E64'
                                                                    : '#FF5000',
                                                        }}
                                                    >
                                                        {item.lida
                                                            ? 'Lida'
                                                            : 'Não lida'}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                display: isMobile && 'none',
                                            }}
                                        >
                                            <div
                                                className="py-4 px-6"
                                                style={{
                                                    background:
                                                        index % 2
                                                            ? '#D3DEED'
                                                            : '#F4F4F4',
                                                }}
                                            >
                                                {
                                                    getDateFormatted(
                                                        item?.updated_at
                                                    ).date
                                                }
                                                &nbsp;
                                                {
                                                    getDateFormatted(
                                                        item?.updated_at
                                                    ).time
                                                }
                                            </div>
                                        </td>
                                        <td
                                            className="text-left w-[18%]"
                                            style={{
                                                display: isMobile && 'none',
                                            }}
                                        >
                                            <div
                                                className="rounded-r-lg py-4"
                                                style={{
                                                    background:
                                                        index % 2
                                                            ? '#D3DEED'
                                                            : '#F4F4F4',
                                                }}
                                            >
                                                <span
                                                    className="text-white p-1.5 rounded-lg"
                                                    style={{
                                                        background: item?.lida
                                                            ? '#001E64'
                                                            : '#FF5000',
                                                    }}
                                                >
                                                    {item.lida
                                                        ? 'Lida'
                                                        : 'Não lida'}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="md:hidden">
                    <hr className="mt-5" />
                    <div className="p-4">
                        {buttonsMobile.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        marginBottom:
                                            index !== buttonsMobile.length &&
                                            '0.63rem',
                                    }}
                                >
                                    <button
                                        disabled={item.disable}
                                        style={{
                                            color: item.border,
                                            borderColor: item.border,
                                            cursor: item.disable
                                                ? 'not-allowed'
                                                : 'pointer',
                                            opacity: item.disable && '0.5',
                                        }}
                                        className="md:hidden border font-bold px-11 h-10 rounded-full w-full flex items-center justify-center"
                                        onClick={() => {
                                            const idsArray =
                                                Array.from(selectedItems);
                                            item.action(idsArray);
                                        }}
                                    >
                                        <div className="flex gap-2">
                                            <span className="flex items-center">
                                                {item.icon}
                                            </span>
                                            <span>{item.label}</span>
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <NotificationTableFooter
                linePerPage={linePerPage}
                totalData={totalData}
            />
        </div>
    ) : !isLoading && notificationsFiltered.length < 1 ? (
        <div className="bg-white text-[#001E64] shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl">
            <div className="p-10 flex flex-col gap-7 justify-center items-center">
                <img src={sadFaceIcon} alt="sad-face" width={75} height={75} />
                <p className="text-[#AAA]">
                    Parece que você não tem nenhuma notificação.
                </p>
            </div>
        </div>
    ) : (
        <div className="bg-white text-[#001E64] shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl">
            <div className="p-16 flex flex-col gap-7 justify-center items-center">
                <div
                    style={{ borderTopColor: 'transparent' }}
                    className="w-20 h-20 border-4 border-[#001E64] border-solid rounded-full animate-spin"
                ></div>
            </div>
        </div>
    );
};
