import { useState } from 'react';

import { useNotificationContext } from '../contexts/NotificationContext';
import { NotificationPagination } from './NotificationPagination';

type NotificationTableFooterProps = {
    linePerPage: number[];
    totalData: number;
};

export const NotificationTableFooter = ({
    linePerPage,
    totalData,
}: NotificationTableFooterProps) => {
    const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage } =
        useNotificationContext();

    const [limitValue, setLimitValue] = useState(itemsPerPage);

    return (
        <>
            <hr className="mt-5" />
            <footer className="py-6 px-5 md:flex justify-between text-[#5A5A5A]">
                <div className="mb-3 flex gap-5 items-center">
                    <p>Linhas por página</p>
                    <select
                        className="py-2 px-3 w-20 focus:outline-none focus:ring-0 border border-[#ECE6F0] rounded-md"
                        value={limitValue}
                        onChange={(e) => {
                            const limitSelected = e.target.value;
                            const limitNumber = Number(limitSelected);
                            setLimitValue(limitNumber);
                            setItemsPerPage(limitNumber);
                            setCurrentPage(1);
                        }}
                    >
                        {linePerPage.map((opt, index) => {
                            return (
                                <option key={index} value={opt}>
                                    {opt}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <NotificationPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalData}
                />
            </footer>
        </>
    );
};
