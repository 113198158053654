import { useEffect, useState } from 'react';

interface CustomIconProps {
    src: string;
    width?: number;
    height?: number;
    color?: string;
}

export const CustomIcon = ({
    src,
    width = 24,
    height = 24,
    color = 'currentColor',
}: CustomIconProps) => {
    const [svgContent, setSvgContent] = useState<string | null>(null);

    useEffect(() => {
        const fetchSvg = async () => {
            try {
                const response = await fetch(src);

                const svgData = await response.text();

                setSvgContent(svgData);
            } catch (error) {
                console.error('Error fetching SVG:', error);
            }
        };

        fetchSvg();
    }, [src]);

    if (!svgContent) {
        return null;
    }

    const modifiedSvg = svgContent
        .replace(/width=".*?"/, `width="${width}"`)
        .replace(/height=".*?"/, `height="${height}"`)
        .replace(/fill=".*?"/, `fill="${color}"`);

    return (
        <div
            className="flex hover:bg-none"
            dangerouslySetInnerHTML={{
                __html: modifiedSvg,
            }}
        />
    );
};
