import { paginationItems } from '../utils/paginate';

export type PaginationProps = {
    currentPage: number;
    lastPage: number;
    maxLength: number;
    fnc?: any;
    setCurrentPage: any;
};

export const Pagination = ({
    currentPage,
    lastPage,
    maxLength,
    fnc,
    setCurrentPage,
}: PaginationProps) => {
    const pageNums = paginationItems(currentPage + 1, lastPage, maxLength);

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
        fnc && fnc({ page: currentPage - 1 });
    };

    const changePage = (page: number) => {
        setCurrentPage(page);
        fnc && fnc({ page: page });
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
        fnc && fnc({ page: currentPage + 1 });
    };

    return (
        <div className="flex gap-4">
            <button disabled={currentPage === 0} onClick={prevPage}>
                {'<'}
            </button>
            {pageNums.map((pageNum, idx) => (
                <button
                    className={`${
                        currentPage === pageNum - 1
                            ? 'bg-[#146CFA] text-white'
                            : 'bg-transparent text-[#323232]'
                    } rounded-md cursor-pointer hover:opacity-80 transition-all w-8 h-8`}
                    key={idx}
                    disabled={isNaN(pageNum)}
                    onClick={() => {
                        changePage(pageNum - 1);
                    }}
                >
                    {!isNaN(pageNum) ? pageNum : '...'}
                </button>
            ))}
            <button disabled={currentPage + 1 === lastPage} onClick={nextPage}>
                {'>'}
            </button>
        </div>
    );
};
