import { useHeaderContext } from '../contexts/HeaderContext';
import { useMediaQuery } from '../hooks/useMediaQuery';
import NavBarAreaLogada from './NavBarAreaLogada';
import { NotificationButton } from './NotificationButton';
import { NotificationSideBar } from './NotificationSideBar';
import Sidebar from './Sidebar';

const Layout = ({ children }: any) => {
    const pathName = window.location.pathname;

    const { elementSize } = useHeaderContext();

    const isMobile = useMediaQuery('(max-width: 768px)');

    return (
        <>
            <NotificationButton
                rightSpace={isMobile ? '4rem' : `${elementSize}px`}
                topSpace={isMobile ? '2rem' : '2.25rem'}
            >
                <NavBarAreaLogada />
            </NotificationButton>
            <div className="flex flex-auto">
                {pathName.includes('/notificacoes') ? <></> : <Sidebar />}

                <div
                    className="grow w-[100%]"
                    style={{ background: '#F4F4F4' }}
                >
                    <div
                        className={
                            !pathName.includes('/notificacoes')
                                ? 'm-0 md:m-5'
                                : ''
                        }
                    >
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
