import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid';
import {
    FormEvent,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { api } from '../services/api';

function RedefinicaoSenha() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [width, setWidth] = useState<number>(window.innerWidth);

    const [params] = useSearchParams();

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(
        'Ocorreu um erro. Por favor, tente novamente em instantes'
    );

    const [loading, setLoading] = useState(false);

    const [exibirSenha, setExibirSenha] = useState(false);
    const [exibirConfirmacao, setExibirConfirmacao] = useState(false);

    const senha = useRef({});

    senha.current = watch('senha', '');

    const confirmar_senha = useRef({});

    confirmar_senha.current = watch('confirmar_senha', '');

    const strongPasswordRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
    );

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const onSubmit = async (data: any) => {
        const token = params.get('change_password_token');

        const user_id = params.get('id');

        try {
            setLoading(true);
            await api.post('/v1/auth/reset-password', {
                newPassword: data.senha,
                user_id,
                token,
            });

            setSuccess(true);
            setLoading(false);
        } catch (error: any) {
            if (error.response.status === 401) {
                setErrorMessage(
                    'Usuário ou token inválido/expirado. Favor solicitar a redefinição de senha novamente.'
                );
            }

            if (error.response.status === 403) {
                setErrorMessage(
                    'Usuário inativo. Favor tente fazer o login para reenviar a ativação para seu e-mail.'
                );
            }

            setLoading(false);
            setError(true);
        }
    };

    const handleExibirSenha = () => {
        setExibirSenha(!exibirSenha);
    };

    const handleExibirConfirmacao = () => {
        setExibirConfirmacao(!exibirConfirmacao);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (!params.get('change_password_token') || !params.get('id')) {
            setError(true);

            return;
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <NavBar hideBtnCadastrar hideBtnEntrar />

            <div>
                <div
                    className={`w-full py-20 px-5 md:p-20 md:h-screen ${
                        isMobile ? 'bg-login-mobile' : 'bg-login'
                    } md:grid grid-cols-2 gap-4`}
                    style={{ minHeight: '550px' }}
                >
                    <div>
                        <h4 className="title title-regulamento font-bold text-white mb-10">
                            {params.get('tipo') === 'redefinir_senha'
                                ? 'Redefinir senha'
                                : 'Cadastrar senha'}
                        </h4>
                    </div>
                    <div
                        className={`bg-white rounded-3xl py-10 px-5 md:px-12 text-black ${
                            isMobile
                                ? 'h-auto'
                                : success
                                ? 'max-h-[25rem]'
                                : errors.confirmar_senha || errors.senha
                                ? 'max-h-[36rem]'
                                : 'max-h-[32rem]'
                        }`}
                        style={{ maxWidth: '520px', zIndex: 2 }}
                    >
                        <div className="w-full flex justify-center">
                            <img alt="" src="/images/logo-colorido.jpg"></img>
                        </div>
                        {success ? (
                            <div className="w-full flex flex-col items-center justify-center gap-4 mt-4">
                                <CheckCircleIcon className="h-20 w-20 text-success" />
                                <p className="text-[#006217] font-bold text-lg w-[10rem] text-center">
                                    {params.get('tipo') === 'redefinir_senha'
                                        ? 'Senha redefinida com sucesso!!'
                                        : 'Senha cadastrada com sucesso!!'}
                                </p>
                                <Link
                                    to="/login"
                                    className="w-full flex justify-center items-center"
                                >
                                    <button className="bg-[#FF5000] w-2/3  font-bold h-[3.25rem] text-white flex items-center text-center justify-center radius-button">
                                        Ir para o login
                                    </button>
                                </Link>
                            </div>
                        ) : error ? (
                            <div className="w-full flex flex-col items-center justify-center gap-4 mt-10">
                                <InformationCircleIcon className="h-20 w-20 text-[#AA2C23]" />
                                <p className="text-[#AA2C23] font-bold text-lg w-auto max-w-[20rem] text-center">
                                    {errorMessage}
                                </p>
                            </div>
                        ) : (
                            <form
                                className="w-full mt-10"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="mb-5 mt-5">
                                    <label
                                        className="text-normal mb-2 flex items-center justify-between"
                                        htmlFor="senha"
                                    >
                                        <span>Digite aqui sua nova senha</span>
                                        <button
                                            className="text-xs text-dark-blue"
                                            type="button"
                                            onClick={handleExibirSenha}
                                        >
                                            {exibirSenha
                                                ? 'Esconder senha'
                                                : 'Exibir senha'}
                                        </button>
                                    </label>
                                    <input
                                        className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="senha"
                                        type={`${
                                            exibirSenha ? 'text' : 'password'
                                        }`}
                                        title="Mínimo seis caracteres (uma letra
                                        maiúscula e um carácter especial)"
                                        placeholder="Senha"
                                        {...register('senha', {
                                            required: true,
                                            pattern: strongPasswordRegex,
                                            minLength: {
                                                value: 6,
                                                message:
                                                    'Mínimo seis caracteres (uma letra maiúscula e um carácter especial)',
                                            },
                                        })}
                                    />
                                    {errors.senha && (
                                        <p className="text-red-600">
                                            Digite aqui sua nova senha
                                        </p>
                                    )}
                                </div>
                                <div className="mb-5 mt-5">
                                    <label
                                        className="text-normal mb-2 flex items-center justify-between"
                                        htmlFor="novaSenha"
                                    >
                                        <span>
                                            Confirme aqui sua nova senha
                                        </span>
                                        <button
                                            className="text-xs text-dark-blue"
                                            type="button"
                                            onClick={handleExibirConfirmacao}
                                        >
                                            {exibirConfirmacao
                                                ? 'Esconder senha'
                                                : 'Exibir senha'}
                                        </button>
                                    </label>
                                    <input
                                        className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="confirmar_senha"
                                        title="Mínimo seis caracteres (uma letra
                                        maiúscula e um carácter especial)"
                                        type={`${
                                            exibirConfirmacao
                                                ? 'text'
                                                : 'password'
                                        }`}
                                        placeholder="Confirmar senha"
                                        {...register('confirmar_senha', {
                                            required: true,
                                            validate: (value) =>
                                                value === senha.current,
                                        })}
                                    />
                                    {(errors.confirmar_senha ||
                                        errors.senha) && (
                                        <p className="text-red-600">
                                            {senha.current !==
                                            confirmar_senha.current
                                                ? 'Senhas digitadas não coincidem'
                                                : 'Confirme aqui sua nova senha'}
                                        </p>
                                    )}
                                </div>
                                <p>
                                    <b>Sua senha</b> deve conter no mínimo 6
                                    caracteres:{' '}
                                    <span className="text-[#243d79] font-bold">
                                        letras maiúsculas, minúscula, números e
                                        2 caracteres especiais
                                        (!,%,&,@,#,$,^,*,?,_,~)
                                    </span>
                                </p>
                                <button className="w-full mt-5 py-3 px-12 text-lg text-white font-bold bg-primary radius-button">
                                    {loading ? (
                                        <ClipLoader size={20} />
                                    ) : (
                                        'Confirmar'
                                    )}
                                </button>
                            </form>
                        )}
                    </div>
                </div>
                <img
                    alt=""
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        marginTop: '-12rem',
                        width: '100%',
                        display: `${isMobile ? 'none' : 'block'}`,
                    }}
                    src="/images/linha3.svg"
                />
            </div>

            <Footer />
        </>
    );
}

export default RedefinicaoSenha;
