import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import arrowRightIcon from '../assets/svgs/arrow_right-icon.svg';
import closeIcon from '../assets/svgs/close-icon.svg';
import { useNotificationContext } from '../contexts/NotificationContext';
import { api } from '../services/api';
import { getTextWithBold } from '../utils/getTextWithBold';
import { isJson } from '../utils/isJson.util';
import { CustomIcon } from './CustomIcon';

type NotificationToastProps = {
    open: boolean;
    action: () => void;
    children: ReactNode;
};

export const NotificationToast = ({
    open,
    action,
    children,
}: NotificationToastProps) => {
    const { openNotifications } = useNotificationContext();
    const location = useLocation();

    const pathName = window.location.pathname;

    const [lastNotificationRead, setLastNotificationRead] = useState([]);

    const [notShowNotificationToast, setNotShowNotificationToast] =
        useState(false);
    const [isNotificationsPopoverOpen, setIsNotificationsOpen] = useState(open);

    const [isLoading, setIsLoading] = useState(false);

    const userString =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? sessionStorage.getItem('@grana-extra') || '{}'
            : localStorage.getItem('@grana-extra') || '{}';

    const user = JSON.parse(userString);

    const getLastNotifications = async () => {
        setIsLoading(true);

        if (!user?.access_token) {
            setIsLoading(false);
            return;
        }

        try {
            const res = await api.get(
                `v1/notificacoes/notificacoes-tela?lida=false&_limit=${1}&_offset=${0}`,
                { headers: { Authorization: user.access_token } }
            );

            setLastNotificationRead(res.data._embedded._result);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const style = {
        color: '#FF5000',
        fontWeight: '700',
    };

    const notificationObj = {
        'novo lead': 'Você recebeu um novo lead.',
        'novos leads': 'Você recebeu novos leads.',
        'nova venda': 'Uma venda acaba de ser concluída.',
        'novo agendamento': 'Você tem um agendamento',
        'novos agendamentos': 'Você tem novos agendamentos',
    };

    useEffect(() => {
        if (isNotificationsPopoverOpen === false) {
            localStorage.setItem(
                'lastClosedNotification',
                JSON.stringify(lastNotificationRead[0])
            );
        }
    }, [isNotificationsPopoverOpen]);

    useEffect(() => {
        getLastNotifications();
    }, []);

    useEffect(() => {
        const lastClosedNotificationJSON = localStorage.getItem(
            'lastClosedNotification'
        );

        const lastClosedNotification = isJson(lastClosedNotificationJSON)
            ? JSON.parse(lastClosedNotificationJSON)
            : undefined;

        const showNewNotification =
            !lastClosedNotification?.created_at ||
            new Date(lastNotificationRead[0]?.created_at) >
                new Date(lastClosedNotification?.created_at);

        setNotShowNotificationToast(
            pathName.includes('/notificacoes') ||
                pathName.length <= 13 ||
                !pathName.startsWith('/area-logada') ||
                openNotifications ||
                !isNotificationsPopoverOpen ||
                !showNewNotification
        );
    }, [
        location,
        openNotifications,
        lastNotificationRead,
        isNotificationsPopoverOpen,
    ]);

    return (
        <div>
            <div
                style={{
                    display:
                        notShowNotificationToast ||
                        lastNotificationRead.length < 1
                            ? 'none'
                            : 'block',
                    borderLeft: `9px solid ${style.color}`,
                }}
                className="bg-white w-[91%] md:w-[27rem] pl-10 pr-7 py-5 rounded-2xl shadow-[0px_0px_5px_rgba(50,50,50,0.25)] fixed bottom-4 right-5 z-10"
            >
                {isLoading ? (
                    <div className="py-5 flex items-center justify-center">
                        <div
                            style={{ borderTopColor: 'transparent' }}
                            className="w-12 h-12 border-4 border-[#001E64] border-solid rounded-full animate-spin"
                        ></div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center">
                            <ul className="list-disc max-w-[13rem] md:max-w-none">
                                <li style={style} className="text-[1.5rem]">
                                    {lastNotificationRead?.map(
                                        (item, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="text-[#333] text-[1rem] font-normal"
                                                >
                                                    {
                                                        notificationObj[
                                                            String(
                                                                item?.assunto
                                                            ).toLowerCase()
                                                        ]
                                                    }
                                                </span>
                                            );
                                        }
                                    )}
                                </li>
                            </ul>
                            <button
                                onClick={() => setIsNotificationsOpen(false)}
                            >
                                <CustomIcon
                                    src={closeIcon}
                                    width={12}
                                    height={12}
                                />
                            </button>
                        </div>
                        <button
                            style={style}
                            className="flex gap-2 underline mt-5"
                            onClick={() => action()}
                        >
                            Ver mais detalhes
                            <CustomIcon
                                src={arrowRightIcon}
                                width={20}
                                height={20}
                                color={style.color}
                            />
                        </button>
                    </>
                )}
            </div>
            {children}
        </div>
    );
};
