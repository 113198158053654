export const premiacoes = [
    {
        title: 'SAÚDE PME',
        items: [
            {
                label: 'Vidas',
                color: '#000000',
                values: [
                    '3 a 5 vidas',
                    '6 a 10 vidas',
                    '11 a 29 vidas',
                    '30 a 99 vidas',
                    '100 a 199 vidas',
                ],
            },
            {
                label: 'Propostas',
                color: '#FF5000',
                values: [
                    'R$ 600,00',
                    'R$ 700,00',
                    'R$ 800,00',
                    'R$ 1.400,00',
                    'R$ 1.800,00',
                ],
            },
            {
                label: 'Com.Adicional débito em conta corrente',
                color: '#001E64',
                values: [
                    'R$ 650,00',
                    'R$ 750,00',
                    'R$ 850,00',
                    'R$ 1.450,00',
                    'R$ 1.850,00',
                ],
            },
        ],
    },
    {
        title: 'SAÚDE PME + ODONTO',
        items: [
            {
                label: 'Vidas',
                color: '#000000',
                values: [
                    '3 a 5 vidas',
                    '6 a 10 vidas',
                    '11 a 29 vidas',
                    '30 a 99 vidas',
                    '100 a 199 vidas',
                ],
            },
            {
                label: 'Propostas',
                color: '#FF5000',
                values: [
                    'R$ 800,00',
                    'R$ 850,00',
                    'R$ 1.000,00',
                    'R$ 1.900,00',
                    'R$ 2.300,00',
                ],
            },
            {
                label: 'Com.Adicional débito em conta corrente',
                color: '#001E64',
                values: [
                    'R$ 900,00',
                    'R$ 950,00',
                    'R$ 1.100,00',
                    'R$ 2.000,00',
                    'R$ 2.400,00',
                ],
            },
        ],
    },
    // {
    //     title: 'CAPITAL GLOBAL',
    //     items: [
    //         {
    //             label: 'Vidas',
    //             color: '#000000',
    //             values: [
    //                 '3 a 5 vidas',
    //                 '6 a 10 vidas',
    //                 '11 a 29 vidas',
    //                 '30 a 99 vidas',
    //             ],
    //         },
    //         {
    //             label: 'Propostas',
    //             color: '#FF5000',
    //             values: ['R$ 100,00', 'R$ 150,00', 'R$ 200,00', 'R$ 250,00'],
    //         },
    //     ],
    // },
    // {
    //     title: 'PROTEÇÃO SAÚDE',
    //     items: [
    //         {
    //             label: 'Valor Min de Prêmio/Mês',
    //             values: [
    //                 'R$ 10 a R$ 24,99',
    //                 'R$ 25 a R$ 49,99',
    //                 'R$ 50 a R$ 99,99',
    //                 'R$ 100 a R$ 199,99',
    //             ],
    //         },
    //         {
    //             label: 'Prot. Saúde/Vida individual',
    //             values: [
    //                 'R$ 10,00',
    //                 'R$ 25,00',
    //                 'R$ 50,00',
    //                 'R$ 100,00',
    //                 'R$ 200,00',
    //             ],
    //         },
    //         {
    //             label: 'DG¹ ou DIT²',
    //             values: [
    //                 'R$ 10,00',
    //                 'R$ 50,00',
    //                 'R$ 100,00',
    //                 'R$ 200,00',
    //                 'R$ 400,00',
    //             ],
    //         },
    //     ],
    // },
];
