const formatCel = (number = '') => {
    if (!number) return '';

    let formating = String(number).replace(/\D/g, '');

    formating = formating.replace(/^0/, '');

    if (formating.length >= 12) {
        formating = formating.slice(2);
        if (formating.length === 10) {
            formating = formating.replace(
                /^(\d\d)(\d{4})(\d{4}).*/,
                '($1) $2-$3'
            );
            return formating;
        }
        formating = formating.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (formating.length === 11) {
        formating = formating.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (formating.length > 10) {
        formating = formating.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        console.log(formating);
    } else if (formating.length > 5) {
        formating = formating.replace(
            /^(\d\d)(\d{4})(\d{0,4}).*/,
            '($1) $2-$3'
        );
    } else if (formating.length > 2) {
        formating = formating.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
        formating = formating.replace(/^(\d*)/, '($1)');
    }
    return formating;
};

export const getTelephoneOrCellPhoneNumber = (telephone: any, cell: any) => {
    const telephoneFormatted = telephone?.replace(/\D/g, '');
    const cellFormatted = cell?.replace(/\D/g, '');

    let phoneNumber = '';

    if (
        cellFormatted &&
        telephoneFormatted &&
        telephoneFormatted?.length >= 10 &&
        cellFormatted?.length >= 10
    ) {
        phoneNumber = `${formatCel(cellFormatted)} / ${formatCel(
            telephoneFormatted
        )}`;
    } else if (telephoneFormatted && telephoneFormatted?.length >= 10) {
        phoneNumber = `${formatCel(telephoneFormatted)}`;
    } else if (cellFormatted && cellFormatted?.length >= 10) {
        phoneNumber = `${formatCel(cellFormatted)}`;
    } else {
        phoneNumber = '';
    }

    return phoneNumber;
};
