import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from 'react';

type NotificationType = {
    assunto: string;
    conteudo: { segmento: string | null };
    created_at: string;
    id: string;
    id_notificacao: string;
    id_usuario: string;
    lida: boolean;
    updated_at: string;
};

type NotificationContextType = {
    toggleNotifications: () => void;
    openNotifications: boolean;
    notificationsFiltered: NotificationType[];
    setNotificationsFiltered: Dispatch<SetStateAction<any[]>>;
    status: string;
    setStatus: Dispatch<SetStateAction<string>>;
    selectAll: boolean;
    setSelectAll: Dispatch<SetStateAction<boolean>>;
    selectedItems: Set<unknown>;
    setSelectedItems: Dispatch<SetStateAction<Set<unknown>>>;
    resetCheckboxes: () => void;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    itemsPerPage: number;
    setItemsPerPage: Dispatch<SetStateAction<number>>;
    offset: number;
};

type NotificationContextProviderProps = {
    children: ReactNode;
};

const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider = ({
    children,
}: NotificationContextProviderProps) => {
    const [openNotifications, setOpenNotifications] = useState(false);
    const [notificationsFiltered, setNotificationsFiltered] = useState([]);

    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState(new Set());

    const [status, setStatus] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(5);

    const offset = (currentPage - 1) * itemsPerPage + 1;

    const resetCheckboxes = () => {
        setSelectAll(false);
    };

    const toggleNotifications = () => {
        setOpenNotifications(!openNotifications);
    };

    return (
        <NotificationContext.Provider
            value={{
                toggleNotifications,
                openNotifications,
                notificationsFiltered,
                setNotificationsFiltered,
                status,
                setStatus,
                selectAll,
                setSelectAll,
                selectedItems,
                setSelectedItems,
                resetCheckboxes,
                currentPage,
                setCurrentPage,
                itemsPerPage,
                setItemsPerPage,
                offset,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => {
    const context = useContext(NotificationContext);

    return context;
};
