import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';

const Sidebar = () => {
    const [open] = useState(false);
    const location = useLocation();
    const { logout } = useAuth();

    const Menus = [
        {
            title: 'Início',
            path: '/area-logada/',
            src: <IconHome active={location.pathname === '/area-logada/'} />,
        },
        {
            title: 'Suporte',
            path: '/area-logada/suporte',
            src: (
                <IconSuporte
                    active={location.pathname === '/area-logada/suporte'}
                />
            ),
        },
    ];

    return (
        <>
            <div
                style={{
                    width: '225px',
                    background: '#F8F8F8',
                }}
                className={`${
                    open ? 'w-60' : 'w-fit'
                } hidden sm:block relative duration-300 dark:border-gray-600 py-5 dark:bg-slate-800`}
            >
                <h4 className="text-dark-blue font-bold pl-5">
                    Painel Grana Extra
                </h4>
                <Link to="/">
                    <div className={`flex ${open && 'gap-x-4'} items-center`}>
                        <img
                            src="/public/images/logo-branco.png"
                            alt=""
                            className="pl-2"
                        />
                        {open && (
                            <span className="text-xl font-medium whitespace-nowrap dark:text-white">
                                Goal Quest
                            </span>
                        )}
                    </div>
                </Link>

                <ul className="pt-6 pl-3 ">
                    {Menus.map((menu, index) => (
                        <Link to={menu.path} key={index}>
                            <li
                                className={`text-sm flex h-12 items-center justify-between gap-x-3 py-3 pl-2 text-base cursor-pointer dark:text-white hover:opacity-80
                                   ${
                                       location.pathname === menu.path &&
                                       'text-blue font-bold'
                                   }`}
                            >
                                <div className="flex gap-4 items-center">
                                    <span className="text-2xl">{menu.src}</span>
                                    <span
                                        className={`text-dark-blue
                                      ${
                                          location.pathname === menu.path &&
                                          'text-blue font-bold'
                                      }                                
                                    `}
                                    >
                                        {menu.title}
                                    </span>
                                </div>
                                {location.pathname === menu.path && (
                                    <div className="bg-blue h-12 w-[5px] rounded-l-lg" />
                                )}
                            </li>
                        </Link>
                    ))}
                    <Link to="/">
                        <li className="text-sm flex h-12 items-center justify-between gap-x-3 py-3 pl-2 text-base cursor-pointer dark:text-white hover:opacity-80">
                            <button
                                className="flex gap-4 items-center"
                                onClick={() => logout()}
                            >
                                <span className="text-2xl">
                                    <IconSair />
                                </span>
                                <span className="text-dark-blue">Sair</span>
                            </button>
                        </li>
                    </Link>
                </ul>
            </div>
        </>
    );
};

interface IIcons {
    active: boolean;
}

function IconHome({ active }: IIcons) {
    return (
        <svg
            width="22"
            height="25"
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.3061 22.4477C20.3061 22.9188 19.9264 23.3005 19.4606 23.3005H15.3727V17.7815C15.3727 17.4059 15.3252 17.0332 15.2303 16.6724C15.1413 16.3235 15.0108 15.9896 14.8387 15.6735C14.0734 14.2632 12.6019 13.3837 11 13.3837C8.97681 13.3837 7.26807 14.7731 6.76969 16.6545C6.67476 17.0153 6.62433 17.3939 6.62433 17.7815V23.3005H2.53937C2.07066 23.3005 1.69094 22.9188 1.69094 22.4477V9.63878C1.69094 9.38534 1.80367 9.14681 1.99649 8.98581L10.4571 1.89854C10.6143 1.76735 10.8072 1.70175 11 1.70175C11.1928 1.70175 11.3827 1.76735 11.5399 1.89854L20.0005 8.98581C20.1963 9.14681 20.3061 9.38534 20.3061 9.63878V22.4477ZM13.5839 23.3005H8.41613V17.7815C8.41613 17.6205 8.43096 17.4595 8.46062 17.3075C8.68312 16.0999 9.73624 15.1846 11 15.1846C11.9463 15.1846 12.8155 15.7004 13.2665 16.5382C13.394 16.7708 13.486 17.0242 13.5364 17.2836C13.569 17.4476 13.5839 17.6146 13.5839 17.7815V23.3005ZM21.0863 7.67986L12.6227 0.592594C11.6823 -0.197531 10.3147 -0.197531 9.37433 0.592594L0.913698 7.67986C0.332253 8.16587 0 8.87847 0 9.63878V22.4477C0 23.8551 1.13916 25 2.53937 25H19.4606C20.8608 25 22 23.8551 22 22.4477V9.63878C22 8.87847 21.6648 8.16587 21.0863 7.67986Z"
                fill={`${active ? '#0066F3' : '#001E64'}`}
            />
        </svg>
    );
}

function IconSuporte({ active }: IIcons) {
    return (
        <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.93945 12.7256V10.891C2.93945 5.42678 7.36634 0.99958 12.8309 0.99958C15.5632 0.99958 18.0353 2.10709 19.8283 3.89689C21.618 5.68668 22.7256 8.15876 22.7256 10.891V12.7256"
                stroke={`${active ? '#0066F3' : '#001E64'}`}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 17.1815C1 20.1011 3.36672 22.4678 6.28624 22.4678V11.8953C3.36672 11.8953 1 14.262 1 17.1815Z"
                stroke={`${active ? '#0066F3' : '#001E64'}`}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.5792 17.1815C24.5792 20.1011 22.2125 22.4678 19.293 22.4678V11.8953C22.2125 11.8953 24.5792 14.262 24.5792 17.1815Z"
                stroke={`${active ? '#0066F3' : '#001E64'}`}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function IconSair() {
    return (
        <svg
            width="22"
            height="25"
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.7645 25C9.22364 25 5.5293 21.3081 5.5293 16.77C5.5293 16.262 5.94143 15.8505 6.44969 15.8505C6.95796 15.8505 7.37009 16.262 7.37009 16.77C7.37009 20.2937 10.2387 23.1604 13.7645 23.1604C17.2906 23.1604 20.1592 20.2937 20.1592 16.77V8.22975C20.1592 4.70628 17.2906 1.83957 13.7645 1.83957C10.2387 1.83957 7.37009 4.70628 7.37009 8.22975C7.37009 8.73768 6.95796 9.14954 6.44969 9.14954C5.94143 9.14954 5.5293 8.73768 5.5293 8.22975C5.5293 3.6919 9.22364 1.90735e-06 13.7645 1.90735e-06C18.3054 1.90735e-06 22 3.6919 22 8.22975V16.77C22 21.3081 18.3054 25 13.7645 25Z"
                fill="#001E64"
            />
            <path
                d="M11.367 13.3497C9.21526 13.3497 2.95852 13.3521 0.943256 13.3482C0.356362 13.347 -0.00236198 13.0147 1.17074e-05 12.4972C0.00268211 11.9819 0.361703 11.6578 0.953641 11.6569C2.8155 11.6531 8.91885 11.6554 10.781 11.6554H11.3569C10.8617 11.16 10.4407 10.7395 10.0199 10.3184C9.83094 10.129 9.63155 9.94781 9.45649 9.74588C9.14079 9.38146 9.16334 8.88006 9.49328 8.56457C9.80928 8.26212 10.3 8.24255 10.6439 8.55449C11.0092 8.88569 11.348 9.24596 11.6972 9.59436C12.4257 10.322 13.1559 11.0476 13.8807 11.7785C14.3929 12.2947 14.3911 12.714 13.8736 13.2338C12.8589 14.2535 11.8406 15.2693 10.822 16.2846C10.3629 16.7418 9.85645 16.7955 9.48557 16.4332C9.11171 16.0676 9.15651 15.5531 9.60929 15.0988C10.1638 14.5423 10.7228 13.9905 11.367 13.3497Z"
                fill="#001E64"
            />
        </svg>
    );
}

export default Sidebar;
