import { InformationCircleIcon } from '@heroicons/react/outline';
import { FormEvent, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import { CustomCookieConsent } from '../components/CustomCookieConsent';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { useAuth } from '../contexts/AuthContext';
import useIsMobile from '../hooks/useIsMobile';
import { api } from '../services/api';
import { cpfMask } from '../utils/masks';

function Login() {
    const history = useNavigate();
    const [windowSize] = useIsMobile();
    const isMobile = +windowSize <= 768;

    const captchaRef = useRef<ReCAPTCHA>(null);

    const user = useRef(
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? sessionStorage.getItem('@grana-extra')
            : localStorage.getItem('@grana-extra')
    );

    const blurredEmail = useRef('');

    const { login } = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    const [errorLogin, setErrorLogin] = useState(false);

    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const [isSentEmail, setSentEmail] = useState(false);

    const [form, setForm] = useState<any>({
        cpf: {
            value: '',
            error: '',
        },
        senha: {
            value: '',
            error: '',
        },
        permanecerConectado: false,
    });

    const [captchaError, setCaptchaError] = useState(false);

    const [resendEmail, setResendEmail] = useState(false);

    const [sendLegacyEmail, setSendLegacyEmail] = useState(false);

    const [exibirSenha, setExibirSenha] = useState(false);

    const handleChangeInput = (field: string, value: any) => {
        resetCheckLogin();

        const newForm = {
            ...form,
            [field]: {
                ...form[field],
                value: value,
            },
        };

        setForm(newForm);
    };

    const resetCheckLogin = () => {
        setResendEmail(false);
        setSentEmail(false);
        setErrorLogin(false);
        setCaptchaError(false);
        setSendLegacyEmail(false);
    };

    const captchaHasError = () => {
        setCaptchaError(true);
    };

    const checkCaptcha = () => {
        const captchaToken = captchaRef.current?.getValue();

        if (!captchaToken) {
            captchaHasError();
            return false;
        } else return true;
    };

    const handleExibirSenha = () => {
        setExibirSenha(!exibirSenha);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        resetCheckLogin();

        const { cpf, senha, permanecerConectado } = form;

        setForm({
            ...form,
            cpf: {
                ...form.cpf,
                error: '',
            },
            senha: {
                ...form.senha,
                error: '',
            },
        });

        let formValidate = {};

        if (!cpf.value) {
            formValidate = {
                ...form,
                cpf: {
                    ...form.cpf,
                    error: 'Campo obrigatório',
                },
            };
        }
        if (!senha.value) {
            formValidate = {
                ...form,
                ...formValidate,
                senha: {
                    ...form.senha,
                    error: 'Campo obrigatório',
                },
            };
        }

        if (!cpf.value || !senha.value) return setForm(formValidate);

        if (!checkCaptcha()) {
            return;
        }

        try {
            setIsLoading(true);

            const response = await login(
                cpf.value,
                senha.value,
                permanecerConectado
            );

            if (response === 'ERR_NETWORK') {
                setErrorLogin(true);

                setIsLoading(false);

                return;
            }

            if (response.status === 403) {
                setResendEmail(true);
            } else if (response.status === 422) {
                setSendLegacyEmail(true);

                blurredEmail.current = response.data.blurredEmail;
            } else {
                setForm({
                    cpf: {
                        ...form.cpf,
                        error: 'Usuário e/ou senha inválidos',
                    },
                    senha: {
                        ...form.senha,
                        error: 'Usuário e/ou senha inválidos',
                    },
                });
            }
        } catch (error: any) {
            setIsLoading(false);
            console.log(error);
            if (error.response.status) {
                setForm({
                    ...form,
                    senha: {
                        ...form.senha,
                        error: 'Usuário e/ou senha inválidos',
                    },
                });
            }
        }

        setIsLoading(false);
    };

    const handleReenviarEmail = async (tipo: string) => {
        const { cpf } = form;

        try {
            setIsSendingEmail(true);

            tipo === 'confirmacao'
                ? await api.post('/v1/email/confirmation-email', {
                      cpf: cpf.value,
                  })
                : await api.post('/v1/email/legacy/reset-password', {
                      cpf: cpf.value,
                  });

            setIsSendingEmail(false);
            setSentEmail(true);
        } catch (error: any) {
            console.log(error);

            toast.error(
                `Erro ${error.code}. Por favor, tente novamente em instantes, ou entre em contato.`
            );

            setIsSendingEmail(false);
        }
    };

    useEffect(() => {
        if (user.current) {
            const jwtString = user.current ?? '';

            const jwt = JSON.parse(jwtString);

            if (jwt?.access_token) {
                history('/area-logada/');
            }
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [sendLegacyEmail]);

    return (
        <>
            <NavBar hideBtnCadastrar hideBtnEntrar />
            <div>
                <div
                    className={`w-full px-5 py-16 md:p-20 ${
                        isMobile ? 'bg-login-mobile' : 'bg-login'
                    } md:grid grid-cols-2 gap-4`}
                >
                    <div>
                        {isMobile ? (
                            <h4 className="title title-regulamento font-bold text-white mb-5 md:mb-10">
                                Área <br /> de acesso
                            </h4>
                        ) : (
                            <h4 className="title title-regulamento font-bold text-white mb-5 md:mb-10">
                                Área de acesso
                            </h4>
                        )}
                        <button
                            onClick={() => history('/')}
                            className="py-2 mr-2 mb-14 px-10 text-white font-bold border border-white radius-button"
                        >
                            Voltar
                        </button>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                        className="bg-white rounded-3xl py-10 px-5 md:px-12 text-black"
                        style={{ maxWidth: '520px', zIndex: 2 }}
                    >
                        <div className="w-full flex justify-center">
                            <img alt="" src="/images/logo-colorido.jpg"></img>
                        </div>
                        {errorLogin ? (
                            <div className="bg-[#ffccd0] mt-8 flex justify-between items-center rounded-sm text-[#AA2C23] py-3 px-4">
                                <p className="w-3/5">
                                    Ocorreu um erro. Por favor, tente novamente
                                    em instantes
                                </p>
                                <button onClick={() => setErrorLogin(false)}>
                                    x
                                </button>
                            </div>
                        ) : null}
                        <div className="mb-5 mt-10">
                            <label className="text-normal mb-2" htmlFor="cpf">
                                CPF
                            </label>
                            <MaskedInput
                                mask={cpfMask}
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="cpf"
                                type="text"
                                onChange={(e) =>
                                    handleChangeInput('cpf', e.target.value)
                                }
                                placeholder="CPF"
                            />
                            {form.cpf.error && (
                                <span className="text-sm text-error">
                                    {form.cpf.error}
                                </span>
                            )}
                        </div>
                        <div className="mb-3">
                            <div className="flex justify-between">
                                <label className="text-normal" htmlFor="senha">
                                    Senha
                                </label>
                                <button
                                    type="button"
                                    className="text-[#001E64]"
                                    onClick={handleExibirSenha}
                                >
                                    {exibirSenha
                                        ? 'Esconder senha'
                                        : 'Exibir senha'}
                                </button>
                            </div>
                            <input
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="senha"
                                type={`${exibirSenha ? 'text' : 'password'}`}
                                onChange={(e) =>
                                    handleChangeInput('senha', e.target.value)
                                }
                                placeholder="Senha"
                            />
                            {form.senha.error && (
                                <span className="text-sm text-error">
                                    {form.senha.error}
                                </span>
                            )}
                        </div>
                        <div>
                            <input
                                className="form-check-input h-4 w-4 border border-dark-blue rounded-sm bg-white checked:bg-dark-blue checked:border-dark-blue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value={form.permanecerConectado}
                                onChange={(e) =>
                                    handleChangeInput(
                                        'permanecerConectado',
                                        e.target.checked
                                    )
                                }
                            />
                            <label
                                className="form-check-label inline-block text-gray-800"
                                htmlFor="connect"
                            >
                                Permanecer conectado
                            </label>
                        </div>
                        <div className="w-full flex flex-col items-center justify-center mt-7">
                            <ReCAPTCHA
                                ref={captchaRef}
                                sitekey={
                                    process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                        ? process.env
                                              .REACT_APP_RECAPTCHA_SITE_KEY
                                        : '6Ld1QY8hAAAAAHyVmRUarEIe7OMxu3o44fnBBYHk'
                                }
                                onChange={() => setCaptchaError(false)}
                            />
                            {captchaError && (
                                <p className="text-sm text-red-600">
                                    Verifique se você não é um robô
                                </p>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full my-7 py-3 px-12 text-lg text-white font-bold bg-primary radius-button"
                        >
                            {isLoading ? <ClipLoader size={20} /> : 'Entrar'}
                        </button>
                        {(resendEmail || sendLegacyEmail) &&
                            (isSendingEmail ? (
                                <div className="w-full flex justify-center mb-4">
                                    <ClipLoader size={20} />
                                </div>
                            ) : isSentEmail ? (
                                <div className="flex items-center w-full gap-2 justify-center mb-4">
                                    <p>E-mail reenviado com sucesso!</p>
                                    <img
                                        src="/images/check.svg"
                                        alt=""
                                        className="h-8"
                                    />
                                </div>
                            ) : sendLegacyEmail ? (
                                <div className="p-8 rounded-[4px] bg-[#D3DEED] flex items-center justify-center mt-4 text-black gap-4 mb-6">
                                    <div>
                                        <InformationCircleIcon className="w-8 h-8" />
                                    </div>

                                    <div>
                                        <p className="text-[#323232] ">
                                            <b>Primeiro acesso</b>, caso não
                                            tenha recebido um e-mail para
                                            criação de nova senha você pode
                                            clicar no link{' '}
                                            <button
                                                className="underline font-bold text-dark-blue"
                                                type="button"
                                                onClick={() =>
                                                    handleReenviarEmail(
                                                        'nova-senha'
                                                    )
                                                }
                                            >
                                                reenviar e-mail
                                            </button>
                                            . Verifique seu e-mail{' '}
                                            <b>({blurredEmail.current})</b> de
                                            confirmação na caixa de entrada ou
                                            spam.
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-4 rounded-[4px] bg-[#D3DEED] flex items-center justify-center mt-4 text-black gap-4 mb-4">
                                    <InformationCircleIcon className="w-20 h-20" />

                                    <p className="text-[#323232]">
                                        Conta inativa, caso não tenha recebido
                                        um e-mail de confirmação da conta você
                                        pode clicar no link{' '}
                                        <button
                                            className="underline font-bold text-dark-blue"
                                            type="button"
                                            onClick={() =>
                                                handleReenviarEmail(
                                                    'confirmacao'
                                                )
                                            }
                                        >
                                            reenviar e-mail
                                        </button>
                                        . Verifique seu e-mail de confirmação na
                                        caixa de entrada ou spam.
                                    </p>
                                </div>
                            ))}
                        <div className="w-full flex justify-center">
                            <p>
                                Esqueceu a senha?{' '}
                                <Link
                                    className="font-bold text-dark-blue"
                                    to={{ pathname: '/recuperar-senha' }}
                                >
                                    Recuperar
                                </Link>
                            </p>
                        </div>
                        <div className="w-full border border-grey-border my-5"></div>
                        <div className="w-full flex justify-center">
                            <p>
                                Ainda não tem uma conta?{' '}
                                <Link
                                    className="font-bold text-dark-blue"
                                    to={{ pathname: '/cadastro' }}
                                >
                                    Criar conta
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
                <img
                    alt=""
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        marginTop: `${isMobile ? '-3rem' : '-15rem'}`,
                    }}
                    src="/images/linha3.svg"
                ></img>
            </div>

            <CustomCookieConsent cookieName="grana-extra-accept">
                A SulAmérica somente utiliza cookies essenciais a navegabilidade
                do site, para melhorar a sua experiência com nossos canais e
                serviços, inexistindo quaisquer coletas de dados adicionais. Ao
                utilizar nossos serviços e canais, você concorda com a coleta
                exposta. Para saber mais informações confira nossa{' '}
                <a
                    href="https://portal.sulamericaseguros.com.br/politica-de-privacidade/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <p className="text-orange-500 decoration-orange-500 inline">
                        Política de Privacidade
                    </p>
                </a>{' '}
                e conheça nosso{' '}
                <a
                    href="https://portal.sulamericaseguros.com.br/privacidade/login"
                    target="_blank"
                    rel="noreferrer"
                >
                    <p className="text-orange-500 decoration-orange-500 inline">
                        Portal de Privacidade
                    </p>
                </a>
                .
            </CustomCookieConsent>
            <Footer />
        </>
    );
}

export default Login;
