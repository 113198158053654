import 'react-toastify/dist/ReactToastify.css';

import { Menu, Transition } from '@headlessui/react';
import { AxiosResponse } from 'axios';
import exportFromJSON from 'export-from-json';
import jwt_decode from 'jwt-decode';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
    BiArrowToBottom,
    BiChevronDown,
    BiDownArrowAlt,
    BiSearch,
} from 'react-icons/bi';
import { BsFillArrowDownSquareFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import Kanban from '../components/Kanban';
import LayoutLeads from '../components/LayoutLeads';
import FilterContext from '../contexts/FilterContext';
import { api } from '../services/api';
import { delay } from '../utils/delay';

function KanbanLeads() {
    let objStatusPages = [
        {
            status: 'Novas Oportunidades',
            currentPage: 1,
        },
        { status: 'NovoContato', currentPage: 1 },
        { status: 'EmNegociacao', currentPage: 1 },
        { status: 'Concluido', currentPage: 1 },
    ];

    const history = useNavigate();

    const {
        filterTextState,
        setFilterTextState,
        filterSelectState,
        setFilterSelectState,
    } = useContext(FilterContext);

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [openSelect, setOpenSelect] = useState(false);
    const [leads, setLeads] = useState<any>({
        oportunidades: [],
        novoContato: [],
        emNegociacao: [],
        concluidos: [],
        naoAtendido: [],
    });
    const [loading, setLoading] = useState(false);
    const [indicadores, setIndicadores] = useState({
        totalLead: 0,
        totalLeadsAtendidos: 0,
        leadsNaoAtendidos: 0,
        clientesAguardandoRetorno: 0,
    });
    const [campaigns, setCampaigns] = useState(['Campanha Sula Vida+']);

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}') || {
                  user: { name: 'Joaquim Bira' },
              }
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}') || {
                  user: { name: 'Joaquim Bira' },
              };

    const decodeJwt: any = jwt_decode(user.access_token);
    console.log(decodeJwt);
    const userName = decodeJwt.response.name;

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    const getFiltersPath = (
        lastItem: string | undefined,
        leadStatus: string
    ) => {
        const path = lastItem
            ? `/v1/leads?tamanho-pagina=500&status=${leadStatus}&cursor=${lastItem}`
            : `/v1/leads?tamanho-pagina=500&status=${leadStatus}`;

        return path;
    };

    const getData = async () => {
        setLoading(true);
        const user =
            sessionStorage.getItem('@grana-extra') !== 'null'
                ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

        try {
            const responses = await Promise.all(
                objStatusPages
                    .map((params) => {
                        if (params.status !== 'nao-atendeu') {
                            return api.get(
                                getFiltersPath(undefined, params.status),
                                {
                                    headers: {
                                        Authorization: user.access_token,
                                    },
                                }
                            );
                        }
                    })
                    .filter((item) => Boolean(item))
            );

            const leadsObj = {};

            const tipo = responses[0].data.tipo;

            responses.forEach((item) => {
                if (item.data.leads?.length > 0) {
                    leadsObj[item.data.leads[0].STATUS__c] = item.data.leads;
                }
            });

            const lengthObj = {
                concluidos: leadsObj['Concluido']?.length || 0,
                emNegociacao: leadsObj['EmNegociacao']?.length || 0,
                novoContato: leadsObj['NovoContato']?.length || 0,
                oportunidades: leadsObj['Novas Oportunidades']?.length || 0,
            };

            setIndicadores({
                totalLead:
                    lengthObj.concluidos +
                        lengthObj.emNegociacao +
                        lengthObj.novoContato +
                        lengthObj.oportunidades || 0,
                totalLeadsAtendidos:
                    +lengthObj.concluidos +
                        lengthObj.emNegociacao +
                        lengthObj.novoContato || 0,
                leadsNaoAtendidos: lengthObj.oportunidades || 0,
                clientesAguardandoRetorno: 0,
            });

            const objLeads = {
                tipo: tipo,
                concluidos: leadsObj['Concluido'] || [],
                emNegociacao: leadsObj['EmNegociacao'] || [],
                novoContato: leadsObj['NovoContato'] || [],
                oportunidades: leadsObj['Novas Oportunidades'] || [],
            };
            setLeads(objLeads);
            setLoading(false);
        } catch (error: any) {
            const cards = {
                [uuid()]: {
                    name: 'Novas Oportunidades',
                    color: '#FA6B25',
                    items: [],
                    id: 1,
                    status: 'Oportunidades',
                },
                [uuid()]: {
                    name: 'Fazer novo contato',
                    color: '#146CFA',
                    items: [],
                    id: 2,
                    status: 'NovoContato',
                },
                [uuid()]: {
                    name: 'Em negociação',
                    color: '#00335E',
                    items: [],
                    id: 3,
                    status: 'EmNegociacao',
                },
                [uuid()]: {
                    name: 'Concluído',
                    color: '#68C17C',
                    items: [],
                    id: 4,
                    status: 'Concluido',
                },
            };
            setLeads(cards);
            setLoading(false);
        }
    };

    const exportData = async () => {
        const leadsToExport = [
            ...leads.oportunidades,
            ...leads.novoContato,
            ...leads.emNegociacao,
            ...leads.concluidos,
        ];
        const data = leadsToExport.map((lead) => {
            return {
                id_lead: lead.ID_LEAD__c,
                id_mdm: lead.ID_MDM__c,
                nome: lead.NOME__c,
                cpf: lead.CPF__c,
                telefone: lead.TELEFONE__c,
                celular: lead.CELULAR__c,
                email: lead.EMAIL__c,
                segmento: lead.SEGMENTO__c,
                nome_corretor: lead.NOME_CORRETOR__c,
                EV: lead.ESTRUTURA_VENDA__c,
                codigo_produtor: lead.CODIGO_PRODUTOR__c,
                status_lead: lead.STATUS__c,
                campanha: lead.Campanha__c,
                lote: lead.LOTE__c,
                data_campanha: lead.DATA_CAMPANHA__c,
                objetivo_campanha: lead.OBJETIVO_CAMPANHA__c,
                descritivo_campanha: lead.DESCRITIVO_CAMPANHA__c,
            };
        });
        const fileName = `Export-lead`;
        const exportType = exportFromJSON.types['csv'];

        exportFromJSON({ data, fileName, exportType });

        try {
            const user =
                sessionStorage.getItem('@grana-extra') !== 'null'
                    ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                    : JSON.parse(localStorage.getItem('@grana-extra') || '{}');
            console.log(user.access_token);
            await api.patch(
                `/v1/leads/registro-exportacao`,
                {},
                {
                    headers: { Authorization: user.access_token },
                    timeout: 0,
                }
            );
        } catch (error) {
            console.log(error);

            if (
                error.response.data.data.error_message ===
                'Lead não pertence ao usuário'
            ) {
                toast.error('Lead não pertence mais ao usuário', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });

                await delay(3000);

                history(0);
            } else {
                toast.error('Ocorreu um erro, favor tente novamente', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
        }
    };

    const textFiltering = (text: any) => {
        const textLength = text.length;
        if (textLength >= 3) {
            setFilterTextState(text.toLowerCase().trim());
        } else {
            setFilterTextState('');
        }
    };

    const selectFiltering = (value: string) => {
        const findOption = filterSelectState.findIndex(
            (element: string) => element === value
        );
        const filtered = filterSelectState;
        if (findOption === -1) {
            setFilterSelectState([...filtered, value]);
        } else {
            filtered.splice(findOption, 1);
            setFilterSelectState([...filtered]);
        }
    };

    useEffect(() => {
        getData();
        // getCampaignsData();
        // getIndicadores();
        if (campaigns.length > 2) {
            setCampaigns((prev) => {
                return { ...prev };
            });
        }
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <div className="">
            <LayoutLeads>
                <div className="mb:px-[1rem] flex flex-col lg:px-[2rem] 2xl:px-[2.5rem] w-[100%] ">
                    <div className="flex mb:flex-col lg:flex-row mb:text-center justify-between items-center mt-[2vh] bg-[#D4DCEB] px-[1rem] py-[1rem] rounded-lg gap-[20px]">
                        <p>
                            Cadastre sua proposta no &nbsp;
                            <span className="font-bold">
                                Grana Extra e acompanhe seus créditos.
                            </span>
                        </p>
                        <Link
                            to={{ pathname: '/area-logada/propostas' }}
                            className="mb:w-full lg:w-auto mb:text-center bg-[#001E64] text-[white] px-[2rem] py-[0.4rem] rounded-full text-[1.1rem] font-bold"
                        >
                            Acessar painel Grana Extra
                        </Link>
                    </div>
                    <div className="py-[2vh] flex items-center justify-between mb:pt-[20px] mb:pb-[30px] ">
                        <p className="mb:text-[1.5rem] lg:text-[2rem] font-bold">
                            Painel de Gestão de Leads
                        </p>
                    </div>

                    <div className="mb:grid mb:grid-cols-2 mb:gap-[16px] mb:ml-10px lg:flex items-center justify-between w-[100%]">
                        <div className="mb:text-center mb:px-[10px] mb:py-[10px] xl:w-[22.5vw] 2xl:w-[23vw] bg-[white] flex rounded-xl pb-[2vh] items-center justify-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                            <p className="mb:text-[1.5rem] lg:text-[7vh] font-bold">
                                {indicadores.clientesAguardandoRetorno}
                            </p>
                            <p className="mb:text-[0.75rem] lg:text-[2.5vh] flex items-center">
                                Ligações não atendidas
                            </p>
                        </div>
                        <div className="mb:text-center mb:px-[10px] mb:py-[10px] xl:w-[22.5vw] 2xl:w-[23vw] bg-[white] flex rounded-xl pb-[2vh] items-center justify-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                            <p className="mb:text-[1.5rem] lg:text-[7vh] font-bold">
                                {indicadores.totalLead}
                            </p>
                            <p className="mb:text-[0.75rem] lg:text-[2.5vh] flex items-center">
                                Total de leads Recebidos
                            </p>
                        </div>
                        <div className="mb:text-center mb:px-[10px] mb:py-[10px] xl:w-[22.5vw] 2xl:w-[23vw] bg-[white] flex rounded-xl pb-[2vh] items-center justify-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                            <p className="mb:text-[1.5rem] lg:text-[7vh] font-bold">
                                {indicadores.totalLeadsAtendidos}
                            </p>
                            <p className="mb:text-[0.75rem] lg:text-[2.5vh] flex items-center">
                                Total de leads Atendidos
                            </p>
                        </div>
                        <div className="mb:text-center mb:px-[10px] mb:py-[10px] xl:w-[22.5vw] 2xl:w-[23vw] bg-[white] flex rounded-xl pb-[2vh] items-center justify-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                            <p className="mb:text-[1.5rem] lg:text-[7vh] font-bold">
                                {indicadores.leadsNaoAtendidos}
                            </p>
                            <p className="mb:text-[0.75rem] lg:text-[2.5vh] flex items-center">
                                Leads não atendidos
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mb:hidden lg:flex lg:px-[2rem] 2xl:px-[2.5rem] items-center mt-[1rem] w-[100%] justify-between">
                    <div className="bg-[#FFFF] p-[1.6rem] rounded-xl xl:w-[35%] 2xl:w-[34%]  shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                        <p className="font-bold text-[2vh]">
                            Gestão de Campanhas
                        </p>

                        <Menu
                            as="div"
                            className="relative inline-block text-left w-[100%]"
                        >
                            <div>
                                <Menu.Button className="flex justify-between px-[0.8rem] h-[2.3rem] items-center rounded-3xl bg-white w-[100%] border-[0.1rem] border-[black] ">
                                    <span>Selecionar</span>
                                    <BiDownArrowAlt size="1.5rem" />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute p-4 z-20 mt-2 w-[100%] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <>
                                                    {campaigns.map(
                                                        (campaign, index) => {
                                                            return (
                                                                <div
                                                                    className="flex"
                                                                    key={index}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="border-black mr-1"
                                                                        checked={
                                                                            campaign ===
                                                                            filterSelectState.find(
                                                                                (
                                                                                    element: string
                                                                                ) =>
                                                                                    element ===
                                                                                    campaign
                                                                            )
                                                                        }
                                                                        value={
                                                                            campaign
                                                                        }
                                                                        onChange={() => {
                                                                            selectFiltering(
                                                                                campaign
                                                                            );
                                                                        }}
                                                                    />
                                                                    <p>
                                                                        {
                                                                            campaign
                                                                        }
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>

                    <div className="p-[1.6rem] xl:w-[38%] 2xl:w-[34%] bg-[#FFFF] rounded-lg flex items-center justify-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                        {' '}
                        <div className="w-[100%]">
                            <div className="flex">
                                <p className="font-bold text-[2vh]">Leads</p>
                            </div>

                            <input
                                className="w-[100%] h-[2.3rem] px-[0.8rem] rounded-3xl border-[black] border-[0.1rem]"
                                placeholder="Buscar lead"
                                onChange={(e) => {
                                    textFiltering(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className="p-[1.6rem] bg-[#FFFF] rounded-lg flex items-center justify-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)]">
                        {' '}
                        <div>
                            <div className="flex">
                                <p className="font-bold text-[2vh]">
                                    Exportar Leads
                                </p>
                            </div>

                            <button
                                className="2xl:w-[25vw] lg:w-[18.5vw] 2xl:h-[2.3rem] lg:h-[2rem] lg:my-[0.25rem] 2xl:my-[0] px-[0.8rem] rounded-3xl  bg-[#2A4E9A] flex justify-center items-center"
                                onClick={() => {
                                    exportData();
                                }}
                                placeholder="Buscar lead"
                            >
                                <BsFillArrowDownSquareFill color="white" />
                                <span className="pl-1  text-white">
                                    Exportar
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <Kanban
                        data={leads}
                        campaigns={campaigns}
                        exportData={exportData}
                    />
                </div>
            </LayoutLeads>
        </div>
    );
}

export default KanbanLeads;
