import { CheckCircleIcon } from '@heroicons/react/solid';
import {
    FormEvent,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { api } from '../services/api';

function RecuperarSenha() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const history = useNavigate();

    const [width, setWidth] = useState<number>(window.innerWidth);

    const [sent, setSent] = useState(false);

    const [loading, setLoading] = useState(false);

    const [params] = useSearchParams();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const onSubmit = async (data: any) => {
        const { email } = data;
        try {
            setLoading(true);

            await api.post('/v1/email/reset-password', {
                email,
            });

            setSent(true);
            setLoading(false);
        } catch (error: any) {
            const notifyError = () =>
                toast.error(
                    `Erro ${error.code}. Favor tente novamente em instantes.`
                );

            setLoading(false);

            notifyError();
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <NavBar hideBtnCadastrar hideBtnEntrar />
            <div>
                <div
                    className={`w-full py-14 px-5 md:p-20 
                    ${
                        isMobile ? 'bg-login-mobile' : 'bg-login h-screen'
                    } md:grid grid-cols-2 gap-4`}
                    style={{ minHeight: '650px' }}
                >
                    <div>
                        <h4 className="title title-regulamento font-bold text-white mb-5 md:mb-10 pr-20">
                            {params.get('tipo') === 'nova_senha'
                                ? 'Cadastrar nova senha'
                                : 'Recuperar senha'}
                        </h4>
                        <button
                            onClick={() => (
                                params.get('tipo') === 'nova_senha' &&
                                    history('/'),
                                params.get('tipo') !== 'nova_senha' &&
                                    history('/login')
                            )}
                            type="button"
                            className="py-2 mr-2 mb-7 px-10 text-white font-bold border border-white radius-button"
                        >
                            Voltar
                        </button>
                    </div>
                    <div
                        className="bg-white rounded-3xl py-10 px-5 md:px-12 text-black max-h-[30rem]"
                        style={{ maxWidth: '520px', zIndex: 2 }}
                    >
                        <div className="w-full flex justify-center">
                            <img alt="" src="/images/logo-colorido.jpg"></img>
                        </div>
                        {sent ? (
                            <div className="flex flex-col w-full justify-center items-center gap-4">
                                <CheckCircleIcon className="h-20 w-20 text-success" />
                                <p className="text-[#006217] font-bold text-lg text-center">
                                    Enviamos um link para recuperar sua senha em
                                    seu e-mail.
                                </p>
                                <Link
                                    to="/login"
                                    className="w-full flex justify-center items-center"
                                >
                                    <button className="bg-[#FF5000] w-2/3  font-bold h-[3.25rem] text-white flex items-center text-center justify-center radius-button">
                                        Ir para o login
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            <form
                                className="mt-10"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h5 className="text-xl">
                                    {params.get('tipo') === 'nova_senha' &&
                                        'Insira seu e-mail de cadastro para cadastrar uma nova senha'}
                                    {params.get('tipo') !== 'nova_senha' &&
                                        'Insira seu e-mail para recuperar a senha'}
                                </h5>

                                <div className="mb-5 mt-5">
                                    <label
                                        className="text-normal mb-2"
                                        htmlFor="email"
                                    >
                                        E-mail
                                    </label>
                                    <input
                                        className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="email"
                                        type="email"
                                        {...register('email', {
                                            required: true,
                                        })}
                                        placeholder="E-mail"
                                    />
                                    {errors.email && (
                                        <span className="text-red-600">
                                            E-mail incorreto
                                        </span>
                                    )}
                                </div>
                                <button className="w-full mt-5 py-3 px-12 text-lg text-white font-bold bg-primary radius-button">
                                    {loading ? (
                                        <ClipLoader size={20} />
                                    ) : params.get('tipo') === 'nova_senha' ? (
                                        'Cadastrar nova senha'
                                    ) : (
                                        'Recuperar senha'
                                    )}
                                </button>
                            </form>
                        )}
                    </div>
                </div>
                {isMobile ? null : (
                    <img
                        alt=""
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            width: '100%',
                            marginTop: `${isMobile ? '-3rem' : '-11rem'}`,
                        }}
                        src="/images/linha3.svg"
                    />
                )}
            </div>
            <Footer />
        </>
    );
}

export default RecuperarSenha;
