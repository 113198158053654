import React, { createContext, useState } from 'react';

type FilterContextProps = {
    filterTextState: string;
    setFilterTextState: React.Dispatch<React.SetStateAction<string>>;

    filterSelectState: string[];
    setFilterSelectState: React.Dispatch<React.SetStateAction<string[]>>;
};

const initialValues = {
    filterTextState: '',

    setFilterTextState: () => {
        ('');
    },

    filterSelectState: [''],

    setFilterSelectState: () => {
        ('');
    },
};

const FilterContext = createContext<FilterContextProps>(initialValues);

const FilterContextProvider = ({ children }: any) => {
    const [filterTextState, setFilterTextState] = useState(
        initialValues.filterTextState
    );
    const [filterSelectState, setFilterSelectState] = useState(
        initialValues.filterSelectState
    );

    return (
        <FilterContext.Provider
            value={{
                filterTextState,
                setFilterTextState,
                filterSelectState,
                setFilterSelectState,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};
export { FilterContextProvider };
export default FilterContext;
