import { Link } from 'react-router-dom';

import { premiacoes } from '../data/premiacoes';

const Card = ({ title, items }) => (
    <div className="py-10 border border-dark-blue rounded-3xl card-campanha my-3 max-w-[100%] mb-10">
        <div className="px-5" style={{ marginTop: '-70px' }}>
            <h6 className="font-bold bg-primary text-center py-4 rounded-xl">
                {title}
            </h6>
        </div>
        <div className="flex pb-4 px-3 text-dark-blue mt-5 w-full sm:w-[25rem] text-sm sm:text items-center justify-center">
            {items.map((item, index) => (
                <div key={index} className="ml-1">
                    <h6
                        className={`text-[${item.color}] flex items-center font-bold mb-3`}
                        style={{ width: '115px', height: '75px' }}
                    >
                        {item.label}
                    </h6>
                    {item.values.map((value, idx) => (
                        <p key={idx}>{value}</p>
                    ))}
                </div>
            ))}
        </div>
    </div>
);

function Campanhas() {
    return (
        <div className="pt-5">
            <div id="campanhas">
                <div className="my-10">
                    <div className="px-5 md:px-48">
                        <h4 className="font-bold title-campanhas">Campanhas</h4>
                        <p className="my-3 text-secondary max-w-screen-sm">
                            Toda semana suas propostas cadastradas e implantadas
                            viram grana extra
                            <br /> no seu bolso. Quanto mais vender, mais
                            créditos você irá ganhar!
                        </p>
                    </div>
                    <div className="px-5 md:px-16 mt-12 grid md:grid-cols-3 sm:grid-cols-12 gap-10 text-white">
                        <div className="px-12 py-10 bg-dark-blue rounded-3xl card-campanha">
                            <h6 className="flex items-center">
                                <span className="title title-card">1</span>
                                <span className="text-xl ml-5">
                                    Faça seu cadastro
                                </span>
                            </h6>
                            <p className="text-base pr-7 md:pr-10">
                                <Link
                                    to="/cadastro"
                                    className="border-b font-bold"
                                >
                                    Clique aqui
                                </Link>{' '}
                                e complete o cadastro. Depois é só acessar com o
                                e-mail e senha cadastrados.
                            </p>
                        </div>
                        <div className="px-12 py-10 bg-primary rounded-3xl card-campanha">
                            <h6 className="flex items-center">
                                <span className="title title-card">2</span>
                                <span className="text-xl ml-5">
                                    Cadastre suas propostas
                                </span>
                            </h6>
                            <p className="text-base">
                                Com o cadastro realizado, você poderá enviar
                                suas propostas Saúde: PME, PME+, Empresarial até
                                199 vidas Odonto {/*e Capital Global*/}para
                                participar da campanha.
                            </p>
                        </div>
                        <div className="px-10 md:px-12 py-10 bg-blue rounded-3xl card-campanha">
                            <h6 className="flex items-center">
                                <span className="title title-card">3</span>
                                <span className="text-xl ml-5">
                                    Comemore toda semana!
                                </span>
                            </h6>
                            <p className="text-base">
                                Depois da emissão do seguro, os créditos cairão
                                automaticamente no cartão Master que a
                                SulAmérica enviará para você.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="mt-5 w-full bg-ice pt-10 pb-20"
                    style={{ background: 'rgba(210, 210, 210, 0.2)' }}
                >
                    <div className="pl-5 pr-20 md:px-48">
                        <h4 className="font-bold title-campanhas max-w-lg">
                            Quanto mais vender, mais créditos você irá ganhar!
                        </h4>
                    </div>
                    <div className="mx-5 md:px-16 mt-20 flex gap-10 flex-wrap text-white items-center justify-center">
                        {premiacoes.map((item, index) => (
                            <Card
                                key={index}
                                title={item.title}
                                items={item.items}
                            />
                        ))}
                    </div>{' '}
                </div>
            </div>
            <img
                alt=""
                style={{
                    position: 'absolute',
                    marginTop: '-80px',
                    width: '100%',
                }}
                src="/images/linha.svg"
            ></img>
            <div
                className="w-full bg-dark-blue px-5 md:px-48 py-16 md:flex justify-between flex-col"
                id="regulamento"
            >
                <div>
                    <h4 className="title-regulamento font-bold text-white">
                        Baixe nossos regulamentos
                    </h4>
                    <p className="text-white text-base mt-5">
                        Faça o download dos regulamentos completos clicando nos
                        botões abaixo.
                    </p>
                </div>
                <div className="w-full md:flex">
                    <a
                        href="docs/regulamentoGranaExtra2.pdf"
                        target="_blank"
                        className="mr-7"
                    >
                        <button className="w-full md:w-auto mt-10 py-3 px-24 text-lg text-white font-bold bg-primary radius-button flex items-center justify-center">
                            <img
                                alt="regulamento"
                                className="mr-4"
                                src="/images/arrow-down.svg"
                            ></img>

                            <span>Regulamento Grana Extra</span>
                        </button>
                    </a>
                    {/* <a href="docs/regulamentoProtecaoSaude.pdf" target="_blank">
                        <button className="w-full md:w-auto mt-10 py-3 px-24 text-lg text-white font-bold bg-primary radius-button flex items-center justify-center">
                            <img
                                alt="regulamento"
                                className="mr-4"
                                src="/images/arrow-down.svg"
                            ></img>

                            <span>Regulamento Proteção Saúde</span>
                        </button>
                    </a> */}
                </div>
            </div>
        </div>
    );
}

export default Campanhas;
