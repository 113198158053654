import { Link, useLocation } from 'react-router-dom';

import right_0xe822 from '../assets/svgs/right_0xe822.svg';

type BackLinkProps = {
    url?: string;
};

export const BackLink = ({ url }: BackLinkProps) => {
    const location = useLocation();

    const actualPage = location.pathname;

    const lastPageIndex = actualPage.lastIndexOf('/');

    const lastPage = actualPage.substring(0, lastPageIndex);

    return (
        <Link to={url ?? lastPage} className="inline-block">
            <div className="flex gap-2 text-[#001E64] underline font-bold w-auto">
                {/*
                 */}
                <img src={right_0xe822} alt="Voltar" />
                {/*
                 */}
                Voltar
            </div>
        </Link>
    );
};
