import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Link as LinkInterno } from 'react-scroll';
import { ClipLoader } from 'react-spinners';

import MenuSocial from './MenuSocial';

interface INavBar {
    hideBtnCadastrar?: boolean;
    hideBtnEntrar?: boolean;
}

function NavBar({ hideBtnCadastrar, hideBtnEntrar }: INavBar) {
    const location = useLocation();

    const history = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = () => {
        const userJwt =
            localStorage.getItem('@grana-extra') ||
            sessionStorage.getItem('@grana-extra');

        if (userJwt && userJwt !== 'null') {
            setIsLoading(true);

            setTimeout(() => {
                history('/area-logada/');
            }, 1000);
        } else history('/login');
    };

    return (
        <div className="sticky top-0 z-50 shadow-xl">
            <nav className="bg-white pb-2">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="hidden md:block border-b border-gray-150">
                        <MenuSocial />
                    </div>
                    <div className="flex items-center justify-between h-16 w-full">
                        <div className="flex items-center w-full">
                            <div className="flex-shrink-0">
                                <img
                                    alt=""
                                    width="128px"
                                    src="/images/logo-sulAmerica.png"
                                ></img>
                            </div>
                            <div className="hidden md:block w-full">
                                <div className="w-full ml-10 flex items-baseline space-x-4 justify-between">
                                    <div className="hidden md:block text-dark-blue">
                                        <div className="ml-5 flex items-baseline">
                                            {location.pathname === '/' ? (
                                                <>
                                                    <LinkInterno
                                                        activeClass="font-bold"
                                                        className="cursor-pointer mx-5"
                                                        to="banner"
                                                        spy={true}
                                                        smooth={true}
                                                        offset={-150}
                                                        duration={500}
                                                    >
                                                        Início
                                                    </LinkInterno>
                                                    <LinkInterno
                                                        activeClass="font-bold"
                                                        className="cursor-pointer mx-5"
                                                        to="campanhas"
                                                        spy={true}
                                                        smooth={true}
                                                        offset={-150}
                                                        duration={500}
                                                    >
                                                        Campanha
                                                    </LinkInterno>
                                                    <LinkInterno
                                                        activeClass="font-bold"
                                                        className="cursor-pointer mx-5"
                                                        to="regulamento"
                                                        spy={true}
                                                        smooth={true}
                                                        offset={-70}
                                                        duration={500}
                                                    >
                                                        Regulamento
                                                    </LinkInterno>
                                                    <LinkInterno
                                                        activeClass="font-bold"
                                                        className="cursor-pointer mx-5"
                                                        to="contato"
                                                        spy={true}
                                                        smooth={true}
                                                        offset={-70}
                                                        duration={500}
                                                    >
                                                        Contato
                                                    </LinkInterno>
                                                    <Link
                                                        className="mx-5"
                                                        to={{
                                                            pathname:
                                                                '/duvidas-frequentes',
                                                        }}
                                                    >
                                                        Dúvidas frequentes
                                                    </Link>
                                                </>
                                            ) : (
                                                <>
                                                    <HashLink
                                                        className="mx-5"
                                                        to="/#inicio"
                                                    >
                                                        Início
                                                    </HashLink>
                                                    <HashLink
                                                        className="mx-5"
                                                        to="/#campanhas"
                                                    >
                                                        Campanha
                                                    </HashLink>
                                                    <HashLink
                                                        className="mx-5"
                                                        to="/#regulamento"
                                                    >
                                                        Regulamento
                                                    </HashLink>
                                                    <HashLink
                                                        className="mx-5"
                                                        to="/#contato"
                                                    >
                                                        Contato
                                                    </HashLink>
                                                    <Link
                                                        className={`mx-5 ${
                                                            location.pathname ===
                                                            '/duvidas-frequentes'
                                                                ? 'font-bold'
                                                                : ''
                                                        }`}
                                                        to={{
                                                            pathname:
                                                                '/duvidas-frequentes',
                                                        }}
                                                    >
                                                        Dúvidas frequentes
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pr-10">
                                        {!hideBtnCadastrar && (
                                            <button
                                                onClick={() =>
                                                    history('/cadastro')
                                                }
                                                className="min-w-[9.5rem] min-h-[3rem] py-2 mr-2 px-10 text-primary font-bold border border-primary radius-button"
                                            >
                                                Cadastrar
                                            </button>
                                        )}
                                        {!hideBtnEntrar && (
                                            <button
                                                onClick={() => handleLogin()}
                                                className="min-w-[9.5rem] min-h-[3rem] py-3 ml-2 px-10 text-white font-bold bg-primary hover:bg-primary-hover radius-button"
                                            >
                                                {isLoading ? (
                                                    <ClipLoader
                                                        size={20}
                                                        color="#fff"
                                                    />
                                                ) : (
                                                    'Entrar'
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className="inline-flex items-center justify-center p-2 rounded-md text-dark-blue"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {(ref) => (
                        <div className="h-full md:hidden" id="mobile-menu">
                            <div
                                ref={ref}
                                className="px-2 pt-10 pb-3 space-y-7 sm:px-3 flex flex-col text-dark-blue"
                            >
                                <a className="font-bold mx-2" href="/">
                                    Início
                                </a>
                                <a
                                    className="mx-2"
                                    href="/"
                                    style={{ color: '#001E64' }}
                                >
                                    Campanha
                                </a>
                                <a className="mx-2" href="/#regulamento">
                                    Regulamento
                                </a>
                                <a className="mx-2" href="/#contato">
                                    Contato
                                </a>
                                <Link
                                    className="mx-2"
                                    to={{ pathname: '/duvidas-frequentes' }}
                                >
                                    Dúvidas frequentes
                                </Link>
                                <button
                                    onClick={() => history('/cadastro')}
                                    className="py-2 px-10 text-primary font-bold border border-primary radius-button"
                                >
                                    Cadastrar
                                </button>
                                <button
                                    onClick={() => history('/login')}
                                    className="py-2 px-10 text-white font-bold bg-primary hover:bg-primary-hover radius-button"
                                >
                                    Entrar
                                </button>
                                <div className="border-t flex pt-7 pb-5 justify-center">
                                    <a
                                        href="https://pt-br.facebook.com/SulAmerica/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            alt=""
                                            src="/images/icons/facebook-mobile.svg"
                                        ></img>
                                    </a>
                                    <a
                                        href="https://twitter.com/Sulamerica"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            alt=""
                                            src="/images/icons/twitter-mobile.svg"
                                        ></img>
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UCoBwkOEyX6JqKzHRbpoaC0g"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            alt=""
                                            src="/images/icons/youtube-mobile.svg"
                                        ></img>
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/sulamerica/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            alt=""
                                            src="/images/icons/linkedin-mobile.svg"
                                        ></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </Transition>
            </nav>
        </div>
    );
}

export default NavBar;
