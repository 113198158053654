import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function NovaHome() {
    const [isLinked, setIsLinked] = useState(false);

    useEffect(() => {
        const user =
            sessionStorage.getItem('@grana-extra') !== 'null'
                ? JSON.parse(
                      sessionStorage.getItem('@grana-extra') || '{}'
                  ) || {
                      user: { name: 'Joaquim Bira' },
                  }
                : JSON.parse(localStorage.getItem('@grana-extra') || '{}') || {
                      user: { name: 'Joaquim Bira' },
                  };

        const decodedJwt: any = jwt_decode(user?.access_token);

        setIsLinked(decodedJwt.response.isLinked);
    }, []);

    return (
        <div className="flex flex-col justify-between w-[100%] h-[100vh]">
            <div id="inicio">
                <div
                    id="first"
                    className="flex mb:flex-col lg:flex-row justify-center lg:w-[100%] lg:h-[60%]"
                >
                    <div className="mb:w-full lg:w-[50%] ">
                        <div className="mb:px-[16px] py-[20px] lg:pl-[5rem] lg:py-5 bg-[#F5F5F5] h-[30%]">
                            <img
                                className="mb:w-[128px] mb:h-[32px] lg:w-[25%]"
                                alt=""
                                src="/images/logo-sulAmerica.png"
                            ></img>
                        </div>
                        <div className="flex flex-col justify-center bg-[#001E64] text-[#F5F5F5] mb:px-[16px] lg:px-0 mb:py-[20px] lg:py-0 lg:h-[70%] lg:pl-[5rem] mb:gap-[10px] lg:gap-0">
                            <h1 className="mb:text-[2rem] lg:text-3xl 2xl:text-5xl font-bold">
                                Painel Grana Extra
                            </h1>
                            <p className="mb:text-[1.25rem] mb:leading-7 lg:text-3xl lg:mt-8 font-light">
                                Foi criado para que você tenha
                                <br />
                                uma experiência num só lugar. Acesse todos os
                                portais.
                            </p>
                        </div>
                    </div>
                    <div className="mb:w-full lg:w-[50%]">
                        <a
                            href="https://app.mindminers.com/r/BRJQDEG"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                alt=""
                                className="w-[100%] h-[100%] mb:hidden lg:flex"
                                src="/images/novo-banner-home.png
                                "
                            ></img>
                        </a>
                    </div>
                </div>
                <div className="flex mb:gap-[20px] lg:gap-[32px] mb:flex-col lg:flex-row justify-center items-center lg:h-[80%] lg:mx-[2rem] mb:px-[16px] lg:px-0 mb:py-[30px] lg:py-0 mb:mb-[30px] lg:mb-0 ">
                    <div className="w-full max-w-[630px] flex justify-center items-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)] mb:px-[16px] mb:py-[30px] lg:w-[47vw] lg:h-[28vh] rounded-3xl">
                        <p className="mb:text-[1.25rem] lg:text-2xl font-bold">
                            Painel de Propostas Grana Extra
                        </p>
                        <p className="text-center mb:text-[1rem] lg:text-[1rem] mb:text-[#858586] mb:mt-[5px] mb:mb-[20px] lg:my-3">
                            Cadastre suas propostas e acompanhe seus &nbsp;
                            <br className="mb:hidden lg:flex" />
                            créditos disponíveis na campanha.
                        </p>
                        <Link
                            className="mb:w-full mb:flex mb:justify-center"
                            to={{ pathname: '/area-logada/propostas' }}
                        >
                            <button className="bg-[#FF5000] text-[#F5F5F5] mb:w-full mb:h-[48px] mb:py-[6px] lg:py-0 lg:w-[20vw] lg:h-[4vh] rounded-3xl">
                                Acessar Painel
                            </button>
                        </Link>
                    </div>
                    {isLinked ? (
                        <div className="w-full  max-w-[630px] flex justify-center items-center flex-col shadow-[0px_0px_5px_rgba(50,50,50,0.25)] mb:px-[16px] mb:py-[30px] lg:w-[47vw] lg:h-[28vh] rounded-3xl">
                            <p className="mb:text-[1.25rem] lg:text-2xl font-bold">
                                Gestão de Leads
                            </p>
                            <p className="text-center mb:text-[1rem] lg:text-[1rem] mb:text-[#858586] mb:mt-[5px] mb:mb-[20px] lg:my-3">
                                Gerencie o número de leads, conversão &nbsp;
                                <br className="mb:hidden lg:flex" />
                                de venda e acompanhe seus resultados em tempo
                                real.
                            </p>

                            <Link
                                className="mb:w-full mb:flex mb:justify-center"
                                to={{ pathname: '/area-logada/leads' }}
                            >
                                <button className="bg-[#FF5000] text-[#F5F5F5] mb:w-full mb:h-[48px] mb:py-[6px] lg:py-0 lg:w-[20vw] lg:h-[4vh] rounded-3xl">
                                    Acessar Painel
                                </button>
                            </Link>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="lg:w-100 bg-primary text-white lg:h-[10vh] flex mb:flex-col lg:flex-row items-center mb:px-[36px] lg:px-0 mb:py-[30px] lg:py-0">
                <div className="flex gap-[22px] mb:mb-[20px] lg:mb-0 justify-center">
                    <div className="bg-black p-1 mx-2">
                        <p className="border p-1" style={{ fontSize: '9px' }}>
                            ANS - nº 006246
                        </p>
                    </div>
                    <div className="bg-black p-1">
                        <p className="border p-1" style={{ fontSize: '9px' }}>
                            ANS - nº 006246
                        </p>
                    </div>
                </div>
                <p className="mb:text-center lg:text-start text-xs md:ml-16">
                    2022 SulAmérica - Todos os direitos reservados | CNPJ:
                    01.685.053/0013-90 - Rua dos Pinheiros, 1673 - São Paulo/SP
                    - CEP: 05.422-012
                </p>
            </div>
        </div>
    );
}

export default NovaHome;
