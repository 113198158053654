/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import {
    compareItems,
    RankingInfo,
    rankItem,
} from '@tanstack/match-sorter-utils';
import {
    FilterFn,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React, { Dispatch, useEffect, useState } from 'react';

import useIsMobile from '../hooks/useIsMobile';
import { randomComponentId } from '../utils/randomComponentId';
import { Pagination } from './Pagination';

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
        itemRank,
    });

    return itemRank.passed;
};

export const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 0,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            {...props}
        />
    );
};

export function IconSearch() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
        </svg>
    );
}

const RowBreak = () => {
    return (
        <tr className="border-0">
            <th className="border-0 p-[.2rem]" />
        </tr>
    );
};

const strippedStyle = {
    '&:nth-of-type(6n+4)': {
        backgroundColor: '#D3DEED',
    },
};

type ReactTableV8Props = {
    columns: any;
    data: any;
    filters?: any;
    setFilters?: Dispatch<any>;
    paginationDispensed?: boolean;
};

export const ReactTableV8 = ({
    columns,
    data,
    filters,
    setFilters,
    paginationDispensed,
}: ReactTableV8Props) => {
    const [windowSize] = useIsMobile();
    const isMobile = +windowSize <= 768;

    const [expanded, setExpanded] = useState<any>({});

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters: filters,
            expanded,
        },
        onColumnFiltersChange: setFilters,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
    });

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto lg:-mx-8">
                <div
                    className="lg:pt-[32px] lg:pb-8 lg:px-8"
                    inline-block
                    min-w-full
                >
                    <div className="overflow-auto">
                        <table className="border-spacing-y-2 border-spacing-x-0 w-full text-[0.875rem]">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id} className="h-10">
                                        {headerGroup.headers.map(
                                            (header, index) => {
                                                return (
                                                    <th
                                                        className="text-[#001E64]"
                                                        key={header.id + index}
                                                        colSpan={header.colSpan}
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <>
                                                                <div
                                                                    {...{
                                                                        className:
                                                                            header.column.getCanSort()
                                                                                ? 'flex h-full cursor-pointer select-none pl-[26px] whitespace-nowrap'
                                                                                : 'flex h-full pl-[26px] whitespace-nowrap',
                                                                        onClick:
                                                                            header.column.getToggleSortingHandler(),
                                                                    }}
                                                                >
                                                                    {flexRender(
                                                                        header
                                                                            .column
                                                                            .columnDef
                                                                            .header,
                                                                        header.getContext()
                                                                    )}
                                                                    {(header.column.getCanSort() &&
                                                                        {
                                                                            asc: (
                                                                                <div
                                                                                    className={`${
                                                                                        index >=
                                                                                        4
                                                                                            ? 'flex ml-2'
                                                                                            : 'hidden'
                                                                                    }`}
                                                                                >
                                                                                    <ArrowUpIcon
                                                                                        className="h-4"
                                                                                        color={
                                                                                            header.column.getIsSorted()
                                                                                                ? '#001E64'
                                                                                                : '#AAAAAA'
                                                                                        }
                                                                                    />
                                                                                    <ArrowDownIcon
                                                                                        className="h-4 mt-1"
                                                                                        color={
                                                                                            header.column.getIsSorted()
                                                                                                ? '#AAAAAA'
                                                                                                : '#001E64'
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ),
                                                                            desc: (
                                                                                <div
                                                                                    className={`${
                                                                                        index >=
                                                                                        4
                                                                                            ? 'flex ml-2'
                                                                                            : 'hidden'
                                                                                    }`}
                                                                                >
                                                                                    <ArrowUpIcon
                                                                                        className="h-5"
                                                                                        color={
                                                                                            header.column.getIsSorted()
                                                                                                ? '#AAAAAA'
                                                                                                : '#001E64'
                                                                                        }
                                                                                    />
                                                                                    <ArrowDownIcon
                                                                                        className="h-4 mt-1"
                                                                                        color={
                                                                                            header.column.getIsSorted()
                                                                                                ? '#001E64'
                                                                                                : '#AAAAAA'
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ),
                                                                        }[
                                                                            header.column.getIsSorted() as string
                                                                        ]) ?? (
                                                                        <div
                                                                            className={`${
                                                                                index >=
                                                                                4
                                                                                    ? 'flex ml-2'
                                                                                    : 'hidden'
                                                                            }`}
                                                                        >
                                                                            <ArrowUpIcon
                                                                                className="h-4"
                                                                                color="#AAAAAA"
                                                                            />
                                                                            <ArrowDownIcon
                                                                                className="h-4 mt-1"
                                                                                color="#AAAAAA"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </th>
                                                );
                                            }
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className="">
                                {table.getRowModel().rows.map((row, index) => {
                                    return (
                                        <>
                                            <tr
                                                key={row.id + index}
                                                className="h-14"
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell) => {
                                                        return (
                                                            <td
                                                                key={cell.id}
                                                                className={`${
                                                                    cell.column
                                                                        .id ===
                                                                        'cpf' ||
                                                                    cell.column
                                                                        .id ===
                                                                        'cnpj'
                                                                        ? 'rounded-md'
                                                                        : ''
                                                                }
                                                              ${
                                                                  cell.column
                                                                      .id ===
                                                                      'saude' ||
                                                                  cell.column
                                                                      .id ===
                                                                      'Propostas'
                                                                      ? 'rounded-l-md'
                                                                      : ''
                                                              } ${
                                                                    cell.column
                                                                        .id ===
                                                                        'dataCredito' ||
                                                                    cell.column
                                                                        .id ===
                                                                        'Propostas'
                                                                        ? 'rounded-r-md'
                                                                        : ''
                                                                } ${
                                                                    index % 2
                                                                        ? 'bg-[#D3DEED]'
                                                                        : 'bg-[#F4F4F4]'
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`mb:pl-[15px] lg:pl-8 lg:h-14 flex items-center ${
                                                                        cell
                                                                            .column
                                                                            .id ===
                                                                            'cpf' ||
                                                                        cell
                                                                            .column
                                                                            .id ===
                                                                            'cnpj' ||
                                                                        cell
                                                                            .column
                                                                            .id ===
                                                                            'saude' ||
                                                                        cell
                                                                            .column
                                                                            .id ===
                                                                            'Propostas'
                                                                            ? 'border-l-[#FF5000] border-l-[5px] rounded-l-md'
                                                                            : ''
                                                                    } `}
                                                                >
                                                                    {flexRender(
                                                                        cell
                                                                            .column
                                                                            .columnDef
                                                                            .cell,
                                                                        cell.getContext()
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                            <div className="h-[10px]"></div>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>

                        <div
                            className={`${
                                paginationDispensed ? 'hidden' : 'flex'
                            } justify-between gap-4 flex-wrap`}
                        >
                            <div className="flex items-center gap-2">
                                <p>Linhas por página</p>
                                <select
                                    className="w-20 h-10 text-center border-[1px] border-[#F4F4F4] rounded-md"
                                    value={table.getState().pagination.pageSize}
                                    onChange={(e) => {
                                        table.setPageSize(
                                            Number(e.target.value)
                                        );
                                    }}
                                >
                                    {[10, 20, 30, 50, 100].map((pageSize) => (
                                        <option
                                            key={pageSize + randomComponentId()}
                                            value={pageSize}
                                        >
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex gap-10 sm:justify-center items-center flex-wrap">
                                <div className="flex">
                                    <div>
                                        Exibindo de{' '}
                                        {table.getState().pagination
                                            .pageIndex === 0
                                            ? table.getState().pagination
                                                  .pageIndex + 1
                                            : table.getState().pagination
                                                  .pageIndex *
                                                  table.getState().pagination
                                                      .pageSize +
                                              1}{' '}
                                        até{' '}
                                        {table.getState().pagination.pageIndex +
                                            1 ===
                                        table.getPageCount()
                                            ? data.length
                                            : table.getState().pagination
                                                  .pageSize *
                                              (table.getState().pagination
                                                  .pageIndex +
                                                  1)}{' '}
                                        de {data.length}
                                    </div>
                                </div>

                                <div
                                    className={`${
                                        paginationDispensed ? 'hidden' : 'flex'
                                    } mb:w-full lg:w-auto justify-center`}
                                >
                                    <Pagination
                                        currentPage={
                                            table.getState().pagination
                                                .pageIndex
                                        }
                                        lastPage={table.getPageCount()}
                                        maxLength={isMobile ? 5 : 7}
                                        setCurrentPage={table.setPageIndex}
                                    />
                                </div>
                                {/* <div className="flex gap-6">
                                    <button
                                        className="bg-transparent cursor-pointer w-8 h-8"
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        {' < '}
                                    </button>
                                    <div>
                                        {Array.from(
                                            Array(table.getPageCount()).keys()
                                        ).map((page, index) => {
                                            return (
                                                <>
                                                    <button
                                                        key={
                                                            randomComponentId() +
                                                            index
                                                        }
                                                        className={`
                                                rounded-md 
                                                cursor-pointer
                                                hover:opacity-80
                                                transition-all
                                                w-8
                                                h-8
                                                ${
                                                    table.getState().pagination
                                                        .pageIndex === page
                                                        ? 'bg-[#146CFA] text-[#fff]'
                                                        : 'bg-transparent text-[#323232]'
                                                }`}
                                                        onClick={() =>
                                                            table.setPageIndex(
                                                                page
                                                            )
                                                        }
                                                    >
                                                        {page + 1}
                                                    </button>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <button
                                        className="bg-transparent cursor-pointer w-8 h-8"
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        {' > '}
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
