import CookieConsent from 'react-cookie-consent';

type CustomCookieConsentProps = {
    children: any;
    cookieName: string;
};

export const CustomCookieConsent = ({
    children,
    cookieName,
}: CustomCookieConsentProps) => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="ACEITO"
            cookieName={cookieName}
            style={{
                width: '90%',
                marginLeft: '5%',
                marginBottom: '3%',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                minHeight: '9.25rem',
                background: '#fff',
                color: '#000',
                borderRadius: '1.8rem',
                boxShadow: '0px 0px 5px rgba(50, 50, 50, 0.25)',
            }}
            buttonStyle={{
                background: '#FF5000',
                color: '#fff',
                fontSize: '13px',
                width: '9.5rem',
                height: '3rem',
                fontWeight: 'bold',
                borderRadius: '2.2rem',
            }}
        >
            {children}
        </CookieConsent>
    );
};
