import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import arrowLeft from '../assets/svgs/arrow_left-icon.svg';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { CustomIcon } from './CustomIcon';
import { NotificationSideBar } from './NotificationSideBar';

export type NotificationLayoutProps = {
    children: ReactNode;
    title: string;
};

export const NotificationLayout = ({
    title,
    children,
}: NotificationLayoutProps) => {
    const isMobile = useMediaQuery('(max-width: 700px)');
    const navigate = useNavigate();

    return (
        <div className="flex">
            {!isMobile && <NotificationSideBar />}
            <div className="w-screen h-auto">
                <h2 className="bg-[#f0f0f0] px-4 md:px-14 py-7 text-[#000000] text-[1.5rem] md:text-[2rem] font-bold">
                    {title}
                </h2>
                <div className="px-4 md:px-14 pb-8">
                    <button
                        className="mt-22 py-7 underline text-[#001E64] flex gap-2 font-bold cursor-pointer"
                        onClick={() => navigate(-1)}
                    >
                        <CustomIcon
                            src={arrowLeft}
                            width={isMobile ? 20 : 24}
                            height={isMobile ? 20 : 24}
                        />
                        <p>Voltar</p>
                    </button>
                    <div className="min-h-[calc(100vh-305px)]">{children}</div>
                </div>
            </div>
        </div>
    );
};
