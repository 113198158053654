/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
import { Popover } from '@radix-ui/themes';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import notificationImage from '../assets/notification-image.png';
import arrowRightIcon from '../assets/svgs/arrow_right-icon.svg';
import chevronLeftCircleIcon from '../assets/svgs/chevron_left_circle-icon.svg';
import closeIcon from '../assets/svgs/close-icon.svg';
import deleteIcon from '../assets/svgs/delete-icon.svg';
import notificationIcon from '../assets/svgs/notification-icon.svg';
import settingsIcon from '../assets/svgs/settings-icon.svg';
import { useNotificationContext } from '../contexts/NotificationContext';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { api } from '../services/api';
import { getDateFormatted } from '../utils/getDateFormatted';
import { getTextWithBold } from '../utils/getTextWithBold';
import { CustomIcon } from './CustomIcon';
import { TooltipComponent } from './Tooltip';

type NotificationType = {
    assunto: string;
    conteudo: { segmento: string | null };
    created_at: string;
    id: string;
    id_notificacao: string;
    id_usuario: string;
    lida: boolean;
    updated_at: string;
};

export type ButtonsMobileType = {
    label: string;
    icon: JSX.Element;
    border: string;
    color: string;
    background: string;
    action: () => void | Promise<void>;
    disable: boolean;
};

type NotificationButtonProps = {
    buttonsMobile?: ButtonsMobileType[];
    rightSpace?: string;
    topSpace?: string;
    children: ReactNode;
};

export const NotificationButton = ({
    buttonsMobile,
    rightSpace,
    topSpace,
    children,
}: NotificationButtonProps) => {
    const navigate = useNavigate();
    const { toggleNotifications, openNotifications } = useNotificationContext();
    const location = useLocation();

    const isMobile = useMediaQuery('(max-width: 700px)');

    const [totalNotification, setTotalNotification] = useState(0);

    const [notifications, setNotifications] = useState<NotificationType[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [notShowNotificationToast, setNotShowNotificationToast] =
        useState(false);

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    const notificationObj = {
        'novo lead': 'Você recebeu um novo lead.',
        'novos leads': 'Você recebeu novos leads.',
        'nova venda': 'Uma venda acaba de ser concluída.',
        'novo agendamento': 'Você tem um agendamento',
        'novos agendamentos': 'Você tem novos agendamentos',
    };

    const buttonsMobileDefault = buttonsMobile
        ? buttonsMobile
        : [
              {
                  icon: <CustomIcon src="" />,
                  label: 'Ver todas as notificações',
                  border: 'transparent',
                  color: '#FFFFFF',
                  background: '#001E64',
                  action: () => {
                      navigate('/area-logada/notificacoes');
                      enableScroll();
                  },
                  disable: false,
              },
              {
                  icon: (
                      <CustomIcon
                          src={settingsIcon}
                          width={21}
                          height={19}
                          color="#2A4E9A"
                      />
                  ),
                  label: 'Configurar notificação',
                  border: '#2A4E9A',
                  color: '#2A4E9A',
                  background: '#FFFFFF',
                  action: () => {
                      navigate('/area-logada/notificacoes/configuracoes');
                      enableScroll();
                  },
                  disable: false,
              },
          ];

    const getTotalNotifications = async () => {
        try {
            const response = await api.get(
                `v1/notificacoes/notificacoes-tela?lida=false&_limit=${1}&_offset=${0}`,
                { headers: { Authorization: user.access_token } }
            );

            setTotalNotification(response.data._embedded._total);
        } catch (error) {
            console.log(error);
        }
    };

    const getNotifications = async () => {
        setIsLoading(true);
        getTotalNotifications();

        try {
            const responseLida = await api.get(
                `v1/notificacoes/notificacoes-tela?lida=false&_limit=${3}&_offset=${0}`,
                { headers: { Authorization: user.access_token } }
            );

            const responseNoLida = await api.get(
                `v1/notificacoes/notificacoes-tela?lida=true&_limit=${3}&_offset=${0}`,
                { headers: { Authorization: user.access_token } }
            );
            const items = [
                ...responseLida.data._embedded._result,
                ...responseNoLida.data._embedded._result,
            ];

            setNotifications(items.slice(0, 3));
            setIsLoading(false);
        } catch (error) {
            console.log(error);

            setIsLoading(false);
        }
    };

    const deleteNotifications = async (ids: string[]) => {
        const joinedIDs = ids.join(',');

        try {
            await api.delete(
                `v1/notificacoes/notificacoes-tela?ids=${joinedIDs}`,
                { headers: { Authorization: user.access_token } }
            );

            const newNotifications = notifications.filter(
                (item) => !ids.includes(item.id)
            );

            setNotifications(newNotifications);
            getTotalNotifications();
        } catch (error) {
            console.log(error);
        }
    };

    const handlePageRedirection = (path?: string) => {
        window.location.pathname = `/${path ? path : ''}`;
    };

    const headersIcons = [
        // {
        //   icon: messageIcon,
        //   tolltip: 'Marcar todas como lida',
        //   action: deleteAllNotifications
        // },
        {
            icon: settingsIcon,
            tolltip: 'Configurar notificação',
            action: handlePageRedirection,
        },
    ];

    const pathName = window.location.pathname;

    const disableScroll = () => {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        setNotShowNotificationToast(
            pathName.length <= 13 || !pathName.startsWith('/area-logada')
        );
    }, [location]);

    return (
        <Popover.Root
            open={openNotifications}
            onOpenChange={toggleNotifications}
        >
            <Popover.Trigger
                style={{
                    display: notShowNotificationToast ? 'none' : 'block',
                }}
            >
                <button
                    style={{
                        right: rightSpace,
                        top: topSpace,
                        visibility:
                            isMobile && openNotifications
                                ? 'hidden'
                                : 'visible',
                    }}
                    className="absolute top-8 text-[#001E64] flex z-30"
                    onClick={() => {
                        toggleNotifications();
                        if (isMobile) {
                            disableScroll();
                        }
                    }}
                >
                    <CustomIcon
                        src={notificationIcon}
                        width={isMobile ? 24 : 28}
                        height={28}
                    />
                    {totalNotification > 0 && (
                        <div className="bg-[#AA2C23] text-white rounded-full w-[0.9rem] p-2 h-[0.9rem] text-[0.575rem] font-bold flex justify-center items-center absolute top-0 left-4">
                            {totalNotification}
                        </div>
                    )}
                </button>
            </Popover.Trigger>
            {isMobile && openNotifications ? (
                <div className="fixed top-0 left-0 h-[100vh] w-full bg-white z-10 text-base flex flex-col justify-been">
                    <div className="flex justify-between items-center bg-[#F4F4F4] py-5 px-4">
                        <button
                            className="focus:outline-none text-[#001E64]"
                            onClick={() => {
                                toggleNotifications();
                                if (isMobile) {
                                    enableScroll();
                                }
                            }}
                        >
                            <CustomIcon src={chevronLeftCircleIcon} />
                        </button>
                        <p className="text-[#323232] text-xl font-bold">
                            Notificações
                        </p>
                        <button
                            className="focus:outline-none"
                            onClick={() => {
                                toggleNotifications();
                                if (isMobile) {
                                    enableScroll();
                                }
                            }}
                        >
                            <CustomIcon
                                src={closeIcon}
                                width={15}
                                height={15}
                            />
                        </button>
                    </div>
                    <div className="h-full overflow-auto bg-white">
                        {notifications.length >= 1 ? (
                            notifications?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            borderBottom:
                                                index + 1 !==
                                                    notifications.length &&
                                                '1px solid #F4F4F4',
                                        }}
                                        className="flex justify-been pt-5 hover:bg-[#E9EDF4] transition-opacity"
                                    >
                                        <ul
                                            style={{
                                                color: item?.lida
                                                    ? '#001E64'
                                                    : '#FF5000',
                                            }}
                                            className="list-disc"
                                        >
                                            <div className="ml-6 px-5 pb-5">
                                                <li className="text-[1.5rem]">
                                                    <span className="text-[1rem] font-bold">
                                                        {item?.lida
                                                            ? 'Lida'
                                                            : 'Não Lida'}
                                                    </span>
                                                </li>
                                                <p className="text-[#828282] font-normal">
                                                    {
                                                        getDateFormatted(
                                                            item?.updated_at
                                                        ).date
                                                    }
                                                    &nbsp;
                                                    {
                                                        getDateFormatted(
                                                            item?.updated_at
                                                        ).time
                                                    }
                                                </p>
                                                <strong className="text-black">
                                                    {item?.assunto}
                                                </strong>
                                                <p className="text-black font-normal">
                                                    {
                                                        notificationObj[
                                                            String(
                                                                item?.assunto
                                                            ).toLowerCase()
                                                        ]
                                                    }
                                                </p>
                                                <button
                                                    style={{
                                                        color: item?.lida
                                                            ? '#001E64'
                                                            : '#FF5000',
                                                    }}
                                                    className="flex gap-2 underline font-bold mt-6"
                                                    onClick={() => {
                                                        toggleNotifications();
                                                        navigate(
                                                            '/area-logada/notificacoes'
                                                        );
                                                    }}
                                                >
                                                    Ver mais detalhes
                                                    <CustomIcon
                                                        src={arrowRightIcon}
                                                        width={20}
                                                        height={20}
                                                        color={
                                                            item?.lida
                                                                ? '#001E64'
                                                                : '#FF5000'
                                                        }
                                                    />
                                                </button>
                                            </div>
                                        </ul>
                                        <button
                                            className="flex flex-col"
                                            onClick={() => {
                                                const arr = [item?.id];
                                                deleteNotifications(arr);
                                            }}
                                        >
                                            <CustomIcon src={deleteIcon} />
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-[#828282] text-5 text-center flex flex-col justify-center items-center h-full">
                                <img
                                    src={notificationImage}
                                    alt="notification-image"
                                />
                                Não há notificações!
                            </p>
                        )}
                    </div>
                    <hr />
                    <div className="py-5 pt-7 px-4">
                        {buttonsMobileDefault
                            .slice(
                                notifications.length > 0 ? 0 : 1,
                                notifications.length > 0 ? undefined : 2
                            )
                            .map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            marginBottom:
                                                index !==
                                                    buttonsMobileDefault.length -
                                                        1 && '0.63rem',
                                        }}
                                    >
                                        <button
                                            disabled={item.disable}
                                            style={{
                                                color: item.color,
                                                background: item.background,
                                                borderColor: item.border,
                                                cursor: item.disable
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                                opacity: item.disable && '0.5',
                                            }}
                                            className="md:hidden border font-bold px-11 h-10 rounded-full w-full flex items-center justify-center"
                                            onClick={() => {
                                                item.action();
                                            }}
                                        >
                                            <div className="flex gap-2">
                                                <span>{item.icon}</span>
                                                <span>{item.label}</span>
                                            </div>
                                        </button>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : (
                <Popover.Content className="overflow-auto min-h-[16rem] mt-5 mr-8 w-[27rem] max-h-[37rem] bg-white flex-col justify-between shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl">
                    <div>
                        <div className="h-11 flex items-center justify-between px-5 py-1.5 bg-[#F4F4F4] rounded-t-xl">
                            <span>Notificações</span>
                            <div className="flex gap-2">
                                {headersIcons.map((icon, index) => {
                                    return (
                                        <TooltipComponent
                                            tooltip={icon.tolltip}
                                            key={index}
                                        >
                                            <div
                                                className="rounded-full bg-[#d2d2d2a3] hover:bg-[#E9EDF4] transition-opacity flex items-center justify-center p-2 cursor-pointer"
                                                onClick={() =>
                                                    icon.action(
                                                        'area-logada/notificacoes/configuracoes'
                                                    )
                                                }
                                            >
                                                <CustomIcon
                                                    src={icon.icon}
                                                    width={18}
                                                    height={16}
                                                />
                                            </div>
                                        </TooltipComponent>
                                    );
                                })}
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="py-5 flex items-center justify-center h-64">
                                <div
                                    style={{ borderTopColor: 'transparent' }}
                                    className="w-12 h-12 border-4 border-[#001E64] border-solid rounded-full animate-spin"
                                ></div>
                            </div>
                        ) : notifications.length >= 1 ? (
                            notifications?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            borderBottom:
                                                index + 1 !==
                                                    notifications.length &&
                                                '1px solid #F4F4F4',
                                        }}
                                        className="flex justify-between pt-5 pr-6 hover:bg-[#E9EDF4] transition-opacity"
                                    >
                                        <ul
                                            style={{
                                                color: item?.lida
                                                    ? '#001E64'
                                                    : '#FF5000',
                                            }}
                                            className="list-disc"
                                        >
                                            <div className="ml-6 px-5 pb-5">
                                                <li className="text-[1.5rem]">
                                                    <span className="text-[1rem] font-bold">
                                                        {item?.lida
                                                            ? 'Lida'
                                                            : 'Não Lida'}
                                                    </span>
                                                </li>
                                                <p className="text-[#828282] font-normal">
                                                    {
                                                        getDateFormatted(
                                                            item?.updated_at
                                                        ).date
                                                    }
                                                    &nbsp;
                                                    {
                                                        getDateFormatted(
                                                            item?.updated_at
                                                        ).time
                                                    }
                                                </p>
                                                <strong className="text-black">
                                                    {item?.assunto}
                                                </strong>
                                                <p className="text-black font-normal">
                                                    {
                                                        notificationObj[
                                                            String(
                                                                item?.assunto
                                                            ).toLowerCase()
                                                        ]
                                                    }
                                                </p>
                                                <button
                                                    style={{
                                                        color: item?.lida
                                                            ? '#001E64'
                                                            : '#FF5000',
                                                    }}
                                                    className="flex gap-2 underline font-bold mt-6"
                                                    onClick={() => {
                                                        toggleNotifications();
                                                        navigate(
                                                            '/area-logada/notificacoes'
                                                        );
                                                    }}
                                                >
                                                    Ver mais detalhes
                                                    <CustomIcon
                                                        src={arrowRightIcon}
                                                        width={20}
                                                        height={20}
                                                        color={
                                                            item?.lida
                                                                ? '#001E64'
                                                                : '#FF5000'
                                                        }
                                                    />
                                                </button>
                                            </div>
                                        </ul>
                                        <button
                                            className="flex flex-col"
                                            onClick={() => {
                                                const arr = [item?.id];
                                                deleteNotifications(arr);
                                            }}
                                        >
                                            <CustomIcon src={deleteIcon} />
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-[#828282] h-64 text-5 text-center flex flex-col justify-center items-center">
                                <img
                                    src={notificationImage}
                                    alt="notification-image"
                                />
                                Não há notificações!
                            </p>
                        )}
                    </div>
                    {/* <div
                        style={{
                            display: totalNotification >= 1 ? 'flex' : 'none',
                        }}
                        className="h-11 items-center px-5 py-1.5 bg-[#F4F4F4] rounded-b-xl underline"
                    >
                        <button
                            className="text-[#001E64] font-bold cursor-pointer"
                            onClick={() =>
                                handlePageRedirection(
                                    'area-logada/notificacoes'
                                )
                            }
                        >
                            Ver todas as notificações
                        </button>
                    </div> */}
                </Popover.Content>
            )}
            {children}
        </Popover.Root>
    );
};
