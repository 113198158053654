import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthContextProvider } from '../contexts/AuthContext';
import { DragContextProvider } from '../contexts/DragContext';
import { FilterContextProvider } from '../contexts/FilterContext';
import { RatingContextProvider } from '../contexts/RatingContext';
import AtivacaoConta from '../pages/AtivacaoConta';
import Cadastro from '../pages/Cadastro';
import { ConfiguradorNotificacoes } from '../pages/ConfiguradorNotificacoes';
import { ConfirmacaoEmail } from '../pages/ConfirmacaoEmail';
import CriarProposta from '../pages/CriarProposta';
import { DetalhesLead } from '../pages/DetalhesLeads';
import DuvidasFrequentes from '../pages/DuvidasFrequentes';
import FormChamado from '../pages/FormChamado';
import Home from '../pages/Home';
import KanbanLeads from '../pages/KanbanLeads';
import Login from '../pages/Login';
import { NaoAutorizado } from '../pages/NaoAutorizado';
import { Notificacoes } from '../pages/Notificacao';
import NovaHome from '../pages/NovaHome';
import Propostas from '../pages/Propostas';
import RecuperarSenha from '../pages/RecuperarSenha';
import RedefinicaoSenha from '../pages/RedefinicaoSenha';
import Suporte from '../pages/Suporte';
import { AreaLogadaRoutes } from './AreaLogadaRoutes';

function AppRoutes() {
    const [isLinked, setIsLinked] = useState(true);

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    useEffect(() => {
        if (user?.access_token) {
            const decodedJwt: any = jwt_decode(user?.access_token);

            setIsLinked(decodedJwt?.response?.isLinked);
        }
    }, [user]);

    return (
        <FilterContextProvider>
            <AuthContextProvider>
                <RatingContextProvider>
                    <DragContextProvider>
                        <Routes>
                            <Route
                                path="area-logada"
                                element={<AreaLogadaRoutes />}
                            >
                                <Route index element={<NovaHome />} />
                                {isLinked && (
                                    <>
                                        <Route
                                            path="leads"
                                            element={<KanbanLeads />}
                                        />

                                        <Route
                                            path="leads/:idLead"
                                            element={<DetalhesLead />}
                                        />
                                    </>
                                )}
                                <Route
                                    path="propostas"
                                    element={<Propostas />}
                                />
                                <Route path="suporte" element={<Suporte />} />
                                <Route
                                    path="suporte/chamado"
                                    element={<FormChamado />}
                                />

                                <Route
                                    path="notificacoes"
                                    element={<Notificacoes />}
                                />

                                <Route
                                    path="notificacoes/configuracoes"
                                    element={<ConfiguradorNotificacoes />}
                                />
                            </Route>

                            <Route path="/" element={<Home />} />
                            <Route path="cadastro" element={<Cadastro />} />
                            <Route
                                path="duvidas-frequentes"
                                element={<DuvidasFrequentes />}
                            />

                            <Route path="login" element={<Login />} />
                            <Route
                                path="recuperar-senha"
                                element={<RecuperarSenha />}
                            />
                            <Route
                                path="redefinicao-de-senha"
                                element={<RedefinicaoSenha />}
                            />
                            <Route
                                path="ativacao-de-conta"
                                element={<AtivacaoConta />}
                            />

                            <Route
                                path="nao-autorizado"
                                element={<NaoAutorizado />}
                            />
                            <Route
                                path="confirmacao-email"
                                element={<ConfirmacaoEmail />}
                            />
                        </Routes>
                    </DragContextProvider>
                </RatingContextProvider>
            </AuthContextProvider>
        </FilterContextProvider>
    );
}

export default AppRoutes;
