import Footer from '../components/Footer';
import FormCadastro from '../components/FormCadastro';
import NavBar from '../components/NavBar';

function Cadastro() {
    return (
        <>
            <NavBar hideBtnCadastrar />
            <div style={{ background: '#F5F5F5' }}>
                <FormCadastro />
            </div>
            <Footer />
        </>
    );
}

export default Cadastro;
