import React, { createContext, ReactNode, useState } from 'react';

type DragProps = {
    dragState: boolean;
    setDragState: React.Dispatch<React.SetStateAction<boolean>>;
};
type AuthContextProviderProps = {
    children: ReactNode;
};

const initialValues = {
    dragState: false,

    setDragState: () => {
        ('');
    },
};

const DragContext = createContext<DragProps>(initialValues);

const DragContextProvider = ({ children }: AuthContextProviderProps) => {
    const [dragState, setDragState] = useState(initialValues.dragState);

    return (
        <DragContext.Provider
            value={{
                dragState,
                setDragState,
            }}
        >
            {children}
        </DragContext.Provider>
    );
};
export { DragContextProvider };
export default DragContext;
