import 'react-datepicker/dist/react-datepicker.css';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiArrowToBottom, BiChevronDown, BiSearch } from 'react-icons/bi';
import { BsFillArrowDownSquareFill } from 'react-icons/bs';

type ModalFilterProps = {
    leads: any[];
    campaigns: string[];
    isModalFilterOpen: boolean;
    toggleModalFilter: () => void;
    findLeadForFilter: (searchValue: any) => any;
    findCampaignsForFilter: (searchValue: string[]) => void;
    exportData: () => Promise<void>;
};

export const ModalFilter = ({
    leads,
    campaigns,
    exportData,
    isModalFilterOpen,
    toggleModalFilter,
    findLeadForFilter,
    findCampaignsForFilter,
}: ModalFilterProps) => {
    const [open, setOpen] = useState(false);

    const cancelButtonRef = useRef(null);

    const [openSelect, setOpenSelect] = useState(false);

    const [checkboxes, setCheckboxes] = useState([]);

    const [leadsNameList] = useState(['']);

    const [filter, setFilter] = useState('');
    const [name, setName] = useState('');

    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

    const filterLeadsName = leadsNameList.filter((name) =>
        name?.toLowerCase().includes(filter.toLowerCase())
    );

    const newFilterLeadsName = filterLeadsName.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });

    const handleChange = (event) => {
        setFilter(event.target.value);
        setName(event.target.value);
    };

    const handleCheckboxChange = (value) => {
        const isChecked = checkboxes.includes(value);

        if (isChecked) {
            const novosValores = checkboxes.filter((item) => item !== value);
            setCheckboxes(novosValores);
        } else {
            setCheckboxes([...checkboxes, value]);
        }
    };

    useEffect(() => {
        const prop = 'NOME__c';

        for (let i = 0; i < leads.length; i++) {
            for (let j = 0; j < leads[i].length; j++) {
                const obj = leads[i][j];
                if (prop in obj) {
                    const value = obj[prop];
                    leadsNameList.push(value);
                }
            }
        }

        if (isModalFilterOpen) {
            setOpen(true);
        }
    });

    const handleFilterLeadsName = (name: string) => {
        setName(name);
        setFilter('');
    };

    const handleSearch = () => {
        setOpen(false);
        setOpenSelect(false);
        toggleModalFilter();
        if (name) {
            findLeadForFilter(name);
        } else if (checkboxes) {
            findCampaignsForFilter(checkboxes);
        } else {
            return;
        }
    };

    useEffect(() => {
        setName('');
        setButtonIsDisabled(false);
    }, [open]);

    useEffect(() => {
        if (checkboxes.length > 0 || name) {
            setButtonIsDisabled(true);
        } else {
            setButtonIsDisabled(false);
        }
    }, [name, checkboxes]);

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={() => {
                        setOpen(false);
                        toggleModalFilter();
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="">
                                    <div className="bg-white w-[328px] rounded-3xl">
                                        <div className="sm:flex mb:flex-row sm:flex-col sm:items-start ">
                                            <div className=" text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="w-full text-base font-semibold leading-6 text-gray-900 flex justify-between items-center"
                                                >
                                                    <div className="w-full max-w-[328px]">
                                                        <div className="w-full flex justify-between px-[20px] pt-[20px]">
                                                            <span className="flex">
                                                                Filtrar
                                                            </span>
                                                            <button
                                                                className="focus:outline-none active:outline-none hover:outline-none"
                                                                onClick={() => {
                                                                    setOpen(
                                                                        false
                                                                    );
                                                                    toggleModalFilter();
                                                                }}
                                                            >
                                                                <AiOutlineClose size="1.5rem" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Dialog.Title>
                                                <hr className="border-b-1 border-[#E3E3E3] h-0 my-[20px]" />
                                                <div className="w-full px-[20px]">
                                                    <p className="w-full ml-[5px] font-bold text-[1rem] flex items-center gap-[5px]">
                                                        Gestão de Campanhas
                                                    </p>
                                                    <button
                                                        placeholder="Selecionar"
                                                        className="rounded-3xl bg-white w-full border-[0.1rem] border-[black] mt-[15px]"
                                                        onClick={() => {
                                                            setCheckboxes([]);

                                                            setOpenSelect(
                                                                !openSelect
                                                            );
                                                        }}
                                                    >
                                                        <div className="flex justify-between items-center px-[0.8rem] h-[2.3rem]">
                                                            <p>Selecionar</p>

                                                            <BiChevronDown className="text-[#000000] w-[24px] h-[24px]" />
                                                        </div>
                                                    </button>
                                                    <div
                                                        className={`w-[90%] rounded-lg bg-white mt-[0.5vh] p-[0.5rem] border-[#c3c3c3] border-[0.1rem] left-5 absolute z-50 ${
                                                            openSelect
                                                                ? 'visible'
                                                                : 'hidden'
                                                        }`}
                                                    >
                                                        <div className="overflow-y-scroll">
                                                            <div className="">
                                                                {campaigns?.map(
                                                                    (
                                                                        campaign,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                className="flex items-center"
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="border-black"
                                                                                    checked={checkboxes.includes(
                                                                                        campaign
                                                                                    )}
                                                                                    onChange={() => {
                                                                                        handleCheckboxChange(
                                                                                            campaign
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <p className="text-[0.874rem]">
                                                                                    {
                                                                                        campaign
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="border-b-1 border-[#E3E3E3] h-0 my-[20px]" />
                                                <div className="w-full px-[20px]">
                                                    <p className="w-full ml-[5px] font-bold text-[1rem] flex items-center gap-[5px] ">
                                                        Leads
                                                        <span className="font-normal text-[1rem]">
                                                            <BiArrowToBottom />
                                                        </span>
                                                    </p>
                                                    <div className="relative">
                                                        <span className="absolute left-[2.5%] bottom-[20%]">
                                                            <BiSearch />
                                                        </span>
                                                        <input
                                                            placeholder="Buscar nome"
                                                            value={name}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setOpenSelect(
                                                                    false
                                                                );
                                                                handleChange(
                                                                    event
                                                                );
                                                            }}
                                                            className="w-full h-[2.3rem] px-[2rem] rounded-3xl border-[black] border-[0.1rem] mt-[15px] focus:outline-none active:outline-none hover:outline-none"
                                                        />
                                                        {filter && (
                                                            <div
                                                                className="w-full absolute left-[60x] top-12 shadow-lg text-start rounded-lg bg-white mt-[1vh] p-[0.5rem] border-[#c3c3c3] border-[0.1rem]"
                                                                role="menu"
                                                                aria-orientation="vertical"
                                                            >
                                                                <ul>
                                                                    {newFilterLeadsName.map(
                                                                        (
                                                                            name,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="text-[0.875rem] py-0.5 px-4 hover:bg-gray-100 cursor-pointer"
                                                                                    onClick={() =>
                                                                                        handleFilterLeadsName(
                                                                                            name
                                                                                        )
                                                                                    }
                                                                                    onKeyDown={(
                                                                                        event
                                                                                    ) => {
                                                                                        if (
                                                                                            event.key ===
                                                                                                'Enter' ||
                                                                                            event.key ===
                                                                                                ' '
                                                                                        ) {
                                                                                            handleFilterLeadsName(
                                                                                                name
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    role="menuitem"
                                                                                    tabIndex={
                                                                                        0
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        name
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <hr className="border-b-1 border-[#E3E3E3] h-0 my-[20px]" />
                                                <div className="w-full px-[20px]">
                                                    <p className="w-full ml-[5px] font-bold text-[1rem] flex items-center gap-[16px]">
                                                        Exportar Leads
                                                    </p>

                                                    <button
                                                        className="w-full h-[2.3rem] px-[2rem] mt-[10px]  rounded-3xl  bg-[#2A4E9A] flex justify-center items-center gap-[10px]"
                                                        onClick={() => {
                                                            exportData();
                                                        }}
                                                        placeholder="Buscar lead"
                                                    >
                                                        <BsFillArrowDownSquareFill color="white" />
                                                        <span className="pl-1  text-white">
                                                            Exportar
                                                        </span>
                                                    </button>
                                                </div>
                                                <hr className="border-b-1 border-[#E3E3E3] h-0 my-[20px]" />
                                                <div className="w-full px-[20px] pb-[20px] flex items-center">
                                                    <button
                                                        disabled={
                                                            !buttonIsDisabled
                                                        }
                                                        onClick={handleSearch}
                                                        className={`w-full h-[2.3rem] rounded-3xl ${
                                                            !buttonIsDisabled
                                                                ? 'border-[#b3b2b2] bg-[#b3b2b2] cursor-not-allowed'
                                                                : 'border-[#FF5000] bg-[#FF5000]'
                                                        } text-[#FFFFFF] border-[0.1rem] focus:outline-none active:outline-none hover:outline-none`}
                                                    >
                                                        Buscar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
