import jwt_decode from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import copyIcon from '../assets/svgs/copy-icon.svg';
import searchImageNotFound from '../assets/Vector.png';
import { KanbanResponsiveProps } from '../types/KanbanResponsiveProps';
import { CustomIcon } from './CustomIcon';

type ShowOrHidePhoneProps = {
    phone: string;
    celphone: string;
    canShow: boolean;
};

const ShowOrHidePhone = ({
    phone,
    celphone,
    canShow,
}: ShowOrHidePhoneProps) => {
    const formatCel = (number = '') => {
        if (!number) return;

        let formating = String(number).replace(/\D/g, '');

        formating = formating.replace(/^0/, '');

        if (formating.length >= 12) {
            formating = formating.slice(2);
            if (formating.length === 10) {
                formating = formating.replace(
                    /^(\d\d)(\d{4})(\d{4}).*/,
                    '($1) $2-$3'
                );
                return formating;
            }
            formating = formating.replace(
                /^(\d\d)(\d{5})(\d{4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length === 11) {
            formating = formating.replace(
                /^(\d\d)(\d{5})(\d{4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length > 10) {
            formating = formating.replace(
                /^(\d\d)(\d{5})(\d{4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length > 5) {
            formating = formating.replace(
                /^(\d\d)(\d{4})(\d{0,4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length > 2) {
            formating = formating.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else {
            formating = formating.replace(/^(\d*)/, '($1)');
        }
        return formating;
    };

    const getTelephoneOrCellPhoneNumber = (telephone: string, cell: string) => {
        const telephoneFormatted = telephone?.replace(/\D/g, '');
        const cellFormatted = cell?.replace(/\D/g, '');

        let phoneNumber = '';

        if (
            cellFormatted &&
            telephoneFormatted &&
            telephoneFormatted?.length >= 10 &&
            cellFormatted?.length >= 10
        ) {
            phoneNumber = `${formatCel(cellFormatted)} / ${formatCel(
                telephoneFormatted
            )}`;
        } else if (telephoneFormatted && telephoneFormatted?.length >= 10) {
            phoneNumber = `${formatCel(telephoneFormatted)}`;
        } else if (cellFormatted && cellFormatted?.length >= 10) {
            phoneNumber = `${formatCel(cellFormatted)}`;
        } else {
            phoneNumber = '';
        }

        return phoneNumber;
    };
    if (!canShow) {
        const ddd = phone.slice(0, 2);

        return (
            <div>
                <p>{ddd}</p>
                <div className="w-[5.73188rem] h-[1.48188rem] bg-[#F4F4F4] blur-[2px]" />
            </div>
        );
    } else
        return (
            <p className="text-start">
                {getTelephoneOrCellPhoneNumber(phone, celphone)}
            </p>
        );
};

export const KanbanResponsive = ({
    statusList,
    column,
    leadStatus,
    setLeadStatus,
    copyText,
    handleOpeningModalStatus,
    setIsKanbanResponsive,
    isLoading,
    leads,
    setLeads,
}: KanbanResponsiveProps) => {
    const navigate = useNavigate();
    const userRef = useRef(null);

    const [dropdownLeadStatusIsOpen, setDropdownLeadStatusIsOpen] =
        useState(false);

    const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

    const [statusSelectButtonState, setStatusSelectButtonState] = useState({
        items: [],
        color: '',
        name: '',
    });

    const toggleLeadStatusDropdown = () => {
        setDropdownLeadStatusIsOpen(!dropdownLeadStatusIsOpen);
    };

    const toggleForChangeLeadStatusDropdown = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(-1);
        } else {
            setOpenDropdownIndex(index);
        }

        setIsKanbanResponsive(true);
    };

    const handleShowCelular = (idLead: string) => {
        setTimeout(() => {
            const updatedLeads = { ...leads };

            Object.keys(updatedLeads).forEach((key) => {
                updatedLeads[key].items.forEach((item) => {
                    if (item.ID_LEAD__c === idLead) {
                        item.USUARIOS_VISUALIZACOES__c.push(userRef.current);
                    }

                    // console.log(item.USUARIOS_VISUALIZACOES__c);
                });
            });

            setLeads(updatedLeads);
        }, 1000);
    };

    const checkHasVisualization = (
        userId: string,
        USUARIOS_VISUALIZACOES__c: string[]
    ) => {
        // console.log(userId);
        // console.log(USUARIOS_VISUALIZACOES__c);
        return USUARIOS_VISUALIZACOES__c?.includes(userId);
    };

    useEffect(() => {
        setStatusSelectButtonState(column);
    }, [leadStatus, column]);

    useEffect(() => {
        const user =
            sessionStorage.getItem('@grana-extra') !== 'null'
                ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

        const decodeJwt: any = jwt_decode(user.access_token);

        userRef.current = decodeJwt.response.cpf;
    }, []);

    return (
        <div
            className={`${
                column.status == leadStatus
                    ? 'mb:block lg:hidden my-[20px]'
                    : 'hidden'
            }`}
        >
            <div className="relative ">
                <button
                    className={`flex text-[#FFFFFF]  mb-[30px] -border-[1px] border-[${statusSelectButtonState.color}]  items-center justify-between w-full h-[40px] bg-[${statusSelectButtonState.color}] rounded-3xl text-[0.875rem] cursor-pointer px-[30px]`}
                    onClick={() => toggleLeadStatusDropdown()}
                >
                    {`${statusSelectButtonState.name} (${statusSelectButtonState.items?.length})`}
                    <span>
                        <BiChevronDown className="text-[#FFFFFF] w-[24px] h-[24px]" />
                    </span>
                </button>
                {statusSelectButtonState.items?.length === 0 ? (
                    <div className="w-full flex flex-col items-center justify-center py-[45px] gap-[30px]">
                        <img src={searchImageNotFound} alt="" />
                        <p className="text-[#828282] w-[90%] text-center">
                            Não existe nenhuma lead com este status no momento
                        </p>
                    </div>
                ) : null}
                {dropdownLeadStatusIsOpen && (
                    <div
                        className="w-full absolute left-[60x] top-9 shadow-lg text-start rounded-lg text-[0.875rem] bg-white mt-[1vh] p-[0.2rem] border-[#c3c3c3] border-[0.1rem]"
                        role="menu"
                        aria-orientation="vertical"
                    >
                        {statusList.map((status, index) => {
                            return (
                                <ul key={index}>
                                    <li
                                        className="py-1 px-4 hover:bg-gray-100 cursor-pointer"
                                        onClick={() => {
                                            setLeadStatus(status.status);
                                            setDropdownLeadStatusIsOpen(false);
                                        }}
                                        onKeyDown={(event) => {
                                            if (
                                                event.key === 'Enter' ||
                                                event.key === ' '
                                            ) {
                                                setLeadStatus(status.status);
                                            }
                                        }}
                                        role="menuitem"
                                        tabIndex={0}
                                    >
                                        {`${status.name} (${status.items})`}
                                    </li>
                                </ul>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="flex flex-col gap-[20px] w-full scrollbar overflow-x-hidden overflow-y-auto items-center">
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    column.items.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`pl-[24px] pr-[20px] py-[20px] w-full flex flex-col bg-[#fff] rounded-xl justify-start border-l-[0.5vh] ${
                                    item.SOLICITOU_CONTATO__c === 'true'
                                        ? 'border-[#FF5000]'
                                        : column.border
                                }`}
                            >
                                <div className="flex items-start justify-between">
                                    <div className="font-bold text-[1.25rem] mb-[5px]">
                                        {item.NOME__c}
                                    </div>
                                    <div className="pr-5">
                                        {item.SOLICITOU_CONTATO__c ===
                                            'true' && <FireIcon />}
                                    </div>
                                </div>
                                {item.EMAIL__c && (
                                    <div className="break-all flex items-center gap-[5px]">
                                        <button
                                            className="flex justify-start items-center text-start text-[1rem] mb-[0.4rem] cursor-pointer"
                                            onClick={() => {
                                                copyText(item.EMAIL__c);
                                            }}
                                        >
                                            <div className="flex items-start gap-[5px] mr-[0.4rem text-[1rem]">
                                                <CustomIcon src={copyIcon} />
                                                {item.EMAIL__c}
                                            </div>
                                        </button>
                                    </div>
                                )}
                                <button
                                    style={{
                                        display:
                                            (item.TELEFONE__c ||
                                                item.CELULAR__c ||
                                                '') === '0'
                                                ? 'none'
                                                : 'flex',
                                    }}
                                    className="justify-start items-center mb-[0.4rem] text-[1rem] break-words cursor-pointer"
                                    onClick={() => {
                                        copyText(
                                            item.USUARIOS_VISUALIZACOES__c?.includes(
                                                userRef.current
                                            )
                                                ? item.CELULAR__c ||
                                                      item.TELEFONE__c
                                                : ''
                                        );
                                    }}
                                >
                                    <div className="flex items-start gap-[5px] mr-[0.4rem] text-[1rem] break-words">
                                        <CustomIcon src={copyIcon} />
                                        <ShowOrHidePhone
                                            phone={item.TELEFONE__c || ''}
                                            celphone={item.CELULAR__c || ''}
                                            canShow={checkHasVisualization(
                                                userRef.current,
                                                item.USUARIOS_VISUALIZACOES__c
                                            )}
                                        />
                                    </div>
                                </button>
                                {item.Campanha__c === 'Sem Campanha' ? (
                                    <></>
                                ) : (
                                    <div
                                        className={`max-w-[156px] py-[5px] px-[19px] mt-[10px] mb-[20px] rounded-3xl ${
                                            item.SOLICITOU_CONTATO__c === 'true'
                                                ? 'bg-[#FF5000]'
                                                : column.bg
                                        } text-[0.688rem] text-[#FFFFFF] overflow-hidden truncate`}
                                    >
                                        {item.Campanha__c}
                                    </div>
                                )}
                                {item.NOME_CORRETOR__c && (
                                    <div className="mb-[20px]">
                                        <p className="font-bold sm:text-[2vh]">
                                            Corretora
                                        </p>
                                        <p className="sm:text-[2vh] ">
                                            {item.NOME_CORRETOR__c}
                                        </p>
                                    </div>
                                )}
                                <div className="">
                                    <p className="font-bold sm:text-[2vh]">
                                        Agendamento:
                                    </p>
                                    <p className="sm:text-[2vh]">
                                        {item.DATA_AGENDAMENTO__c
                                            ? new Intl.DateTimeFormat('pt-BR', {
                                                  timeZone: 'UTC',
                                              }).format(
                                                  new Date(
                                                      item.DATA_AGENDAMENTO__c
                                                          ?._seconds * 1000
                                                  )
                                              )
                                            : 'Sem agendamento'}
                                    </p>
                                </div>
                                <hr className="border-t-2 border-gray-300 border-opacity-50 mt-[10px] mb-[20px]" />
                                <div className="relative flex gap-[20px] flex-col justify-start">
                                    <button
                                        className={`border-[1px] border-[#000000] flex items-center justify-between w-full h-[40px] bg-[#FFFFFF] rounded-3xl text-[0.875rem] cursor-pointer px-[30px]`}
                                        onClick={() => {
                                            toggleForChangeLeadStatusDropdown(
                                                index
                                            );
                                        }}
                                    >
                                        <p>Alterar Status</p>
                                        <span>
                                            <BiChevronDown className="text-[#000000] w-[24px] h-[24px]" />
                                        </span>
                                    </button>
                                    {openDropdownIndex === index && (
                                        <div
                                            className="w-full absolute left-[60x] top-9 shadow-lg text-start rounded-lg text-[0.875rem] bg-white mt-[1vh] p-[0.2rem] border-[#c3c3c3] border-[0.1rem]"
                                            role="menu"
                                            aria-orientation="vertical"
                                        >
                                            {statusList.map(
                                                (status, counter) => {
                                                    return (
                                                        <ul key={counter}>
                                                            <li
                                                                className="py-1 px-4 hover:bg-gray-100 cursor-pointer"
                                                                onClick={() => {
                                                                    handleOpeningModalStatus(
                                                                        item,
                                                                        status
                                                                    );
                                                                    setOpenDropdownIndex(
                                                                        -1
                                                                    );
                                                                }}
                                                                onKeyDown={(
                                                                    event
                                                                ) => {
                                                                    if (
                                                                        event.key ===
                                                                            'Enter' ||
                                                                        event.key ===
                                                                            ' '
                                                                    ) {
                                                                        // console.log(
                                                                        //     ''
                                                                        // );
                                                                    }
                                                                }}
                                                                role="menuitem"
                                                                tabIndex={0}
                                                            >
                                                                {status.name}
                                                            </li>
                                                        </ul>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                    <button
                                        className={`justify-center mt-[0.5rem] border-[1px] ${
                                            item.SOLICITOU_CONTATO__c === 'true'
                                                ? 'border-[#FF5000]'
                                                : column.border
                                        } text-[#FFFFFF] flex items-center w-full h-[40px] ${
                                            item.SOLICITOU_CONTATO__c === 'true'
                                                ? 'bg-[#FF5000]'
                                                : column.bg
                                        } rounded-3xl text-[0.875rem] cursor-pointer`}
                                        onClick={() => {
                                            handleShowCelular(item.ID_LEAD__c);

                                            const obj = {
                                                ...item,
                                                cor_coluna: column.color,
                                                nome_coluna: column.name,
                                            };
                                            localStorage.setItem(
                                                'data-for-lead-details',
                                                JSON.stringify(obj)
                                            );

                                            navigate(
                                                '/area-logada/leads/details'
                                            );
                                        }}
                                    >
                                        <span className="mr-1">
                                            <AiOutlineEye />
                                        </span>
                                        Detalhes
                                    </button>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

function FireIcon() {
    return (
        <svg
            width="22"
            height="30"
            viewBox="0 0 22 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.142658 21.8707C1.00102 27.0952 5.66894 29.4671 7.8956 30C6.53426 28.7302 4.16461 25.0952 5.57675 20.7143C6.9889 16.3333 10.0416 13.6961 11.3915 12.9252C7.48038 18.8095 10.0416 23.7755 12.5336 23.9456C14.5272 24.0816 14.8641 22.3923 14.7834 21.5306C18.4937 25.2041 16.4678 28.4807 14.991 29.6599C24.7653 25.034 22.2248 16.576 19.7328 12.9252C20.1135 15.1701 18.1407 16.8707 16.2024 15.7823C14.2642 14.6939 14.991 12.9932 15.3025 11.9728C15.614 10.9524 16.3409 8.33333 14.991 5.13605C13.9112 2.57823 11.2415 0.646259 10.0416 0C10.2839 3.43537 8.69166 5.7483 6.51115 8.33333C4.33063 10.9184 -0.930294 15.3401 0.142658 21.8707Z"
                fill="#FA6B25"
            />
        </svg>
    );
}
