import { InformationCircleIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import anos from '../data/anosValidos';
import { corretoras } from '../data/corretoras';
import dias_options from '../data/diasValidos';
import { meses } from '../data/meses';
import { ufs } from '../data/ufs';
import { api } from '../services/api';
import { isCpfValid } from '../utils/checkCpf';

function FormCadastro() {
    const {
        register,
        handleSubmit,
        watch,
        setError: setFieldError,
        formState: { errors },
    } = useForm();
    const history = useNavigate();

    const [width, setWidth] = useState<number>(window.innerWidth);

    const [error, setError] = useState(false);
    const [signup, setSignup] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [dateOfBirth, setDateOfBirth] = useState();

    const success = () =>
        toast.success(
            'Cadastro efetuado, foi enviado um e-mail de confirmação. Confirme e volte aqui para acessar o sistema'
        );

    const senha = useRef({});

    senha.current = watch('senha', '');

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const onSubmit = async (data: any) => {
        const {
            nome,
            email,
            cpf,
            corretora,
            telefone,
            uf,
            cidade,
            senha,
            aceite,
            diaNascimento,
            mesNascimento,
            anoNascimento,
        } = data;

        const dataDeNascimento = new Date(
            `${anoNascimento}-${mesNascimento}-${diaNascimento}`
        ).toISOString();

        setError(false);

        setIsLoading(true);

        const checkCpf = isCpfValid(cpf);

        if (!checkCpf) {
            setFieldError('cpf', {
                type: 'manual',
                message: 'CPF incorreto/inválido',
            });

            setIsLoading(false);
            return;
        }

        try {
            await api.post('/v1/auth/create-account', {
                name: nome,
                email: email.toLowerCase(),
                cpf,
                broker: corretora,
                phone_number: telefone,
                state_abbreviation: uf,
                city: cidade,
                password: senha,
                has_accepted_terms: aceite,
                date_of_birth: dataDeNascimento,
            });

            setSignup(true);
            setIsLoading(false);
        } catch (error: any) {
            if (
                error.response.status === 403 ||
                error.response.status === 409
            ) {
                setError(true);

                window.scrollTo(0, document.body.scrollHeight);

                setIsLoading(false);
                return;
            }

            const notifyError = () =>
                toast.error(
                    `Erro ${error.code}. Por favor, tente novamente em instantes, ou entre em contato.`
                );

            setIsLoading(false);
            notifyError();
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const strongPasswordRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
    );

    return (
        <div className="pb-10">
            <div className="w-full px-5 md:px-48 py-10 bg-dark-blue text-white">
                <div className="">
                    <h3 className="title title-campanhas font-bold">
                        Cadastro
                    </h3>
                    <p className="text-base pr-36 mt-2 md:mt-0">
                        Para participar da campanha, faça seu cadastro.
                    </p>
                    {isMobile ? (
                        <p className="text-sm pr-36 mt-2 md:mt-0">
                            Todos os campos são obrigatórios <br /> o
                            preenchimento.
                        </p>
                    ) : (
                        <p className="text-sm pr-36 mt-2 md:mt-0">
                            Todos os campos são obrigatórios o preenchimento.
                        </p>
                    )}
                </div>
            </div>
            <div className="w-full flex justify-center pt-10 px-5">
                {signup ? (
                    <div className="max-w-[30rem] flex flex-col">
                        {' '}
                        <button
                            type="button"
                            onClick={() => history('/')}
                            className="py-2 mr-2 mb-7 px-10 text-dark-blue font-bold border border-dark-blue radius-button w-[10rem]"
                        >
                            Voltar
                        </button>
                        <div className="h-[60vh] flex flex-col items-center  w-full">
                            <img
                                src="/images/check.svg"
                                alt=""
                                className="h-36"
                            />

                            <p>Seu cadastro foi concluído!</p>
                            <p className="text-center">
                                Você finalizou o seu cadastro. Agora verifique
                                seu e-mail de confirmação na caixa de entrada ou
                                spam.
                            </p>
                        </div>
                    </div>
                ) : (
                    <form
                        style={{ width: '35rem' }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <button
                            type="button"
                            onClick={() => history('/')}
                            className="py-2 mr-2 mb-7 px-10 text-dark-blue font-bold border border-dark-blue radius-button"
                        >
                            Voltar
                        </button>

                        <div className="mb-8">
                            <label className="text-normal mb-2" htmlFor="nome">
                                Nome
                            </label>
                            <input
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="nome"
                                type="text"
                                placeholder="Nome"
                                {...register('nome', {
                                    required: true,
                                    minLength: {
                                        value: 5,
                                        message: 'Favor preencher',
                                    },
                                })}
                            />
                            {errors.nome && (
                                <span className="text-red-600">
                                    Favor preencher
                                </span>
                            )}
                        </div>
                        <div className="mb-8 flex justify-between items-end gap-4">
                            <div className="flex-1 min-w-[30%]">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="diaNascimento"
                                >
                                    Data de nascimento
                                </label>
                                <select
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="diaNascimento"
                                    placeholder="diaNascimento"
                                    {...register('diaNascimento', {
                                        required: true,
                                    })}
                                >
                                    <option value="">Dia</option>
                                    {dias_options.map((dia) => (
                                        <option key={dia} value={dia}>
                                            {dia}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex-1 md:flex-none min-w-[30%]">
                                <label
                                    className="text-normal mb-2 text-transparent"
                                    htmlFor="mesNascimento"
                                >
                                    Mês
                                </label>
                                <select
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="mesNascimento"
                                    placeholder="mesNascimento"
                                    {...register('mesNascimento', {
                                        required: true,
                                    })}
                                >
                                    <option value="">Mês</option>
                                    {meses.map((mes) => (
                                        <option
                                            key={mes.value}
                                            value={mes.value + 1}
                                        >
                                            {mes.mounth}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex-1 md:flex-none min-w-[30%]">
                                <label
                                    className="text-normal mb-2 text-transparent"
                                    htmlFor="anoNascimento"
                                >
                                    Ano
                                </label>
                                <select
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="anoNascimento"
                                    placeholder="Ano"
                                    {...register('anoNascimento', {
                                        required: true,
                                    })}
                                >
                                    <option value="">Ano</option>
                                    {anos.map((ano) => (
                                        <option key={ano} value={ano}>
                                            {ano}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {(errors.anoNascimento ||
                            errors.diaNascimento ||
                            errors.mesNascimento) && (
                            <div className="text-red-600 relative bottom-4">
                                Por favor preencha o campo data de nascimento
                            </div>
                        )}
                        <div className="mb-8">
                            <label className="text-normal mb-2" htmlFor="email">
                                E-mail
                            </label>
                            <input
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                                type="email"
                                placeholder="E-mail"
                                {...register('email', { required: true })}
                            />
                            {errors.email && (
                                <span className="text-red-600">
                                    E-mail incorreto
                                </span>
                            )}
                        </div>
                        <div className="mb-8">
                            <label
                                className="text-normal mb-2"
                                htmlFor="corretora"
                            >
                                Selecione sua corretora
                            </label>
                            <select
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="corretora"
                                defaultValue=""
                                placeholder="Nome da corretora"
                                {...register('corretora', { required: true })}
                            >
                                <option value="" disabled>
                                    Nome da corretora
                                </option>
                                {corretoras.sort().map((corretora, index) => (
                                    <option key={index} value={corretora}>
                                        {corretora}
                                    </option>
                                ))}
                            </select>
                            {errors.corretora && (
                                <span className="text-red-600">
                                    Obrigatório
                                </span>
                            )}
                        </div>
                        <div className="mb-8 md:flex justify-between">
                            <div className="flex-1 md:mr-3">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="telefone"
                                >
                                    Telefone
                                </label>
                                <InputMask
                                    mask={'(99) 999999999'}
                                    alwaysShowMask={false}
                                    maskPlaceholder=""
                                    placeholder="Telefone"
                                    type="text"
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    {...register('telefone', {
                                        required: true,
                                        minLength: {
                                            value: 13,
                                            message: 'Telefone incorreto',
                                        },
                                    })}
                                />
                                {errors.telefone && (
                                    <span className="text-red-600">
                                        Telefone incorreto
                                    </span>
                                )}
                            </div>
                            <div className="flex-1 md:ml-3 mt-5 md:mt-0">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="cpf"
                                >
                                    CPF
                                </label>
                                <InputMask
                                    mask={'999.999.999-99'}
                                    alwaysShowMask={false}
                                    maskPlaceholder=""
                                    placeholder="CPF"
                                    type="text"
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    {...register('cpf', {
                                        required: true,
                                        minLength: {
                                            value: 14,
                                            message: 'CPF incorreto/inválido',
                                        },
                                    })}
                                />
                                {errors.cpf && (
                                    <span className="text-red-600">
                                        CPF incorreto/inválido
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="mb-8 flex justify-between">
                            <div className="flex-1 mr-3">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="cidade"
                                >
                                    Cidade
                                </label>
                                <input
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cidade"
                                    type="text"
                                    placeholder="Cidade"
                                    {...register('cidade', { required: true })}
                                />
                                {errors.cidade && (
                                    <span className="text-red-600">
                                        Cidade incorreta
                                    </span>
                                )}
                            </div>
                            <div className="flex-1 md:flex-none ml-3">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="uf"
                                >
                                    UF
                                </label>
                                <select
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="uf"
                                    placeholder="UF"
                                    {...register('uf', { required: true })}
                                >
                                    <option value="">Selecione...</option>
                                    {ufs.map((uf) => (
                                        <option key={uf} value={uf}>
                                            {uf}
                                        </option>
                                    ))}
                                </select>

                                {errors.uf && (
                                    <span className="text-red-600">
                                        UF incorreto
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="mb-8 flex justify-between gap-6 flex-wrap sm:flex-nowrap">
                            <div className="w-[100%]">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="senha"
                                >
                                    Crie sua senha
                                </label>
                                <input
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="senha"
                                    type="password"
                                    placeholder="Senha"
                                    {...register('senha', {
                                        required: true,
                                        pattern: strongPasswordRegex,
                                        minLength: {
                                            value: 6,
                                            message:
                                                'Mínimo seis caracteres (uma letra maiúscula e um carácter especial)',
                                        },
                                    })}
                                />
                                {errors.senha && (
                                    <p className="text-red-600">
                                        Mínimo seis caracteres (uma letra
                                        maiúscula e um carácter especial)
                                    </p>
                                )}
                            </div>
                            <div className="w-[100%]">
                                <label
                                    className="text-normal mb-2"
                                    htmlFor="confirmar_senha"
                                >
                                    Confirmar senha
                                </label>
                                <input
                                    className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="confirmar_senha"
                                    type="password"
                                    placeholder="Confirmar senha"
                                    {...register('confirmar_senha', {
                                        required: true,
                                        validate: (value) =>
                                            value === senha.current,
                                    })}
                                />
                                {(errors.confirmar_senha || errors.senha) && (
                                    <p className="text-red-600">
                                        Por favor preencha o campo de
                                        confirmação de senha corretamente
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="w-full md:flex justify-between items-center">
                            <div>
                                <input
                                    className="form-check-input h-4 w-4 border border-dark-blue rounded-sm bg-white checked:bg-dark-blue checked:border-dark-blue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox"
                                    id="aceite"
                                    {...register('aceite', { required: true })}
                                />

                                <label
                                    className="form-check-label inline-block text-gray-800"
                                    htmlFor="aceite"
                                >
                                    Li e aceito os{' '}
                                    <a
                                        href="docs/regulamentoGranaExtra2.pdf"
                                        target="_blank"
                                    >
                                        <span className="border-b border-black font-bold">
                                            termos do regulamento
                                        </span>
                                    </a>
                                </label>
                                {errors.aceite && (
                                    <p className="text-red-600">Obrigatório</p>
                                )}
                            </div>

                            <button
                                type="submit"
                                className="w-full justify-center md:w-auto mt-5 md:mt-0 py-3 px-12 text-lg text-white font-bold bg-primary radius-button flex"
                            >
                                {isLoading ? (
                                    <ClipLoader size={20} />
                                ) : (
                                    'Cadastrar'
                                )}
                            </button>
                        </div>
                        {error && (
                            <div className="p-4 rounded-[4px] bg-[#D3DEED] flex items-center justify-center mt-4 text-black gap-4">
                                <InformationCircleIcon className="w-[10rem] sm:w-20 h-20" />

                                <p className="text-[#323232]">
                                    Já existe uma conta cadastrada com esses
                                    dados, deseja{' '}
                                    <Link
                                        to="/login"
                                        className="underline font-bold text-dark-blue"
                                    >
                                        fazer login?
                                    </Link>{' '}
                                    Caso não tenha recebido um e-mail de
                                    confirmação da conta você pode clicar no
                                    link{' '}
                                    <span className="">reenviar e-mail</span>.
                                    Verifique seu e-mail de confirmação na caixa
                                    de entrada ou spam.
                                </p>
                            </div>
                        )}
                    </form>
                )}
            </div>
        </div>
    );
}

export default FormCadastro;
