import React, {
    createContext,
    Dispatch,
    ReactNode,
    useContext,
    useState,
} from 'react';

type HeaderProps = {
    elementSize: string;
    setElementeSize: Dispatch<React.SetStateAction<string>>;
};

type HeaderContextProviderProps = {
    children: ReactNode;
};

const initialValues = {
    elementSize: '',
    setElementeSize: () => {
        ('');
    },
};

const HeaderContext = createContext<HeaderProps>(initialValues);

export const HeaderContextProvider = ({
    children,
}: HeaderContextProviderProps) => {
    const [elementSize, setElementeSize] = useState(initialValues.elementSize);

    return (
        <HeaderContext.Provider
            value={{
                elementSize,
                setElementeSize,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};

export const useHeaderContext = () => {
    const context = useContext(HeaderContext);

    return context;
};
