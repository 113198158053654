import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { createColumnHelper } from '@tanstack/react-table';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { DebouncedInput, ReactTableV8 } from '../components/ReactTableV8';
import useIsMobile from '../hooks/useIsMobile';
import { api } from '../services/api';

interface ProposalDto {
    type: string;
    status: string;
    salesperson_id: string;
    companies: [
        {
            cnpj: string;
        }
    ];
    broker: string;
    created_at: string;
    credit?: number;
    credit_date?: string;
    id: string;
    plan: {
        companies: [
            {
                cnpj: string;
            }
        ];
        type: string;
    };
    products: {
        global_capital: {
            proposal_number: number;
        };
        dental: {
            proposal_number: number;
        };
        health: {
            proposal_number: number;
        };
        health_protect: {
            proposal_number: number;
        };
    };

    has_supervisor: boolean;
    supervisor: {
        cpf: string;
        full_name: string;
    };
    updated_at: string;
}

type Proposal = {
    saude: string;
    odonto: string;
    capital: string;
    protecao: string;
    situacao: string;
    credito: number;
    dataCredito: Date;
};

function Propostas() {
    const history = useNavigate();
    const [windowSize] = useIsMobile();
    const isMobile = +windowSize <= 768;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Proposal[]>([]);
    const [tableData, setTableData] = useState<Proposal[]>([]);

    const [globalFilter, setGlobalFilter] = useState('');

    const [isLinked, setIsLinked] = useState(false);

    const [filters, setFilters] = useState<any>([]);
    const [situation, setSituation] = useState('');
    const [offer, setOffer] = useState('');

    const [ascendingOrder, setAscendingOrder] = useState(true);
    // const [propriedadeDropdownOpen, setPropriedadeDropdownOpen] =
    //     useState(false);
    // const [propriedade, setPropriedade] = useState('situacao');

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    // const propriedadeDropdown = [
    //     { value: 'situacao', name: 'Situação' },
    //     { value: 'dataCredito', name: 'Data de Crédito' },
    //     { value: 'credito', name: 'Crédito' },
    // ];

    useEffect(() => {
        const decodedJwt: any = jwt_decode(user?.access_token);

        setIsLinked(decodedJwt.response.isLinked);
    }, []);

    const formatDate = (input: string) => {
        const datePart = input.match(/\d+/g);

        if (datePart) {
            const day = datePart[2];
            const month = datePart[1];
            const year = datePart[0];

            return day + '/' + month + '/' + year;
        }
    };

    const columnHelper = createColumnHelper<Proposal>();

    const capitalizeFirstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const columns = isMobile
        ? [
              columnHelper.display({
                  id: 'Propostas',
                  cell: (info) => {
                      return (
                          <div className="text-[#001E64] pt-4 pb-4">
                              <p className="leading-8">
                                  Proposta Saúde&nbsp;
                                  <b>{info.row.original.saude}</b>
                              </p>
                              <p className="leading-8">
                                  Proposta Odonto&nbsp;
                                  <b>{info.row.original.odonto}</b>
                              </p>
                              <p className="leading-8">
                                  Proposta Capital Global&nbsp;
                                  <b>{info.row.original.capital}</b>
                              </p>
                              <p className="leading-8">
                                  Proposta Proteção Saúde&nbsp;
                                  <b>{info.row.original.protecao}</b>
                              </p>
                              <p className="leading-8">
                                  Situação&nbsp;
                                  <b
                                      className={`${
                                          info.row.original.situacao ===
                                          'EMITIDO'
                                              ? 'text-[#006217]'
                                              : info.row.original.situacao ===
                                                'RECUSADO'
                                              ? 'text-[#AA2C23]'
                                              : 'text-[#001E64]'
                                      }`}
                                  >
                                      {capitalizeFirstLetter(
                                          info.row.original.situacao ===
                                              'EMITIDO'
                                              ? 'Remunerado'
                                              : info.row.original.situacao ===
                                                'RECUSADO'
                                              ? 'Não remunerado'
                                              : info.row.original.situacao
                                      )}
                                  </b>
                              </p>
                              <p className="leading-8">
                                  Crédito&nbsp;
                                  <b
                                      className={`${
                                          info.row.original.credito > 0
                                              ? 'text-[#006217]'
                                              : 'text-[#AA2C23]'
                                      }`}
                                  >
                                      {info.row.original.credito.toString() !==
                                          '-' && 'R$ '}

                                      {info.row.original.credito.toLocaleString(
                                          'pt-br',
                                          {
                                              style: 'currency',
                                              currency: 'BRL',
                                          }
                                      )}
                                  </b>
                              </p>
                              <p className="leading-8">
                                  Data de Crédito&nbsp;
                                  <span className="text-[#146CFA] font-bold">
                                      {info.row.original.dataCredito.toString() !==
                                      '-'
                                          ? formatDate(
                                                info.row.original.dataCredito.toString()
                                            )
                                          : info.row.original.dataCredito.toString()}
                                  </span>
                              </p>
                          </div>
                      );
                  },
                  footer: (info) => info.column.id,
                  enableGlobalFilter: true,
              }),
          ]
        : [
              columnHelper.accessor('saude', {
                  cell: (info) => info.getValue(),
                  header: () => <span>Proposta Saúde</span>,
                  footer: (info) => info.column.id,
                  enableGlobalFilter: true,
              }),
              columnHelper.accessor('odonto', {
                  cell: (info) => info.getValue(),
                  header: () => <span>Proposta Odonto</span>,
                  footer: (info) => info.column.id,
                  enableGlobalFilter: true,
              }),
              columnHelper.accessor('capital', {
                  cell: (info) => info.getValue(),
                  header: () => <span>Proposta Capital Global</span>,
                  footer: (info) => info.column.id,
                  enableGlobalFilter: true,
              }),
              columnHelper.accessor('protecao', {
                  cell: (info) => info.getValue(),
                  header: () => <span>Proposta Proteção Saúde</span>,
                  footer: (info) => info.column.id,
                  enableGlobalFilter: true,
              }),
              columnHelper.accessor('situacao', {
                  cell: (info) => (
                      <p
                          className={`${
                              info.getValue() === 'EMITIDO'
                                  ? 'text-[#006217]'
                                  : info.getValue() === 'RECUSADO'
                                  ? 'text-[#AA2C23]'
                                  : 'text-[#001E64] lowercase first-letter:uppercase'
                          }`}
                      >
                          {info.getValue() === 'EMITIDO'
                              ? 'Remunerado'
                              : info.getValue() === 'RECUSADO'
                              ? 'Não remunerado'
                              : info.getValue()}
                      </p>
                  ),
                  header: () => <span>Situação</span>,
                  footer: (info) => info.column.id,
              }),
              columnHelper.accessor('credito', {
                  cell: (info) => (
                      <p
                          className={`
                    font-bold
                    ${
                        info.getValue<number>() > 0
                            ? 'text-[#006217]'
                            : 'text-[#AA2C23]'
                    }`}
                      >
                          {info.getValue<number>() > 0 ? 'R$ ' : null}
                          {info.getValue<number>().toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                          })}
                      </p>
                  ),
                  header: () => <span>Crédito</span>,
                  footer: (info) => info.column.id,
              }),
              columnHelper.accessor('dataCredito', {
                  cell: (info) => (
                      <p className="text-[#146CFA] font-bold">
                          {info.getValue().toString() !== '-'
                              ? formatDate(info.getValue().toString())
                              : info.getValue().toString()}
                      </p>
                  ),
                  header: () => <span>Data de crédito</span>,
                  footer: (info) => info.column.id,
                  //   sortingFn: 'datetime',
              }),
          ];

    const handlePropostaFilter = async (filter: string) => {
        await setSituation('');
        await setOffer(filter);

        let filteredData;

        switch (filter) {
            case 'Saúde':
                // eslint-disable-next-line no-case-declarations
                filteredData = data.filter((item) => item.saude.length > 1);

                setTableData(filteredData);

                break;
            case 'Odonto':
                filteredData = data.filter((item) => item.odonto.length > 1);

                setTableData(filteredData);
                break;

            case 'Capital Global':
                filteredData = data.filter((item) => item.capital.length > 1);

                setTableData(filteredData);
                break;

            case 'Protecao Saude':
                filteredData = data.filter((item) => item.protecao.length > 1);
                setTableData(filteredData);
                break;
            default:
                setTableData(data);
                break;
        }
    };

    const handleSituacaoFilter = async (filter: string) => {
        await setOffer('');
        await setSituation(filter);

        const newTableData = data.filter((item) => item.situacao === filter);

        if (filter !== '') {
            setTableData(newTableData);
        } else {
            setTableData(data);
        }
    };

    const getData = async () => {
        try {
            setLoading(true);

            const { data } = await api.get(`/v1/vendedores/propostas`, {
                headers: { Authorization: user.access_token },
            });

            const tableData = data
                .filter((item: ProposalDto) => {
                    return (
                        item.products.health?.proposal_number ||
                        item.products.dental?.proposal_number ||
                        item.products.global_capital?.proposal_number ||
                        item.products.health_protect?.proposal_number
                    );
                })
                .sort((a: ProposalDto, b: ProposalDto) => {
                    return b.created_at.localeCompare(a.created_at);
                })
                .map((item: ProposalDto) => ({
                    saude: item.products.health?.proposal_number
                        ? item.products.health.proposal_number.toString()
                        : '-',
                    odonto: item.products.dental?.proposal_number
                        ? item.products.dental.proposal_number.toString()
                        : '-',
                    capital: item.products.global_capital?.proposal_number
                        ? item.products.global_capital.proposal_number.toString()
                        : '-',
                    protecao: item.products.health_protect?.proposal_number
                        ? item.products.health_protect.proposal_number.toString()
                        : '-',
                    situacao:
                        item.status.toString() === 'NOVO'
                            ? 'Em análise'
                            : item.status.toString(),
                    credito: item.credit
                        ? item.credit.toLocaleString('PT-BR')
                        : '-',
                    dataCredito: item.credit_date ? item.credit_date : '-',
                }));

            setData(tableData);

            setTableData(tableData);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // const handleOrdenarClick = () => {
    //     setPropriedadeDropdownOpen(!propriedadeDropdownOpen);
    // };

    const handlePropriedadeCrescente = () =>
        // prop: string
        {
            setAscendingOrder(!ascendingOrder);
            // setPropriedade(prop);
            // setPropriedadeDropdownOpen(false);
        };

    // const formatarValorMonetario = (valor: string) => {
    //     const valorNumerico = Number(
    //         valor.replace(/[^0-9,-]+/g, '').replace(',', '.')
    //     );
    //     return isNaN(valorNumerico) ? 0 : valorNumerico;
    // };

    const dadosOrdenados = [...tableData].reverse();
    // sort((a: any, b: any) => {
    // if (propriedade === 'situacao') {
    // if (ascendingOrder) {
    //     return a[propriedade].localeCompare(b[propriedade]); // Ordenação crescente
    // } else {
    //     return b[propriedade].localeCompare(a[propriedade]); // Ordenação decrescente
    // }
    // }
    // if (propriedade === 'dataCredito') {
    //     const dataA = a[propriedade];
    //     const dataB = b[propriedade];
    //     if (ascendingOrder) {
    //         return dataA.localeCompare(dataB); // Ordenação crescente
    //     } else {
    //         return dataB.localeCompare(dataA); // Ordenação decrescente
    //     }
    // }
    // if (propriedade === 'credito') {
    //     const valorA = formatarValorMonetario(a[propriedade]);
    //     const valorB = formatarValorMonetario(b[propriedade]);
    //     if (ascendingOrder) {
    //         return valorA - valorB; // Ordenação crescente
    //     } else {
    //         return valorB - valorA; // Ordenação decrescente
    //     }
    // }
    // });

    const handleDadosOrdenados = () => {
        setTableData(dadosOrdenados);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const filteredDataTable = data.filter((item) => {
            return (
                item.capital.includes(globalFilter) ||
                item.odonto.includes(globalFilter) ||
                item.saude.includes(globalFilter) ||
                item.protecao.includes(globalFilter)
            );
        });

        setTableData(filteredDataTable);
    }, [globalFilter]);

    useEffect(() => {
        handleDadosOrdenados();
    }, [ascendingOrder]);

    return (
        <>
            {isLinked ? (
                <div className="w-full flex flex-col items-center justify-center mt-[20px]">
                    <div className="mb:max-w-[343px] lg:max-w-[1125px] w-full flex mb:flex-col lg:flex-row mb:gap-[20px] lg:gap-[0px] justify-between items-center  bg-[#D4DCEB] mb:p-[20px] px-[1rem] py-[1.5rem] rounded-[12px]">
                        <p className="text-[1rem]">
                            <span className="font-bold">
                                Gerencie o número de leads, conversão de venda e
                                acompanhe seus resultados em tempo real.
                            </span>
                            &nbsp; <br className="hidden md:block" /> Acesse o
                            painel <br className="block md:hidden" />
                            de gestão leads &nbsp;
                            <span className="font-bold">
                                clicando no botão
                                <br className="block md:hidden" /> (Painel de
                                gestão de leads).
                            </span>
                        </p>
                        <Link
                            to={{ pathname: '/area-logada/leads' }}
                            className="mb:w-full lg:w-[273px] h-[40px] text-center bg-[#001E64] text-[white] px-[2rem] py-[9px] rounded-full text-[1rem] font-bold"
                        >
                            Painel de gestão de leads
                        </Link>
                    </div>
                </div>
            ) : null}

            <div className="py-[30px] px-[16px] md:p-10">
                <div className="w-full flex justify-between items-center md:flex-row flex-col gap-4">
                    <h4 className="text-[1.5rem] text-start lg:text-[2rem] title font-bold title-campanhas w-full">
                        Minhas propostas
                    </h4>
                </div>
                <p className="text-black-2 mb:mt-[30px] mt-8  mb-5">
                    Confira a situação das suas propostas &nbsp;
                    <br className="mb:block lg:hidden" />e créditos disponíveis
                    <br className="mb:hidden lg:block" />
                    na campanha
                    <br className="mb:block lg:hidden" />
                    &nbsp;
                    <span className="font-bold text-[#244186]">
                        Saúde e Odonto
                    </span>
                </p>
                <p className="text-[#244186]">
                    Propostas não remuneradas na campanha seguem as regras
                    dispostas no regulamento.
                </p>
                <div className="min-w-full w-[100%] sm:w-[20rem] bg-white rounded-2xl mt-8 p-[20px] md:p-8 shadow-xl flex flex-col">
                    <div className="md:flex md:justify-between flex-wrap">
                        <div className="w-full lg:flex justify-">
                            <div className="w-full">
                                <h6 className="font-bold text-black-2 text-xl">
                                    Acompanhamento de propostas
                                </h6>
                                <p className="text-[#244186]">
                                    Quantidade de propostas:&nbsp;
                                    <span className="font-bold">
                                        {data.length}
                                    </span>
                                </p>
                            </div>
                            <div className="w-full lg:flex lg:flex-row-reverse gap-[20px] items-center">
                                <div className="md:flex gap-5 flex-wrap flex-col lg::flex-row-reverse items-center">
                                    <div className="relative text-gray-600 focus-within:text-gray-400 mb:mt-[30px] lg:mt-0">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                className="w-6 h-6"
                                            >
                                                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                            </svg>
                                        </span>
                                        <DebouncedInput
                                            value={filters.saude ?? ''}
                                            className="border-[#001E64] border-[1px] h-[46px] w-full lg::w-[18.375rem] py-2 text-white rounded-3xl pl-12 focus:outline-none focus:bg-white focus:text-gray-900"
                                            placeholder="Digite sua proposta"
                                            autoComplete="off"
                                            onChange={(value) =>
                                                setGlobalFilter(String(value))
                                            }
                                        />
                                    </div>
                                </div>

                                <select
                                    className="rounded-3xl border-[#001E64] border-[1px] h-[48px] mb:mt-[20px] lg:mt-0 w-[100%] sm:w-[9.375rem] pl-4 pr-4"
                                    id="proposta"
                                    defaultValue=""
                                    value={offer}
                                    onChange={(e) =>
                                        handlePropostaFilter(e.target.value)
                                    }
                                >
                                    <option className="" value="">
                                        Proposta
                                    </option>
                                    <option value="Saúde">Saúde</option>
                                    <option value="Odonto">Odonto</option>
                                    <option value="Capital Global">
                                        Capital Global
                                    </option>
                                    <option value="Protecao Saude">
                                        Proteção Saúde
                                    </option>
                                </select>
                                <select
                                    className="rounded-3xl border-[#001E64] border-[1px] mb:mt-[20px] lg:mt-0 h-[48px] w-[100%] sm:w-[9.375rem] pl-4 pr-4"
                                    id="situacao"
                                    defaultValue=""
                                    value={situation}
                                    onChange={(e) => {
                                        handleSituacaoFilter(e.target.value);
                                    }}
                                >
                                    <option value="">Situação</option>
                                    <option value="Em análise">
                                        Em análise
                                    </option>
                                    <option value="Pendente">Pendente</option>
                                    <option value="EMITIDO">Remunerado</option>
                                    <option value="RECUSADO">
                                        Não remunerado
                                    </option>
                                </select>
                            </div>
                            <div className="relative">
                                <button
                                    onClick={() => handlePropriedadeCrescente()}
                                    className="flex md:hidden justify-center items-center gap-[10px] rounded-3xl border-[#001E64] border-[1px] h-[48px] mb:mt-[20px] lg:mt-0 w-[100%] sm:w-[9.375rem] pl-4 pr-4"
                                >
                                    <div className="flex">
                                        <ArrowUpIcon
                                            className="h-4"
                                            color={`${
                                                !ascendingOrder
                                                    ? '#001E64'
                                                    : '#9CA3AF'
                                            }`}
                                        />
                                        <ArrowDownIcon
                                            className="h-4 mt-1"
                                            color={`${
                                                ascendingOrder
                                                    ? '#001E64'
                                                    : '#9CA3AF'
                                            }`}
                                        />
                                    </div>
                                    <p>Ordenar</p>
                                </button>
                                {/* {propriedadeDropdownOpen && (
                                    <div
                                        className="w-full absolute left-[60x] top-11 shadow-lg text-start rounded-lg text-[0.875rem] bg-white mt-[1vh] p-[0.2rem] border-[#c3c3c3] border-[0.1rem]"
                                        role="menu"
                                        aria-orientation="vertical"
                                    > */}
                                {/* {propriedadeDropdown.map(
                                            (prop, index) => {
                                                return (
                                                    <ul key={index}>
                                                        <li
                                                            className="py-1 px-4 hover:bg-gray-100 cursor-pointer"
                                                            onClick={() =>
                                                                handlePropriedadeCrescente(
                                                                    prop.value
                                                                )
                                                            }
                                                            onKeyDown={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    event.key ===
                                                                        'Enter' ||
                                                                    event.key ===
                                                                        ' '
                                                                ) {
                                                                    handlePropriedadeCrescente(
                                                                        prop.value
                                                                    );
                                                                }
                                                            }}
                                                            role="menuitem"
                                                        >
                                                            {prop.name}
                                                        </li>
                                                    </ul>
                                                );
                                            }
                                        )} */}
                                {/* </div>
                                )} */}
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="w-full flex justify-center">
                            <ClipLoader />
                        </div>
                    ) : tableData.length > 0 ? (
                        <ReactTableV8
                            columns={columns}
                            data={tableData}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    ) : (
                        <div className="w-full h-96 flex flex-col justify-center items-center text-gray-500 gap-6">
                            <img
                                src="/images/icons/sad.svg"
                                alt="Sem propostas"
                            />

                            <p className="text-center">
                                Parece que você não tem nenhuma atividade
                                registrada
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Propostas;
