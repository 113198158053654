import { Dispatch, SetStateAction } from 'react';

type NotificationPaginationProps = {
    itemsPerPage: number;
    totalItems: number;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
};

export const NotificationPagination = ({
    currentPage,
    setCurrentPage,
    itemsPerPage,
    totalItems,
}: NotificationPaginationProps) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleClick = (value: 'previous' | 'next') => {
        if (value === 'previous' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        if (value === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPaginationButtons = () => {
        const buttons = [];
        const maxButtonsToShow = 3;

        if (totalPages <= maxButtonsToShow) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={
                            i === currentPage
                                ? 'bg-[#FF5000] text-white font-bold rounded-md px-3 py-1'
                                : ''
                        }
                    >
                        {i}
                    </button>
                );
            }
        } else {
            const middleButton = Math.floor(maxButtonsToShow / 2);
            let startPage = currentPage - middleButton;
            let endPage = currentPage + middleButton;

            if (startPage < 1) {
                startPage = 1;
                endPage = maxButtonsToShow;
            }

            if (endPage > totalPages) {
                endPage = totalPages;
                startPage = totalPages - maxButtonsToShow + 1;
            }

            for (let i = startPage; i <= endPage; i++) {
                buttons.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={
                            i === currentPage
                                ? 'bg-[#FF5000] text-white font-bold rounded-md px-3 py-1'
                                : ''
                        }
                    >
                        {i}
                    </button>
                );
            }

            if (startPage > 1) {
                buttons.unshift(<span key="ellipsis-start">...</span>);
            }

            if (endPage < totalPages) {
                buttons.push(<span key="ellipsis-end">...</span>);
            }
        }

        return buttons;
    };

    return (
        <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-8 text-[#5A5A5A]">
            <p>
                Exibindo de {(currentPage - 1) * itemsPerPage + 1} até &nbsp;
                {Math.min(currentPage * itemsPerPage, totalItems)} de{' '}
                {totalItems}
            </p>
            <div className="flex gap-2 md:gap-7">
                <button
                    style={{
                        color: currentPage === 1 ? '#D2D2D2' : '#323232',
                    }}
                    onClick={() => handleClick('previous')}
                    disabled={currentPage === 1}
                >
                    {'<'}
                </button>
                <div className="flex gap-7">{renderPaginationButtons()}</div>
                <button
                    style={{
                        color:
                            currentPage === totalPages ? '#D2D2D2' : '#323232',
                    }}
                    onClick={() => handleClick('next')}
                    disabled={currentPage === totalPages}
                >
                    {'>'}
                </button>
            </div>
        </div>
    );
};
