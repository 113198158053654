function MenuSocial() {
    return (
        <div className="flex justify-end">
            <a
                href="https://pt-br.facebook.com/SulAmerica/"
                target="_blank"
                rel="noreferrer"
            >
                <img alt="" src="/images/icons/facebook.svg" />
            </a>
            <a
                href="https://twitter.com/Sulamerica"
                target="_blank"
                rel="noreferrer"
            >
                <img alt="" src="/images/icons/twitter.svg" />
            </a>
            <a
                href="https://www.youtube.com/channel/UCoBwkOEyX6JqKzHRbpoaC0g"
                target="_blank"
                rel="noreferrer"
            >
                <img alt="" src="/images/icons/youtube.svg" />
            </a>
            <a
                href="https://www.linkedin.com/company/sulamerica/"
                target="_blank"
                rel="noreferrer"
            >
                <img alt="" src="/images/icons/linkedin.svg" />
            </a>
        </div>
    );
}

export default MenuSocial;
