import * as DialogRadix from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Dispatch, ReactNode, SetStateAction } from 'react';

type DialogProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    title?: string;
    description?: string;
    children: ReactNode;
    isClosable?: boolean;
    width?: number;
};

const Dialog = ({
    isOpen,
    setIsOpen,
    title,
    description,
    children,
    isClosable = true,
    width,
}: DialogProps) => {
    return (
        <DialogRadix.Root open={isOpen} onOpenChange={setIsOpen}>
            <DialogRadix.Portal>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[1rem] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                    <DialogRadix.Title className="text-mauve12 text-center m-0 text-[17px] font-medium">
                        {title}
                    </DialogRadix.Title>
                    <DialogRadix.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                        {description}
                    </DialogRadix.Description>
                    {children}
                    {isClosable ? (
                        <DialogRadix.Close asChild>
                            <button
                                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                                aria-label="Close"
                            >
                                <Cross2Icon />
                            </button>
                        </DialogRadix.Close>
                    ) : null}
                </DialogRadix.Content>
            </DialogRadix.Portal>
        </DialogRadix.Root>
    );
};

export default Dialog;
