import { useEffect, useState } from 'react';

import Banner from '../components/Banner';
import Campanhas from '../components/Campanhas';
import Contato from '../components/Contato';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

function Home() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <>
            <NavBar />
            <div id="inicio">
                {/* <BannerPraSuperVendedores /> */}
                {/* <TemporaryBanner /> */}
                <Banner />
                {/* <BannerBoraBill /> */}
                <Campanhas />
                <Contato />
                <img
                    alt=""
                    style={{
                        position: 'absolute',
                        zIndex: -1,
                        width: '100%',
                        marginTop: '-5rem',
                    }}
                    src="/images/linha2.svg"
                />
            </div>
            <Footer />
        </>
    );
}

export default Home;
