export const NaoAutorizado = () => {
    return (
        <div className="w-full h-screen flex justify-center items-center gap-10 flex-col">
            <div>
                <span className="font-bold text-[5rem]">401</span>
                <span>Não autorizado</span>
            </div>

            <a href="/" className="font-bold">
                Clique aqui para retornar a home
            </a>
        </div>
    );
};
