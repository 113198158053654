import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import deleteIcon from '../assets/svgs/delete-icon.svg';
import messageIcon from '../assets/svgs/message-icon.svg';
import { CustomIcon } from '../components/CustomIcon';
import { NotificationLayout } from '../components/NotificationLayout';
import { NotificationTable } from '../components/NotificationTable';
import { useNotificationContext } from '../contexts/NotificationContext';
import { api } from '../services/api';

type NotificationType = {
    assunto: string;
    conteudo: { segmento: string | null };
    created_at: string;
    id: string;
    id_notificacao: string;
    id_usuario: string;
    lida: boolean;
    updated_at: string;
};

export const Notificacoes = () => {
    const navigate = useNavigate();

    const {
        status,
        notificationsFiltered,
        setNotificationsFiltered,
        selectedItems,
        setSelectedItems,
        resetCheckboxes,
        currentPage,
        itemsPerPage,
        offset,
    } = useNotificationContext();

    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [totalNotifications, setTotalNotifications] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    const getNotifications = async (offset: number, limit: number) => {
        setIsLoading(true);
        const isFilterSet = status === 'Lida' || status === 'Não lida';

        try {
            const response = await api.get(
                `v1/notificacoes/notificacoes-tela?${
                    isFilterSet ? `&lida=${status === 'Lida'}` : ''
                }&_limit=${limit}&_offset=${offset}`,
                { headers: { Authorization: user.access_token } }
            );

            setNotifications(response.data._embedded._result);
            setNotificationsFiltered(response.data._embedded._result);
            setTotalNotifications(response.data._embedded._total);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const deleteMultipleNotifications = async (ids: unknown[]) => {
        setIsLoading(true);
        const joinedIDs = ids.join(',');

        try {
            await api.delete(
                `v1/notificacoes/notificacoes-tela?ids=${joinedIDs}`,
                { headers: { Authorization: user.access_token } }
            );

            const updatedNotifications = notifications.filter(
                (item) => !ids.includes(item.id)
            );

            setNotificationsFiltered(updatedNotifications);
            getNotifications(offset - 1, itemsPerPage);
            setSelectedItems(new Set());
            resetCheckboxes();
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const upDateMultipleNotifications = async (ids: unknown[]) => {
        const updateData = {
            ids: ids,
        };

        try {
            const response = await api.patch(
                'v1/notificacoes/notificacoes-tela',
                updateData,
                { headers: { Authorization: user.access_token } }
            );

            getNotifications(offset - 1, itemsPerPage);
            setSelectedItems(new Set());
            resetCheckboxes();
        } catch (error) {
            console.log(error);
        }
    };

    const buttonsMobile = [
        {
            icon: (
                <CustomIcon
                    src={messageIcon}
                    width={18}
                    height={16}
                    color="#146CFA"
                />
            ),
            label: 'Marcar todas como lida',
            border: '#146CFA',
            action: upDateMultipleNotifications,
            disable: notificationsFiltered.length === 0,
        },
        {
            icon: (
                <CustomIcon
                    src={deleteIcon}
                    width={21}
                    height={19}
                    color="#AA2C23"
                />
            ),
            label: 'Excluir mensagens',
            border: '#AA2C23',
            action: deleteMultipleNotifications,
            disable: selectedItems.size === 0,
        },
        {
            icon: <CustomIcon src={''} />,
            label: 'Voltar',
            border: '#001E64',
            action: () => navigate(-1),
            disable: false,
        },
    ];

    const headersIcons = [
        {
            icon: messageIcon,
            tolltip: 'Marcar como lida',
            action: upDateMultipleNotifications,
        },
        {
            icon: deleteIcon,
            tolltip: 'Deletar notificação',
            action: deleteMultipleNotifications,
        },
    ];

    useEffect(() => {
        getNotifications(offset - 1, itemsPerPage);
    }, [currentPage, itemsPerPage, status]);

    return (
        <NotificationLayout title="Todas as notificações">
            <NotificationTable
                isLoading={isLoading}
                data={notifications}
                totalData={totalNotifications}
                headersIcons={headersIcons}
                buttonsMobile={buttonsMobile}
            />
        </NotificationLayout>
    );
};
