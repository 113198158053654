import { useEffect, useState } from 'react';

import { NotificationLayout } from '../components/NotificationLayout';
import { NotificationToggle } from '../components/NotificationToggle';
import { api } from '../services/api';

type contentCardType = {
    id: number;
    user: string;
    checked: boolean;
    type: string;
    label: string;
};

export const ConfiguradorNotificacoes = () => {
    const [switchDataTela, setSwitchDataTela] = useState<contentCardType[]>();
    const [switchDataEmail, setSwitchDataEmail] = useState<contentCardType[]>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSwitch, setIsLoadingSwitch] = useState<number | null>(null);

    const [showToast, setShowToast] = useState<boolean>(false);

    const [email, setEmail] = useState('');

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    const fetchEmail = async () => {
        try {
            const response = await api.get(
                'v1/notificacoes/usuarios/contato-principal',
                { headers: { Authorization: user.access_token } }
            );

            const email = response?.data?.email?.valor;

            setEmail(email);
        } catch (e) {
            console.log(e);
        }
    };

    const getConfigurations = async (upgradingSwitch?: boolean) => {
        !upgradingSwitch && setIsLoading(true);

        try {
            const res = await api.get(
                'v1/notificacoes/usuarios/configuracoes-notificacoes',
                { headers: { Authorization: user.access_token } }
            );

            const items = [
                {
                    screen: [
                        {
                            id: 1,
                            user: res.data.id,
                            checked: res.data.leads_config.novo_lead.tela,
                            type: 'novo_lead',
                            label: 'Desejo ser notificado em tela sempre que um novo lead for cadastrado',
                        },
                        {
                            id: 2,
                            user: res.data.id,
                            checked: res.data.leads_config.nova_venda.tela,
                            type: 'nova_venda',
                            label: 'Desejo ser notificado em tela sempre que uma venda for realizada',
                        },
                        {
                            id: 3,
                            user: res.data.id,
                            checked: res.data.leads_config.agendamentos.tela,
                            type: 'agendamentos',
                            label: 'Desejo ser notificado em tela sobre meus agendamentos',
                        },
                    ],
                },
                {
                    email: [
                        {
                            id: 4,
                            user: res.data.id,
                            checked: res.data.leads_config.novo_lead.email,
                            type: 'novo_lead',
                            label: 'Desejo ser notificado por e-mail sempre que um novo lead for cadastrado',
                        },
                        {
                            id: 5,
                            user: res.data.id,
                            checked: res.data.leads_config.nova_venda.email,
                            type: 'nova_venda',
                            label: 'Desejo ser notificado por e-mail sempre que a venda for realizada',
                        },
                        {
                            id: 6,
                            user: res.data.id,
                            checked: res.data.leads_config.agendamentos.email,
                            type: 'agendamentos',
                            label: 'Desejo ser notificado por e-mail sobre meus agendamentos',
                        },
                    ],
                },
            ];

            items.forEach((item) => {
                if (item['screen']) {
                    setSwitchDataTela(item.screen ? item.screen : []);
                    setIsLoadingSwitch(null);
                    setIsLoading(false);
                }
                if (item['email']) {
                    setSwitchDataEmail(item.email ? item.email : []);
                    setIsLoadingSwitch(null);
                    setIsLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
            setIsLoadingSwitch(null);
            setIsLoading(false);
        }
    };

    const checkEmailConfirmation = async (email: string) => {
        try {
            const response = await api.get(
                'v1/notificacoes/usuarios/contato-principal',
                { headers: { Authorization: user.access_token } }
            );

            const inEmail = response.data.email.valor === email;
            const isEmailConfirmed = inEmail
                ? response.data.email.verificado
                : false;

            return isEmailConfirmed;
        } catch (error) {
            console.log(error);
        }
    };

    const upDateConfigurations = async (
        location: string,
        data: contentCardType[]
    ) => {
        try {
            const payload = {
                id: data[0].user,
                leads_config: {},
            };

            for (const key in data) {
                const config = data[key];
                const type = config.type;

                if (!payload.leads_config[type]) {
                    payload.leads_config[type] = {};
                }

                payload.leads_config[type].tela =
                    location === 'screen'
                        ? config.checked
                        : switchDataTela[key].checked;
                payload.leads_config[type].email =
                    location === 'email'
                        ? config.checked
                        : switchDataEmail[key].checked;
            }

            const res = await api.post(
                'v1/notificacoes/configuracoes-notificacoes',
                payload,
                { headers: { Authorization: user.access_token } }
            );

            getConfigurations(true);
        } catch (error) {
            console.log(error);
            setIsLoadingSwitch(null);
        }
    };

    const handleSwitchChange = async (
        isEmail: boolean,
        id: number,
        checked: boolean,
        data: contentCardType[]
    ) => {
        setIsLoadingSwitch(id);

        const updatedData = data.map((item) =>
            item.id === id ? { ...item, checked: checked } : item
        );

        const isEmailConfirmed = isEmail
            ? await checkEmailConfirmation(email)
            : false;

        if (!isEmail) {
            upDateConfigurations('screen', updatedData);
            return;
        } else if (isEmail && isEmailConfirmed) {
            upDateConfigurations('email', updatedData);
        } else {
            setShowToast(true);
            setIsLoadingSwitch(null);
        }
    };

    const handleShowToast = async (action: 'open' | 'close') => {
        if (action === 'open') {
            setShowToast(true);
        } else {
            setShowToast(false);
        }
    };

    useEffect(() => {
        fetchEmail();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    }, [showToast]);

    useEffect(() => {
        getConfigurations();
    }, []);

    return (
        <NotificationLayout title="Configurar notificação">
            <div className="flex flex-col gap-5">
                <NotificationToggle
                    data={switchDataTela}
                    isLoading={isLoading}
                    isLoadingSwitch={isLoadingSwitch}
                    onSwitchChange={(id, checked) =>
                        handleSwitchChange(false, id, checked, switchDataTela)
                    }
                    title={'Em tela'}
                />
                <NotificationToggle
                    data={switchDataEmail}
                    isLoading={isLoading}
                    isLoadingSwitch={isLoadingSwitch}
                    onSwitchChange={(id, checked) =>
                        handleSwitchChange(true, id, checked, switchDataEmail)
                    }
                    title={'Por e-mail'}
                    complement="(Notificações de novos leads, vendas realizadas, agendamento e importações)"
                    email={email}
                    setEmail={setEmail}
                    showToast={showToast}
                    handleShowToast={handleShowToast}
                    getConfigurations={getConfigurations}
                />
            </div>
        </NotificationLayout>
    );
};
