import { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';

import { phoneMask } from '../utils/masks';

function Contato() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const [sent, setSent] = useState(false);

    const [values, setValues] = useState({
        nome: '',
        email: '',
        telefone: '',
        proposta: '',
        assunto: '',
        textoAssunto: '',
    });

    const handleSent = () => {
        setSent(true);
        setValues({
            nome: '',
            email: '',
            telefone: '',
            proposta: '',
            assunto: '',
            textoAssunto: '',
        });
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div className="mb-20" id="contato">
            <div className="my-5">
                <div
                    className="pl-5 pr-10 md:px-48 pt-10 pb-5"
                    style={{ maxWidth: '48rem' }}
                >
                    {isMobile ? (
                        <>
                            <h4 className="font-bold title-campanhas">
                                Dúvidas em participar do Grana Extra?
                            </h4>

                            <p className="my-3 text-secondary max-w-screen-sm">
                                Todos os campos que{' '}
                                <span className="font-bold">
                                    não estiverem demarcados como (Opcional)
                                </span>
                                <br /> são obrigatórios o preenchimento.
                            </p>
                        </>
                    ) : (
                        <>
                            <h4 className="font-bold title-campanhas">
                                Dúvidas em participar <br /> do Grana Extra?
                            </h4>

                            <p className="my-3 text-secondary max-w-screen-sm">
                                Todos os campos que{' '}
                                <span className="font-bold">
                                    não estiverem demarcados como (Opcional)
                                </span>{' '}
                                são obrigatórios o preenchimento.
                            </p>
                        </>
                    )}
                </div>
            </div>

            <div className="px-5 w-full flex justify-center">
                <iframe
                    id="ifrm1"
                    name="ifrm1"
                    title="ifrm1"
                    style={{ display: 'none' }}
                />

                <form
                    action={process.env.REACT_APP_SALESFORCE_URL}
                    // action="https://sulamerica--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                    method="POST"
                    onSubmit={handleSent}
                    target="ifrm1"
                    style={{ width: '35rem' }}
                >
                    {/* <input type="hidden" name="orgid" value="00D55000000Tmsg" /> */}
                    <input
                        type="hidden"
                        name="orgid"
                        value={process.env.REACT_APP_SALESFORCE_ORG_ID}
                    />
                    <input type="hidden" name="retURL" value="http://" />

                    <div className="hidden">
                        <label htmlFor="type">Type</label>
                        <select id="type" name="type" defaultValue="Home">
                            <option value="Area Logada">Area Logada</option>
                            <option value="Home" defaultChecked>
                                Home
                            </option>
                        </select>
                        <label htmlFor="recordType">Case Record Type</label>
                        <select id="recordType" name="recordType">
                            <option
                                value={
                                    process.env.REACT_APP_SALESFORCE_RECORD_TYPE
                                }
                            >
                                Case Grana Extra
                            </option>
                        </select>
                    </div>

                    <div className="mb-6">
                        <label className="text-normal mb-2" htmlFor="name">
                            Nome
                        </label>
                        <input
                            className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            maxLength={80}
                            size={20}
                            name="name"
                            type="text"
                            placeholder="Nome"
                            required
                            // value={values.nome}
                            onChange={(e) =>
                                setValues({ ...values, nome: e.target.value })
                            }
                        />
                    </div>
                    <div className="mb-6">
                        <label className="text-normal mb-2" htmlFor="email">
                            E-mail
                        </label>
                        <input
                            className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            maxLength={80}
                            size={20}
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            required
                            // value={values.email}
                            onChange={(e) =>
                                setValues({ ...values, email: e.target.value })
                            }
                        />
                    </div>
                    <div className="mb-6 md:flex justify-between">
                        <div className="flex-1 my-3 md:mr-3">
                            <label className="text-normal mb-2" htmlFor="phone">
                                Telefone
                            </label>
                            <MaskedInput
                                guide={false}
                                mask={phoneMask}
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="phone"
                                maxLength={40}
                                size={20}
                                name="phone"
                                type="text"
                                placeholder="Telefone"
                                required
                                // value={values.telefone}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        telefone: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="flex-1 my-3 md:ml-3">
                            <label
                                className="text-normal mb-2"
                                htmlFor="proposta"
                            >
                                Número de proposta (Opcional)
                            </label>
                            <input
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id={
                                    process.env
                                        .REACT_APP_SALESFORCE_PROPOSAL_NUMBER
                                }
                                maxLength={100}
                                name={
                                    process.env
                                        .REACT_APP_SALESFORCE_PROPOSAL_NUMBER
                                }
                                size={20}
                                placeholder="Número de proposta"
                                type="text"
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        proposta: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="mb-5">
                            <label
                                className="text-normal mb-2"
                                htmlFor="subject"
                            >
                                Assunto
                            </label>
                            <input
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="subject"
                                name="subject"
                                maxLength={80}
                                size={20}
                                type="text"
                                placeholder="Assunto"
                                required
                                // value={values.assunto}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        assunto: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="">
                            <textarea
                                className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                rows={7}
                                style={{ resize: 'none' }}
                                placeholder="Assunto"
                                name="description"
                                required
                                // value={values.textoAssunto}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        textoAssunto: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full flex md:justify-between items-center flex-wrap sm:flex-nowrap">
                        <div
                            className={`flex gap-2 items-center ${
                                sent ? 'visible' : 'invisible'
                            }`}
                        >
                            <p>Sua solicitação foi enviada com sucesso! </p>
                            <img src="/images/check.svg" alt="" />
                        </div>

                        <button
                            type="submit"
                            className="w-full md:w-auto py-3 px-12 text-lg text-white font-bold bg-primary radius-button"
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Contato;
