/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import sadFaceIcon from '../assets/sad_face-icon.png';
import checkIcon from '../assets/svgs/check-icon.svg';
import { api } from '../services/api';
import { CustomIcon } from './CustomIcon';
import Dialog from './Dialog';
import { Toast } from './Toast';

type contentCardType = {
    id: number;
    user: string;
    checked: boolean;
    type: string;
    label: string;
};

type NotificationToggleProps = {
    isLoading?: boolean;
    isLoadingSwitch?: number | null;
    title: string;
    complement?: string;
    data: contentCardType[];
    email?: string;
    showToast?: boolean;
    setEmail?: Dispatch<SetStateAction<string>>;
    onSwitchChange: (id: number, checked: boolean) => void;
    handleShowToast?: (action: 'open' | 'close') => Promise<void>;
    getConfigurations?: (upgradingSwitch?: boolean) => Promise<void>;
};

export const NotificationToggle = ({
    isLoading,
    isLoadingSwitch,
    title,
    complement,
    data,
    email,
    showToast,
    setEmail,
    onSwitchChange,
    handleShowToast,
    getConfigurations,
}: NotificationToggleProps) => {
    const [errorAlert, setErrorAlert] = useState(false);
    const [confirmAlert, setConfirmAlert] = useState(false);

    const [isOpenModal, setIsOpenModal] = useState(false);

    const disableButton = email === '' || confirmAlert;

    const user =
        sessionStorage.getItem('@grana-extra') !== 'null'
            ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
            : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

    const handleChangeEmail = (email: string) => {
        setErrorAlert(false);
        setConfirmAlert(false);
        setEmail(email);
    };

    const handleSubmitEmail = async () => {
        const body = {
            email: {
                valor: email,
            },
        };

        if (email.includes('@')) {
            try {
                const response = await api.post(
                    `v1/notificacoes/contatos`,
                    body,
                    { headers: { Authorization: user.access_token } }
                );

                setIsOpenModal(true);
                setConfirmAlert(true);
                getConfigurations(true);
            } catch (error) {
                console.log(error);
            }
        } else {
            setErrorAlert(true);
        }
    };

    return !isLoading && data?.length > 1 ? (
        <div className="bg-white text-[#001E64] shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl p-5 flex flex-col gap-3 text-base">
            <span className="flex flex-col  md:flex-row gap-2 font-bold items-center">
                <h2 className="text-[#323232] text-[1.25rem]">{title}</h2>
                <p>{complement}</p>
            </span>
            {title.includes('e-mail') && (
                <div className="bg-[#F4F4F4] rounded-md p-5">
                    <span className="flex flex-col  md:flex-row gap-2">
                        <h2 className="text-[#323232] font-bold">E-mail</h2>
                        <p className="text-[0.875rem]">
                            (Por favor, insira o seu endereço de e-mail para
                            receber esta notificação.)
                        </p>
                    </span>
                    <span className="flex gap-6 mt-2">
                        <div className="flex flex-col w-[65%] gap-2 relative">
                            <input
                                className="h-12 px-5"
                                placeholder="E-mail"
                                value={email}
                                onChange={(e) =>
                                    handleChangeEmail(e.target.value)
                                }
                            />
                            <span
                                style={{
                                    display: confirmAlert ? 'flex' : 'none',
                                }}
                                className="text-[#146CFA] font-bold absolute right-3 top-3 cursor-pointer"
                                onClick={handleSubmitEmail}
                            >
                                reenviar e-mail
                            </span>
                            <span
                                style={{
                                    display: errorAlert ? 'flex' : 'none',
                                }}
                                className="text-[#AA2C23] font-bold"
                            >
                                e-mail inválido
                            </span>
                            <span
                                style={{
                                    display: confirmAlert ? 'flex' : 'none',
                                }}
                                className="text-[#FF5000] font-bold"
                            >
                                Confirmação pendente. Confirme em até 24hrs
                            </span>
                        </div>
                        <button
                            disabled={disableButton}
                            style={{
                                cursor: disableButton
                                    ? 'not-allowed'
                                    : 'pointer',
                                background: disableButton
                                    ? '#828282'
                                    : '#001E64',
                            }}
                            className="px-12 bg-[] text-white font-bold rounded-full max-h-12"
                            onClick={handleSubmitEmail}
                        >
                            Salvar
                        </button>
                    </span>
                </div>
            )}
            <hr className="my-4 mx-[-1.25rem]" />
            {data?.map((item, index) => (
                <div
                    key={index}
                    style={{
                        background: index % 2 ? '#D3DEED' : '#F4F4F4',
                    }}
                    className="border-l-4 border-[#FF5000] rounded-lg p-3 md:py-4 md:px-5 flex justify-between items-center"
                >
                    <p className="max-w-[12rem] md:max-w-full">{item?.label}</p>
                    <div className="relative">
                        {isLoadingSwitch === item?.id ? (
                            <div
                                style={{ borderTopColor: 'transparent' }}
                                className="w-8 h-8 border-4 border-[#001E64] border-solid rounded-full animate-spin"
                            ></div>
                        ) : (
                            <button
                                className="bg-transparent"
                                onClick={() =>
                                    onSwitchChange(item?.id, !item?.checked)
                                }
                            >
                                <div
                                    className={`w-12 h-4 rounded-full shadow-inner ${
                                        item?.checked
                                            ? 'bg-[#757dbb6b]'
                                            : 'bg-gray-400'
                                    }`}
                                ></div>
                                <div
                                    className={`absolute w-6 h-6 rounded-full shadow inset-y-0 top-[-0.25rem] left-0 ${
                                        item?.checked
                                            ? 'bg-[#001E64] left-6'
                                            : 'bg-white'
                                    }`}
                                ></div>
                            </button>
                        )}
                    </div>
                </div>
            ))}
            <Dialog
                isOpen={isOpenModal}
                title={''}
                description={''}
                setIsOpen={setIsOpenModal}
            >
                <div className="w-[100%] flex flex-col items-center">
                    <CustomIcon src={checkIcon} width={49} height={49} />
                    <h2 className="text-[#006217] font-bold text-[1.5rem] mt-5">
                        E-mail enviado com sucesso!
                    </h2>
                    <div className="text-center text-[#323232]">
                        <p>
                            Por favor, acesse o seu e-mail cadastrado&nbsp;
                            <strong>{`(${email})`}</strong> para validar.&nbsp;
                            Verifique sua caixa de{' '}
                            <strong>entrada ou spam.</strong>
                        </p>
                    </div>
                </div>
            </Dialog>
            {showToast && (
                <Toast
                    typeMessage={'error'}
                    message={'Aguardando confirmação do e-mail'}
                    onClose={handleShowToast}
                />
            )}
        </div>
    ) : (!isLoading && data?.length < 1) || (!isLoading && !data) ? (
        <div className="bg-white text-[#001E64] shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl p-10 flex flex-col gap-3 text-base">
            <div className="flex flex-col gap-7 justify-center items-center">
                <img src={sadFaceIcon} alt="sad-face" width={75} height={75} />
                <p className="text-[#AAA]">
                    Parece que você não tem nenhuma configuração.
                </p>
            </div>
        </div>
    ) : (
        <div className="bg-white text-[#001E64] shadow-[0px_0px_5px_rgba(50,50,50,0.25)] rounded-xl p-16 flex flex-col gap-3 text-base">
            <div className="flex flex-col gap-7 justify-center items-center">
                <div
                    style={{ borderTopColor: 'transparent' }}
                    className="w-20 h-20 border-4 border-[#001E64] border-solid rounded-full animate-spin"
                ></div>
            </div>
        </div>
    );
};
