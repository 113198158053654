/* eslint-disable no-unused-vars */
import { createContext, ReactNode, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { api } from '../services/api';

interface ProposalProviderProps {
    children: ReactNode;
}

interface ProposalContextData {
    proposal: any;
    step: number;
    loading: boolean;
    nextStep: () => void;
    previousStep: () => void;
    updateProposal: (data: any) => void;
    submitProposal: () => void;
    resetProposal: () => void;
    createdProposal: boolean;
}

const ProposalContext = createContext<ProposalContextData>(
    {} as ProposalContextData
);

export function ProposalProvider({ children }: ProposalProviderProps) {
    const [proposal, setProposal] = useState<any>({});
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [createdProposal, setCreatedProposal] = useState(false);

    function updateProposal(data: any) {
        const newProposal = {
            ...proposal,
            ...data,
        };
        console.log(newProposal);
        setProposal(newProposal);
    }

    async function submitProposal() {
        setLoading(true);

        const user =
            sessionStorage.getItem('@grana-extra') !== 'null'
                ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

        const newProposal = {
            plan: {
                type: proposal.typeProposal,
                persons:
                    proposal.cpfList?.length > 0
                        ? { cpf: proposal.cpfList[0].cpf }
                        : { cpf: null },
                companies:
                    proposal.cnpjList?.length > 0
                        ? proposal.cnpjList?.map((company: any) => {
                              return { cnpj: company.cnpj };
                          })
                        : null,
            },
            products: {
                health: {
                    proposal_number: +proposal.product.num_saude || null,
                },
                dental: {
                    proposal_number: +proposal.product.num_odonto || null,
                },
                global_capital: {
                    proposal_number:
                        +proposal.product.num_capital_global || null,
                },
                health_protect: {
                    proposal_number:
                        +proposal.product.num_protecao_saude || null,
                },
            },
            has_supervisor: proposal.hasSupervisor ?? false,
            supervisor: {
                full_name: proposal.supervisor.name,
                cpf: proposal.supervisor.cpf,
            },
            broker: proposal.corretora.name,
        };

        try {
            await api
                .post(`/v1/vendedores/proposta`, newProposal, {
                    headers: { Authorization: user.access_token },
                })
                .finally(() => setLoading(false));

            setStep(0);
            setCreatedProposal(true);
        } catch (error: any) {
            const notify = () =>
                toast.error(
                    `Erro ${error.code} um erro. Por favor, tente novamente em instantes ou entre em contato`
                );
            notify();
            console.log(error);
        }
    }

    function nextStep() {
        setStep(step + 1);
    }

    function previousStep() {
        setStep(step - 1);
    }

    function resetProposal() {
        setProposal({});
        setStep(1);
        setLoading(false);
        setCreatedProposal(false);
    }

    return (
        <ProposalContext.Provider
            value={{
                proposal,
                step,
                loading,
                createdProposal,
                resetProposal,
                nextStep,
                previousStep,
                updateProposal,
                submitProposal,
            }}
        >
            {children}
        </ProposalContext.Provider>
    );
}

export function useProposal() {
    const context = useContext(ProposalContext);

    return context;
}
