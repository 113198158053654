export const corretoras = [
    'TJK CORR DE SEGS LTDA',
    'GRU BROKER CORRETORA DE SEGUROS LTDA',
    'GUEDES MAIA PRIME CORR DE SEGUROS LTDA',
    'VENDYSEGUROS CORRETORA DE SEGUROS LTDA',
    'JARAGUA CORR SEGS LTDA',
    'COMPLETO CORRETORA DE SEGUROS LTDA',
    'F M S L CORRETORA DE SEGUROS LTDA',
    '4ALL CORR DE SEGS DA PARAIBA LTDA',
    'SOLANO JUNIOR CORR DE SEGS LTDA ME',
    'CAMED ADM CORR DE SEGS LTDA',
    'DOR PME CORR DE SEGS E SERVS ONLINE LTDA',
    'NOVA CASA DO CORRETOR CORR SEGS E SAÚDE',
    'CARATIERO CORR SEGS LTDA',
    'FAST CORRETORA DE PLAN DE SAUDE LTDA',
    'CAPTURE SAUDE LTDA',
    'MB TONI CORR DE SEGUROS E SAUDE LTDA',
    'PRO FEDERAL CORRETORA E ADM SEGUROS LTDA',
    'BEST LIFE PLATAFORMA LTDA',
    'PATRONE SAUDE SUL LTDA',
    'EQUILIBRAR CORR DE SEGS LTDA',
    'KURTLEWIN CORR DE SEGS',
    'OPPORTUNITY CORR DE SEGS DE VIDA LTDA',
    'CLASSI CORRETORA DE SEGS LTDA ME',
    'MEDLIFE BRASILIA CORR DE SEGS SAUDE E VI',
    'TOP1000 CORR DE PLAN SAUDE E SEGS LTD ME',
    'SAUDE BRASILIA CORR DE SEGS LTDA ME',
    'VIPCARE CORRETORA DE SEGUROS EIRELI',
    'REFERÊNCIA INTERNACIONAL',
    'SEK ASSESSORIA E CORRETORA DE SEGS LTDA',
    'COMPACTA E VIANA ADM E CORR SEGS LTDA',
    'ROTA BH CORRETORA DE SEGUROS LTDA',
    'BENEFICIO INTELECTUAL ADM COR SEG EIRELI',
    'SERGIO WELERSON DE MORAIS',
    'CONSULT CORRETORA DE SEGUROS LTDA ME',
    'ROTA MINAS CORRETORA DE SEGUROS LTDA',
    'REAL LIFE CORRETORA DE SEGUROS LTDA ME',
    'VIA PLENA CORRETORA DE SEGUROS LTDA',
    'MAIS CORRETORA DE SEGUROS EIRELI',
    'WALKER BANDEIRA ANDRIOLA',
    'DELFHI CORR DE SEGUROS ADM E REPR LTDA',
    'MARIA EDNEIDE ARAUJO LOBATO',
    'CURSOR CORR DE SEGS LTDA',
    'HAZ CORRETORA DE SEGUROS LTDA EPP',
    'NHD CORRETORA DE SEGUROS S/S LTDA',
    'PREMIER AUTO CORRETORA DE SEGS EIRELI',
    'USINA DO SEGURO ADM E CORR DE SEGS LTDA',
    'MASSAU CORRETORA DE SEGS LTDA',
    'JOCICROSS ADMR E CORRETORA DE SEGUROS',
    'PREVSEG CORRETORA DE SEGUROS LTDA',
    'FLR FUTURO CORR DE SEGS DE VIDA LTDA',
    'VALLIA PREVSEG CORR DE SEGS LTDA',
    'TOPVIDA CORRETORA DE SEGUROS LTDA',
    'SALUS CORRETORA DE SEGUROS LTDA',
    'LAPENDA CORRETORA DE SEGUROS EIRELI ME',
    'M G SERV EM PLANOS SAUDE COR SEGUROS LTD',
    'ADESAO CORR SEGS DE VIDA CAP E PREV LTDA',
    'AGF CORR DE SEGS VIDA CAP E PREV PRIV LT',
    'BASE CORRETORA DE SEGUROS EIRELI ME',
    'BRASCORR CORRETORA DE SEGUROS LTDA ME',
    'CEDRO KABBAZ CORR SEGS VIDA CAP PREV PRI',
    'EFICAZ CORRETORA DE SEGUROS LTDA',
    'MEGASEGUR CORRETORA DE SEGS LTDA',
    'BEMAC CORRETORA DE SEGS DE VIDA EIRELI',
    'PRAYMY S CORR DE SEGS DE VIDA LTDA',
    'SUTRA CORRETORA DE SEGUROS DE VIDA LTDA',
    'VELOG CORRETORA DE SEGUROS DE VIDA LTDA',
    'AUDAZ CORR AGENTES DE SEGUROS LTDA',
    'BELZ CORRETORA DE SEGUROS LTDA',
    'RBBL CORRETORA DE SEGUROS LTDA ME',
    'CLUB DE BENEFICIOS ADM E CORR DE SEGS',
    'OVIEDO CORRETORA DE SEGUROS EIRELI EPP',
    'TELES CORRETORA DE SEGUROS LTDA ME',
    'PROSEMA CORR DE SEGS LTDA',
    'P G B ADM & CORR DE SEGS EIRELI EPP',
    '3G CORRETORA DE SEGUROS DE VIDA LTDA',
    'MEGA ADM COR PLAN SAUDE SEGS LTDA ME',
    'JOAO CARLOS DE OLIVEIRA',
    'PARANA VIDA E PREV CORR DE SEGS LTDA ME',
    'PRIME BROKER CORRETORA DE SEGUROS LTDA',
    'ASA BENEFICIOS E CORG DE SEGS LTDA ME',
    'PREMIUM CORR DE SEGS E PLANOS DE SAUDE',
    'PORTOCEL CORRETORA DE SEGS DE VIDA LTDA',
    'SK CORR DE SEGS DE VIDA LTDA',
    'A GERGEN CORRETORA DE SEGUROS LTDA ME',
    'UPAON-ACU CORRETORA DE SEGUROS LTDA-ME',
    'ANDRE LUIS TEIXEIRA GERGEN',
    'NOVO RUMO CORR DE SEGS E PLANOS SAUDE LT',
    'WALLAU CORRETORA DE SEGUROS LTDA',
    'WALLAU SEG CORR DE SEGS EIRELI',
    'MQCORR CORR DE SEGS LTDA',
    'AGECOR CORRETORA DE SEGUROS LTDA',
    'QUALIVIDA CORRETORA DE SEGUROS LTDA ME',
    'MARIO GOMES FERREIRA JUNIOR',
    'MS BUSINESS CORRETROA DE SEGUROS LTDA',
    'RESILIENCIA CORRETORA DE SEGUROS LTDA',
    'MAIS OPCAO CORRETORA DE SEGUROS LTDA',
    'MUITOMAIS SAUDE CORRETORA DE SEGS LTDA',
    'VICTORIA SIMOES CORR DE SEGS LTDA',
    'BLUE LIGHT CORR DE PLANOS DE SAUDE EIREL',
    'CORP SAUDE CONSL E CORR DE SEGS LTDA',
    'MULTI SALES CORR DE SEGS E REPRES EIRELI',
    'VHIA CORRETORA DE SEGUROS LTDA',
    'M A CORRETORA DE PLANOS DE SAUDE EIRELI',
    'SOMA CORRETORA DE SEGUROS LTDA',
    'UNICLASS CORRETORA DE SEGUROS EIRELI',
    'TERRA LOPES CORR DE SEGS VIDA E PREV LTD',
    'MARTINS E GOMES CORRETAGEM DE SEGS LTDA',
    'NOVA PETROPOLITANA ASSES CORR SEGT LTD',
    'JC LUZ VOLTA REDONDA CORR ADM DE SEGS LT',
    'VIACORP PLUS CORRETORA DE SEGS DE VD LTDA',
    'BEST LIFE CORR',
    'APRIL BROKER DO BRASIL CORR DE SEGS S A',
    'MONICA SERRA RANUCCI',
    'SERRA J C CORR DE SEGS LTDA',
    'ATUAL BUSINESS CORR DE SEGS LTDA ME',
    'F8 CORR DE SEGS VIDA E BENEF LTDA',
    'NOVA AFFINITY ADM E CORR DE SEGS VIDA LT',
    'VARELA CORR DE SEGS LTDA',
    'NOVA AFFINITY CORRETORA DE SEGUROS LTDA',
    'CENTER VIDA CORR DE SEGS LTDA - EPP',
    'ESTILO FOMENTO CORR DE SEGS LTDA',
    'FELIPE BATISTA DE CARVALHO',
    'MMARA CORR DE SEGS LTDA ME',
    'LEPAMED CORR DE SEGS LTDA - EPP',
    'LPX CORR DE SEGS LTDA EPP',
    'LPX CORRETORA DE SEGS LTDA EPP',
    'DINASTY CORR DE SEGS UNIPESSOAL LTDA',
    'NOVA MCF CORR DE SEGS LTDA',
    'W3G CONSUL E REPRES COMERCIAL LTDA ME',
    'BARELA ABC ADMR E CORR DE SEGS LTDA',
    'BARELA ADM CORR SEGS LTDA',
    'BARELA CORP CORR DE SEGS LTDA',
    'BARELA TAILOR MADE ADM CORR DE SEGS LTDA',
    'CALLARI & RADUAN CORR SEGS LTDA',
    'CANTEIRO E RADUAN CORR SEGS',
    'ISENCAO PLAN CONS ASS ADM E CORR DE SEGS',
    'ITS SEG CORRETORA DE SEGUROS SA',
    'ITSSEG PARTNERS CORRETORA DE SEGUROS LTD',
    'MBS PARTNERS CORR DE SEGS E CONS LTDA',
    'MULTI BENEFIT SER CORR DE SEGS CONS LTDA',
    'MV2 LIFE PLANNERS CORR SEGS DE VIDA LTDA',
    'N RADUAN PLAN CONS ASS ADM CORG SEGS LTD',
    'TORRES & ASSOCIADOS CONS ADM CORG SEGS L',
    'DONDA CORR SEGS LTDA',
    'MODENA CORR DE SEGS LTDA',
    'AIRES & MOURA CORR DE SEGS LTDA',
    'AS SURE SANTO ANDRE CORR SEGS VIDA LTDA',
    'AS-SURE BRASIL CORR DE SEGS LTDA',
    'SAUDE MAIS CONS E CORR DE SEGS LTDA',
    'SEG FORTE ADM CORR SEGS LTDA',
    'D&P WHITE MASTER CORR DE SEGS LTDA',
    'VALERIA ANTUNES DE AMORIM',
    'R F LEMOS CORR DE SEGS EIRELI EPP',
    'SILVANA APARECIDA SILVA FERREIRA FLORES',
    'WTG ASSESSORIA DE SEGUROS LTDA',
    'CORREA LIMA CORR SEGS SC LTDA',
    'M & F CORRETORA DE SEGUROS LTDA ME',
    'F & C CORRETORA DE SEGUROS LTDA ME',
    'F & F CORRETORA DE SEGUROS LTDA ME',
    'LONG LIFE CORRETORA DE SEGUROS LTDA ME',
    'D2PAR CORRETORA DE SEGUROS EIRELI',
    'QUINTIALIANO TROVO CORR SEGS LTDA',
    'CASCARDO & RIBEIRO CORR DE SEGS VIDA LTD',
    'LUIZ CARLOS CASCARDO',
    'CJWS CORRETORA DE SEGUROS LTDA EPP',
    'ATIVA NEW CORRETORA DE SEGUROS LTDA',
    'PRC ATIVA NEW CORR DE SEGS/SEGURI CORR',
    'AMERICA SAUDE VIDA E PREV CORR DE SEGS',
    'THEO-GN CORR DE SEGS LTDA',
    'THEO CENTRAL DE BEN E CORR DE SEGS LTDA',
    'CONECTA CORR DE SEGS E PL DE SAUDE LTDA',
    'TRADS CORRETORA DE SEGUROS EIRELI',
    'PLATAFORMA VIDA SERVS CORR SEGS EIRELI',
    'MELIM CORRETORA DE SEGUROS LTDA ME',
    'G TREMANTI ADMR DE BENEF LTDA',
    'AC CORRETORA DE SEGUROS E PLAN SAUDE LTD',
    '3 M CORRETORA DE SEGUROS',
    'VITÓRIA ASSESSORIA E CORR DE SEGS LTDA ME',
    'PEGADO & PEGADO CORR DE SEGS LTDA ME',
    'MULTIPLA CORRETORA DE SEGUROS LTDA',
    'ETHIC CORR DE SEGS LTDA',
    'RAZY CORRETORA DE SEGUROS LTDA',
    'USINA DO SEGURO PETROLINA',
    'APALLC SAÚDE NITERÓI',
    'XINGU CORR DE SEGS DE VIDA SS LTDA ME',
    'X CORRETORA DE SEGUROS LTDA',
    'R SIM CORR DE SEGS E BENEFICIOS LTDA',
    'MAURO BARROS SERV DE CORRET SEGS EIRELI',
    'AGF CORR DE SEGS VIDA CAP E PREV PRIV L',
    'YIA BRASIL CORRETAGEM DE SEGUROS S A',
    'AGUIA FORTE ADM E CORR DE SEGUROS LTDA',
    'HEALTH CORR CORR DE SEGS EIRELI',
    'RIO SOL CORRETAGEM DE SEGS LTDA',
    'JC LUZ VOLTA REDONDA CORR ADM DE SEGS L',
    'TOP1000 CORR DE PLAN SAUDE E SEGS LTD M',
    'DASEL GESTAO EMPRESARIAL E COMERCIO LTDA',
    'D J ATLANTIS CORR DE SEGS VIDA SS LTDA',
    'MASTERCLUBE CLUBE DE BEN E SERV DE CORR',
    'PROTECT SEG CORRETORA DE SEGUROS LTDA',
    'S MARINS CORRETORA DE SEGUROS LTDA',
    'YASMIN GISELE NAZER DE ASSIS ME',
    'NOVA CORRETORA SEGS NORTE FLUMINENSE LTD',
    'INTEGRA VITA GESTAO DE BENEF COR SEG LT',
    'PATRIOTA CORR DE SEGS EIRELI',
    'NOVA LIDER RIO CORRETORA DE SEGUROS LTDA',
    'TEXAS AFFINTY CORR DE SEGS LTDA',
    'LITORAL CORR DE PLANOS DE SAÚDE E SEGS',
    'BRAZILCALL BUSINESS INTELIGENCE LTDA',
    'DIRECIONAL CORR DE SEGS E SAUDE LTDA',
    'HELP CONSULT E CORR DE SEGS LTDA',
    'SAVVY CORRETORA DE SEGUROS LTDA',
    'MONT SEGURO CORRETORA DE SEGUROS LTDA',
    'BRASCARE SAUDE E CORR',
    'VIPMAR CORRETORA DE SEGUROS LTDA',
    'ESSENCIAL CORRETORA LTDA',
    'CONSORTE CORRETAGEM DE SEGS EIRELI ME',
    'AFINIX CORR SEGS LTDA',
    'RIBERCRED CORRETORA DE SEGUROS LTDA',
    'M P BRITO COMITRE CORR DE SEGS ME',
    'JANAINA F CUNHA CORR DE SEGS EIRELI',
    'INVEST PRIME CORRETORA DE SEGUROS LTDA',
    'MODENA ABC ASSES E CORR DE SEGS LTDA',
    'R A CORRETORA DE SEGS DE ITABRAI LTDA ME',
    'CONSULPLAN CORR SEGS PLAN DE SAUDE LTDA',
    'GRAND FORTAL CORRETORA DE SEGS. LTDA',
    'RIO COR GRUPO DE SAUDE LTDA',
    'BERG PLANOS DE SAUDE SEGS E INVEST LTDA',
    'KASANOVA CORR DE SEGS LTDA',
    'BSBCOR CORR ADM SEGS LTDA',
    'GRUPO EUROPA CONSULT E CORR DE SEGS LTDA',
    'BLUAL INVEST CORR DE SEGS DE VIDA LTDA',
    'FACIL SEGUROS E SAUDE CORR LTDA',
    'VIVER BEM CORR SEGS E PREVIDENCIA EIRELI',
    'DRVS CORRETORA DE SEGS LTDA',
    'A REDE CORPORATION CORR DE SEGUROS',
    'QUALISAFE CORR DE SEGS EIRELI',
    'PLATINUM PLANOS SAUDE CORR DE SEGS LTDA',
    'STAR LINE CORR DE SEGS DE VIDA LTDA',
    'A&G CORRETORA DE SEGUROS DE VDA LTDA EPP',
    'FATIMA BATISTA DA SILVA',
    'MK PLANOS DE SAUDE LTDA',
    'NEW OLD CORRETAGEM DE SEGUROS LTDA',
    'PROCTOR CORRETORA DE SEGUROS EIRELI',
    'SUPERSEG CORR DE SEGS SOC UNIPESSOAL LTD',
    'ALLIANCA CORRETORA DE SEGS E BENEF LTDA',
    'QUER CORRETORA DE SEGS E PL DE SAUDE LTD',
    'NOVA PREMIER CORR DE SEGS LTDA',
    'CASSIO ADM E CORRETAGEM DE SEGS LTDA ME',
    'MTAE CORR DE SEGS EIRELI',
];
