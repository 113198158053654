import { format } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AiOutlineEye } from 'react-icons/ai';
import { FaArrowsAlt } from 'react-icons/fa';
import { RiFileCopy2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import DragContext from '../contexts/DragContext';
import FilterContext from '../contexts/FilterContext';
import { KanbanResponsive } from './KanbanResponsive';
import ModalConfirmStatus from './ModalConfirmStatus';
import ModalDetails from './ModalDetails';
import { ModalFilter } from './ModalFilter';
import { StepsLead } from './StepsLeads';

type ShowOrHidePhoneProps = {
    phone: string;
    celphone: string;
    canShow: boolean;
};

const ShowOrHidePhone = ({
    phone,
    celphone,
    canShow,
}: ShowOrHidePhoneProps) => {
    const formatCel = (number = '') => {
        if (!number) return '';

        let formating = String(number).replace(/\D/g, '');

        formating = formating.replace(/^0/, '');

        if (formating.length >= 12) {
            formating = formating.slice(2);
            if (formating.length === 10) {
                formating = formating.replace(
                    /^(\d\d)(\d{4})(\d{4}).*/,
                    '($1) $2-$3'
                );
                return formating;
            }
            formating = formating.replace(
                /^(\d\d)(\d{5})(\d{4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length === 11) {
            formating = formating.replace(
                /^(\d\d)(\d{5})(\d{4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length > 10) {
            formating = formating.replace(
                /^(\d\d)(\d{5})(\d{4}).*/,
                '($1) $2-$3'
            );
            console.log(formating);
        } else if (formating.length > 5) {
            formating = formating.replace(
                /^(\d\d)(\d{4})(\d{0,4}).*/,
                '($1) $2-$3'
            );
        } else if (formating.length > 2) {
            formating = formating.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else {
            formating = formating.replace(/^(\d*)/, '($1)');
        }
        return formating;
    };

    const getTelephoneOrCellPhoneNumber = (telephone: any, cell: any) => {
        const telephoneFormatted = telephone?.replace(/\D/g, '');
        const cellFormatted = cell?.replace(/\D/g, '');

        let phoneNumber = '';

        if (
            cellFormatted &&
            telephoneFormatted &&
            telephoneFormatted?.length >= 10 &&
            cellFormatted?.length >= 10
        ) {
            phoneNumber = `${formatCel(cellFormatted)} / ${formatCel(
                telephoneFormatted
            )}`;
        } else if (telephoneFormatted && telephoneFormatted?.length >= 10) {
            phoneNumber = `${formatCel(telephoneFormatted)}`;
        } else if (cellFormatted && cellFormatted?.length >= 10) {
            phoneNumber = `${formatCel(cellFormatted)}`;
        } else {
            phoneNumber = '';
        }

        return phoneNumber;
    };
    if (!canShow) {
        const ddd = phone.slice(0, 2);

        return (
            <>
                <p>{ddd}</p>
                <div className="w-[5.73188rem] h-[1.48188rem] bg-[#F4F4F4] blur-[2px]" />
            </>
        );
    } else return <p>{getTelephoneOrCellPhoneNumber(phone, celphone)}</p>;
};

function Kanban({ data, campaigns, exportData }) {
    const userRef = useRef(null);

    const fromBackendOportunidades = [
        {
            id: '',
            DATA_ULTIMA_VISUALIZACAO__c: null,
            EMAIL__c: '',
            DATA_SOLICITACAO_CONTATO__c: null,
            ABORDAGEM_N3__c: null,
            EventUuid: '',
            _EventType: '',
            ESTRUTURA_APOIO__c: null,
            OBJETIVO_CAMPANHA__c: '',
            NOME_CORRETOR__c: '',
            CreatedById: '',
            ACAO_APOIO__c: null,
            NOME__c: '',
            PDN_LEADUPDATE__c: '',
            CODIGO_PRODUTOR__c: '',
            ID_MDM__c: '',
            PAPEL__c: '',
            SOLICITOU_CONTATO__c: null,
            TELEFONE__c: null,
            EXPORTADO_CSV__c: null,
            _ObjectType: '',
            STATUS__c: '',
            DATA_CAMPANHA__c: '',
            ABORDAGEM_N2__c: null,
            RATING__c: null,
            LOTE__c: '',
            PDN_NomeEstipulante__c: null,
            DESCRITIVO_CAMPANHA__c: '',
            ABORDAGEM__c: null,
            CELULAR__c: '',
            Campanha__c: '',
            ESTRUTURA_VENDA__c: '',
            ReplayId: '',
            CPF__c: null,
            CreatedDate: 0,
            ID_LEAD__c: '',
            PDN_ProdutoAtual__c: null,
            INSCRICAO__c: null,
            DATA_ULTIMA_ALTERACAO__c: null,
            LOGIN__c: '',
            NOME_ESTIPULANTE__c: '',
            PRODUTO_ATUAL__c: '',
            SEGMENTO__c: '',
            CORRETORA__c: '',
            DATA_AGENDAMENTO__c: { _seconds: 0 },
            USUARIOS_VISUALIZACOES__c: [''],
        },
    ];
    const cards = {
        [uuid()]: {
            name: 'Novas Oportunidades',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: fromBackendOportunidades,
            id: 1,
            status: 'Oportunidades',
        },
        [uuid()]: {
            name: 'Fazer novo contato',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 2,
            status: 'NovoContato',
        },
        [uuid()]: {
            name: 'Em negociação',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 3,
            status: 'EmNegociacao',
        },
        [uuid()]: {
            name: 'Concluído',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 4,
            status: 'Concluido',
        },
    };
    const defaultValues = {
        name: '',
        email: '',
        cel: '',
        id: '',
        campaign: '',
        status: '',
        abordagem: {
            success: null,
            step1: '',
            step2: '',
            rating: '',
        },
    };

    const { dragState, setDragState } = useContext(DragContext);
    const [, setWidth] = useState<number>(window.innerWidth);
    const [dragResult, setDragResult] = useState<any>();
    const [loading] = useState<any>(false);
    const [isMounted, setIsMounted] = useState(false);
    const [openDetails, setOpenDetails] = useState('false');
    const [openChangeStatus, setOpenChangeStatus] = useState('false');
    const [canChangeStatus, setCanChangeStatus] = useState(false);
    const [canOpenDetails, setCanOpenDetails] = useState(false);
    const [leadDetails, setLeadDetails] = useState<any>(
        fromBackendOportunidades[0]
    );
    const [saveStatus, setSaveStatus] = useState(defaultValues);
    const [leadTransmissao, setLeadTransmissao] = useState(
        fromBackendOportunidades[0]
    );
    const [leads, setLeads] = useState(cards);

    const { filterTextState, filterSelectState } = useContext(FilterContext);
    const [leadStatus, setLeadStatus] = useState('Oportunidades');
    const [statusList, setStatusList] = useState([
        {
            status: '',
            name: '',
            items: 0,
        },
    ]);
    const [isKanbanResponsive, setIsKanbanResponsive] = useState(false);

    const [responsiveLeadsCardList, setResponsiveLeadsCardList] =
        useState<any[]>();

    const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);

    const openStepModal = (result: any) => {
        setOpenChangeStatus(uuid());
        setCanChangeStatus(true);
        setDragResult(result);
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const onDragEnd = (result: any, leads: any, setLeads: any) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = leads[source.droppableId];
            const destColumn = leads[destination.droppableId];

            if (sourceColumn.id > destColumn.id) {
                toast.error(
                    'Não será permitido voltar os cards para as etapas anteriores',
                    {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    }
                );
                return;
            }

            openStepModal(result);

            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];

            let [removed] = sourceItems.splice(source.index, 1);
            setSaveStatus({
                ...saveStatus,
                name: removed.NOME__c,
                id: removed.ID_LEAD__c,
                status: destColumn.status,
            });
            removed = {
                ...removed,
                last_modification: format(new Date(), 'dd/MM/yyyy HH:mm'),
            };
            destItems.splice(destination.index, 0, removed);
            setLeadTransmissao(removed);
            setLeads({
                ...leads,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
        } else {
            const column = leads[source.droppableId];
            const copiedItems = [...column.items];

            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);

            setLeads({
                ...leads,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
        }
    };

    const rollbackDragEnd = (result: any) => {
        const { source, destination } = result;
        const sourceColumn = leads[source.droppableId];
        const destColumn = leads[destination.droppableId];

        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];

        const [removed] = destItems.splice(destination.index, 1);
        sourceItems.splice(source.index, 0, removed);

        // setActiveButton(true);
        setSaveStatus(defaultValues);
        // setStepState(0);
        setLeads({
            ...leads,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems,
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems,
            },
        });
        setCanChangeStatus(false);
        setDragState(false);
    };

    const onFilterText = (
        name: string,
        email: string,
        cel: string,
        campaign: string
    ) => {
        if (
            name?.toLowerCase().includes(filterTextState.toLowerCase()) ||
            email?.toLowerCase().includes(filterTextState.toLowerCase()) ||
            cel?.toLowerCase().includes(filterTextState.toLowerCase()) ||
            campaign?.toLowerCase().includes(filterTextState.toLowerCase()) ||
            filterTextState === '' ||
            filterTextState === null ||
            filterTextState === undefined
        ) {
            return true;
        }

        return false;
    };

    const onFilterSelect = (campaign: string) => {
        if (filterSelectState.length === 1) {
            return true;
        }
        const verify = filterSelectState.find(
            (element: string) =>
                element.toLowerCase() === campaign?.toLowerCase()
        );
        if (verify) return true;
        else {
            return false;
        }
    };

    const copyText = (text: string) => {
        if (text.length === 0) {
            toast.warn('Sem texto para copiar', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } else {
            navigator.clipboard.writeText(text);
            toast.success('Copiado com sucesso', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    };

    const checkHasVisualization = (
        userId: string,
        USUARIOS_VISUALIZACOES__c: string[]
    ) => {
        console.log(userId);
        console.log(USUARIOS_VISUALIZACOES__c);
        return USUARIOS_VISUALIZACOES__c?.includes(userId);
    };

    const handleShowCelular = (idLead: string) => {
        setTimeout(() => {
            const updatedLeads = { ...leads };

            Object.keys(updatedLeads).forEach((key) => {
                updatedLeads[key].items.forEach((item) => {
                    if (item.ID_LEAD__c === idLead) {
                        item.USUARIOS_VISUALIZACOES__c.push(userRef.current);
                    }
                });
            });

            setLeads(updatedLeads);
        }, 1000);
    };

    const conditionStatus = (index: number, status: string) => {
        switch (status) {
            case 'Oportunidades':
                index = 1;

                break;
            case 'NovoContato':
                index = 2;

                break;
            case 'EmNegociacao':
                index = 3;

                break;
            case 'Concluido':
                index = 4;

                break;
        }

        return index;
    };

    const handleOpeningModalStatus = (item: any, status: any) => {
        if (conditionStatus(0, item.STATUS__c) > status.index) {
            toast.error(
                'Não será permitido voltar os cards para as etapas anteriores',
                {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                }
            );
            return;
        } else {
            setSaveStatus({
                ...saveStatus,
                name: item.NOME__c,
                id: item.ID_LEAD__c,
                status: status.status,
            });
            setOpenChangeStatus(status);
            setLeadTransmissao(item);
            setCanChangeStatus(true);
        }
    };

    const getListStatus = (data: any) => {
        const response = [
            {
                status: 'Oportunidades',
                name: 'Novas oportunidades',
                items: data.oportunidades ? data.oportunidades.length : 0,
                index: 1,
            },
            {
                status: 'NovoContato',
                name: 'Fazer novo contato',
                items: data.novoContato ? data.novoContato.length : 0,
                index: 2,
            },
            {
                status: 'EmNegociacao',
                name: 'Em negociação',
                items: data.emNegociacao ? data.emNegociacao.length : 0,
                index: 3,
            },
            {
                status: 'Concluido',
                name: 'Concluído',
                items: data.concluidos ? data.concluidos.length : 0,
                index: 4,
            },
        ];

        return response;
    };

    const dataForResponsiveLeadsCardList = Object.entries(leads).map(
        ([_, column]: [any, any]) => {
            return column;
        }
    );

    const itemsForResponsiveLeadsCardList = Object.entries(leads).map(
        ([_, column]: [any, any]) => {
            return column.items;
        }
    );

    const toggleModalFilter = () => {
        setIsModalFilterOpen(!isModalFilterOpen);
    };

    const dataForResponsiveLeadsCardListWithZeroValue = [
        {
            name: 'Novas Oportunidades',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 1,
            status: 'Oportunidades',
        },
        {
            name: 'Fazer novo contato',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 2,
            status: 'NovoContato',
        },
        {
            name: 'Em negociação',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 3,
            status: 'EmNegociacao',
        },
        {
            name: 'Concluído',
            color: '#001E64',
            border: 'border-[#001E64]',
            bg: 'bg-[#001E64]',
            items: [],
            id: 4,
            status: 'Concluido',
        },
    ];

    const findCampaignsForFilter = (searchValues: string[]) => {
        const campaignFiltered = dataForResponsiveLeadsCardList
            .map((obj) => {
                const filteredItems = obj.items.filter((item) =>
                    searchValues.includes(item.Campanha__c)
                );
                return {
                    ...obj,
                    items: filteredItems,
                };
            })
            .filter((obj) => obj.items.length > 0);

        const newObjsForStatusList = campaignFiltered.reduce((obj, status) => {
            let statusKeyNew;

            switch (status.status) {
                case 'Oportunidades':
                    statusKeyNew = 'oportunidades';
                    break;

                case 'NovoContato':
                    statusKeyNew = 'novoContato';
                    break;

                case 'EmNegociacao':
                    statusKeyNew = 'emNegociacao';
                    break;

                case 'Concluido':
                    statusKeyNew = 'concluidos';
                    break;
            }

            obj[statusKeyNew] = status.items;

            return obj;
        }, {});

        let newStatus;

        Object.keys(newObjsForStatusList).some((prop) => {
            const value = newObjsForStatusList[prop];

            if (value.length > 0) {
                newStatus = value;
                return true;
            }
        });

        const newResponsiveLeadsCardList =
            dataForResponsiveLeadsCardListWithZeroValue.map((obj) => {
                const corresponding = campaignFiltered.find(
                    (item) => item.status === obj.status
                );
                if (corresponding) {
                    return {
                        ...obj,
                        color: corresponding?.color,
                        border: corresponding?.border,
                        bg: corresponding?.bg,
                        items: corresponding?.items,
                    };
                }
                return obj;
            });

        setLeadStatus(
            newStatus[0].STATUS__c === 'Novas Oportunidades'
                ? 'Oportunidades'
                : newStatus[0].STATUS__c
        );
        setResponsiveLeadsCardList(newResponsiveLeadsCardList);
        setStatusList(getListStatus(newObjsForStatusList));
    };

    const findLeadForFilter = (searchValue: string) => {
        const filteredArray = dataForResponsiveLeadsCardList.filter((obj) => {
            return obj.items.some(
                (innerObj) =>
                    innerObj.NOME__c === searchValue ||
                    innerObj.EMAIL__c === searchValue
            );
        });

        const resultArray = filteredArray.map((obj) => {
            return {
                ...obj,
                items: obj.items.filter(
                    (innerObj) =>
                        innerObj.NOME__c === searchValue ||
                        innerObj.EMAIL__c === searchValue
                ),
            };
        });

        const newObjsForStatusList = resultArray.reduce((obj, status) => {
            let statusKeyNew;

            switch (status.status) {
                case 'Oportunidades':
                    statusKeyNew = 'oportunidades';
                    break;

                case 'NovoContato':
                    statusKeyNew = 'novoContato';
                    break;

                case 'EmNegociacao':
                    statusKeyNew = 'emNegociacao';
                    break;

                case 'Concluido':
                    statusKeyNew = 'concluidos';
                    break;
            }

            obj[statusKeyNew] = status.items;

            return obj;
        }, {});

        let newStatus;

        Object.keys(newObjsForStatusList).some((prop) => {
            const value = newObjsForStatusList[prop];

            if (value.length > 0) {
                newStatus = value;
                return true;
            }
        });

        const newResponsiveLeadsCardList =
            dataForResponsiveLeadsCardListWithZeroValue.map((obj) => {
                const corresponding = resultArray.find(
                    (item) => item.status === obj.status
                );
                if (corresponding) {
                    return {
                        ...obj,
                        color: corresponding?.color,
                        border: corresponding?.border,
                        bg: corresponding?.bg,
                        items: corresponding?.items,
                    };
                }
                return obj;
            });

        setLeadStatus(
            newStatus[0].STATUS__c === 'Novas Oportunidades'
                ? 'Oportunidades'
                : newStatus[0].STATUS__c
        );
        setResponsiveLeadsCardList(newResponsiveLeadsCardList);
        setStatusList(getListStatus(newObjsForStatusList));
    };

    const handleResposiveLeadCardListInitialValue = () => {
        setResponsiveLeadsCardList(dataForResponsiveLeadsCardList);
    };

    const [isLoading, setIsLoading] = useState(false);

    const responsiveKanbanProps = {
        statusList,
        leadStatus,
        setLeadStatus,
        copyText,
        handleOpeningModalStatus,
        setIsKanbanResponsive,
        isLoading,
        leads,
        setLeads,
    };

    useEffect(() => {
        const user =
            sessionStorage.getItem('@grana-extra') !== 'null'
                ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

        const decodeJwt: any = jwt_decode(user.access_token);

        userRef.current = decodeJwt.response.cpf;
    }, []);

    useEffect(() => {
        const cards = {
            [uuid()]: {
                name: 'Novas Oportunidades',
                color: '#001E64',
                border: 'border-[#001E64]',
                bg: 'bg-[#001E64]',
                items: data.oportunidades || [],
                id: 1,
                status: 'Oportunidades',
            },
            [uuid()]: {
                name: 'Fazer novo contato',
                color: '#001E64',
                border: 'border-[#001E64]',
                bg: 'bg-[#001E64]',
                items: data.novoContato || [],
                id: 2,
                status: 'NovoContato',
            },
            [uuid()]: {
                name: 'Em negociação',
                color: '#001E64',
                border: 'border-[#001E64]',
                bg: 'bg-[#001E64]',
                items: data.emNegociacao || [],
                id: 3,
                status: 'EmNegociacao',
            },
            [uuid()]: {
                name: 'Concluído',
                color: '#001E64',
                border: 'border-[#001E64]',
                bg: 'bg-[#001E64]',
                items: data.concluidos || [],
                id: 4,
                status: 'Concluido',
            },
        };

        setLeads(cards);
        setStatusList(getListStatus(data));
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [data]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (dragState) {
            rollbackDragEnd(dragResult);
        }
    }, [dragState]);

    useEffect(() => {
        handleResposiveLeadCardListInitialValue();
    }, [leads]);

    return (
        <div className="">
            {isMounted ? (
                <DragDropContext
                    onDragEnd={(result) => {
                        onDragEnd(result, leads, setLeads);
                    }}
                >
                    <div className="w-full mb:block lg:hidden px-[16px]">
                        <button
                            className={`my-[20px] border-[1px] border-[#001E64] flex items-center justify-center w-full h-[40px] bg-[#FFFFFF] rounded-3xl text-[0.875rem] cursor-pointer px-[30px] text-[#001E64]`}
                            onClick={() =>
                                setIsModalFilterOpen(!isModalFilterOpen)
                            }
                        >
                            Filtrar
                        </button>
                        {responsiveLeadsCardList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <KanbanResponsive
                                        {...responsiveKanbanProps}
                                        column={item}
                                    />
                                </div>
                            );
                        })}
                        <ModalFilter
                            leads={itemsForResponsiveLeadsCardList}
                            campaigns={campaigns}
                            exportData={exportData}
                            isModalFilterOpen={isModalFilterOpen}
                            toggleModalFilter={toggleModalFilter}
                            findLeadForFilter={findLeadForFilter}
                            findCampaignsForFilter={findCampaignsForFilter}
                        />
                    </div>
                    {!loading ? (
                        <div className="mb:hidden lg:flex items-center justify-center">
                            {Object.entries(leads).map(([columnId, column]) => {
                                return (
                                    <div
                                        className="mt-[1rem] flex flex-col items-center justify-center"
                                        key={columnId}
                                    >
                                        <StepsLead
                                            color={`${column.color}`}
                                            text={column.name}
                                            value={`${column.items.length}`}
                                        />
                                        <div className="mt-[2vh]">
                                            <Droppable
                                                droppableId={columnId}
                                                key={columnId}
                                            >
                                                {(provided) => {
                                                    if (
                                                        column.items.length <= 0
                                                    ) {
                                                        return (
                                                            <div
                                                                className="flex flex-col h-[80vh] w-[20vw] border-dashed border-[rgba(0,0,0,0.15) border-[0.13rem] items-center justify-center"
                                                                {...provided.droppableProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                            >
                                                                <div className="flex flex-col items-center ">
                                                                    <FaArrowsAlt size="2rem" />
                                                                    <p className="w-[10vw] text-[1.7vh] mt-[1vh] text-center">
                                                                        Clique e
                                                                        arraste
                                                                        os cards
                                                                        <br />
                                                                        para
                                                                        alterar
                                                                        o status
                                                                    </p>
                                                                </div>
                                                                {
                                                                    provided.placeholder
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                    return (
                                                        <div
                                                            className="flex flex-col h-[80vh] w-[19vw] overflow-scroll scrollbar overflow-x-hidden items-center"
                                                            {...provided.droppableProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                        >
                                                            {column.items.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        !onFilterText(
                                                                            item.NOME__c,
                                                                            item.EMAIL__c,
                                                                            item.CELULAR__c ||
                                                                                item.TELEFONE__c,
                                                                            item.Campanha__c
                                                                        ) ||
                                                                        !onFilterSelect(
                                                                            item.Campanha__c
                                                                        )
                                                                    )
                                                                        return;
                                                                    return (
                                                                        <Draggable
                                                                            key={
                                                                                item.ID_LEAD__c
                                                                            }
                                                                            draggableId={
                                                                                item.ID_LEAD__c
                                                                            }
                                                                            index={
                                                                                index
                                                                            }
                                                                        >
                                                                            {(
                                                                                provided
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        className={
                                                                                            item.SOLICITOU_CONTATO__c ===
                                                                                            'true'
                                                                                                ? `flex flex-col bg-[#fff] rounded-xl justify-start m-[1vh] border-l-[0.5vh] border-[#FF5000] w-[18vw] pl-[2vh] py-[1vh] shadow-[0px_0px_5px_rgba(50,50,50,0.25)]`
                                                                                                : `flex flex-col bg-[#fff] rounded-xl justify-start m-[1vh] border-l-[0.5vh] ${column.border} w-[18vw] pl-[2vh] py-[1vh] shadow-[0px_0px_5px_rgba(50,50,50,0.25)]`
                                                                                        }
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <div className="flex items-start justify-between">
                                                                                            <div className="font-bold text-[2.1vh]">
                                                                                                {
                                                                                                    item.NOME__c
                                                                                                }
                                                                                            </div>
                                                                                            <div className="pr-5">
                                                                                                {item.SOLICITOU_CONTATO__c ===
                                                                                                    'true' && (
                                                                                                    <FireIcon />
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.EMAIL__c && (
                                                                                            <button
                                                                                                className="flex justify-start items-center text-[2vh] mb-[0.4rem] cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    copyText(
                                                                                                        item.EMAIL__c
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <div className="items-center flex mr-[0.4rem text-[1.6vh] break-words">
                                                                                                    <RiFileCopy2Line color="#A8A8A8" />
                                                                                                    {
                                                                                                        item.EMAIL__c
                                                                                                    }
                                                                                                </div>
                                                                                            </button>
                                                                                        )}
                                                                                        <button
                                                                                            style={{
                                                                                                display:
                                                                                                    (item.TELEFONE__c ||
                                                                                                        item.CELULAR__c ||
                                                                                                        '') ===
                                                                                                    '0'
                                                                                                        ? 'none'
                                                                                                        : 'flex',
                                                                                            }}
                                                                                            className="flex justify-start items-center mb-[0.4rem] text-[1.6vh] break-words cursor-pointer"
                                                                                            onClick={() => {
                                                                                                copyText(
                                                                                                    checkHasVisualization(
                                                                                                        userRef.current,
                                                                                                        item.USUARIOS_VISUALIZACOES__c
                                                                                                    )
                                                                                                        ? item.CELULAR__c ||
                                                                                                              item.TELEFONE__c
                                                                                                        : ''
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <div className="flex items-center mr-[0.4rem] text-[1.6vh] break-words">
                                                                                                <RiFileCopy2Line color="#A8A8A8" />

                                                                                                <ShowOrHidePhone
                                                                                                    phone={
                                                                                                        item.TELEFONE__c ||
                                                                                                        ''
                                                                                                    }
                                                                                                    celphone={
                                                                                                        item.CELULAR__c ||
                                                                                                        ''
                                                                                                    }
                                                                                                    canShow={checkHasVisualization(
                                                                                                        userRef.current,
                                                                                                        item.USUARIOS_VISUALIZACOES__c
                                                                                                    )}
                                                                                                />
                                                                                            </div>
                                                                                        </button>
                                                                                        {item.Campanha__c ===
                                                                                        'Sem Campanha' ? (
                                                                                            <>

                                                                                            </>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={
                                                                                                    item.SOLICITOU_CONTATO__c ===
                                                                                                    'true'
                                                                                                        ? `my-[1.5vh] p-[0.5rem] rounded-3xl bg-[#FF5000] max-w-[16vw] text-[1.5vh] text-[#FFFFFF] flex justify-center`
                                                                                                        : `my-[1.5vh] p-[0.5rem] rounded-3xl bg-[${column.color}] max-w-[16vw] text-[1.5vh] text-[#FFFFFF] flex justify-center`
                                                                                                }
                                                                                            >
                                                                                                <span>
                                                                                                    {item
                                                                                                        .Campanha__c
                                                                                                        ?.length >=
                                                                                                    20
                                                                                                        ? item.Campanha__c?.substring(
                                                                                                              0,
                                                                                                              30
                                                                                                          ) +
                                                                                                          '...'
                                                                                                        : item.Campanha__c}
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                        {item.NOME_CORRETOR__c && (
                                                                                            <div className="">
                                                                                                <p className="font-bold sm:text-[2vh]">
                                                                                                    Corretora
                                                                                                </p>
                                                                                                <p className="sm:text-[2vh]">
                                                                                                    {
                                                                                                        item.NOME_CORRETOR__c
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                        <div className="">
                                                                                            <p className="font-bold sm:text-[2vh]">
                                                                                                Agendamento:
                                                                                            </p>
                                                                                            <p className="sm:text-[2vh]">
                                                                                                {item.DATA_AGENDAMENTO__c
                                                                                                    ? new Intl.DateTimeFormat(
                                                                                                          'pt-BR',
                                                                                                          {
                                                                                                              timeZone:
                                                                                                                  'America/Sao_Paulo',
                                                                                                          }
                                                                                                      ).format(
                                                                                                          new Date(
                                                                                                              item
                                                                                                                  .DATA_AGENDAMENTO__c
                                                                                                                  ?._seconds *
                                                                                                                  1000
                                                                                                          )
                                                                                                      )
                                                                                                    : 'Sem agendamento'}
                                                                                            </p>
                                                                                        </div>

                                                                                        <div className="flex justify-start">
                                                                                            <button
                                                                                                className={
                                                                                                    item.SOLICITOU_CONTATO__c ===
                                                                                                    'true'
                                                                                                        ? `justify-center text-white mt-[0.5rem] border-[0.1rem] flex items-center w-[16vw] h-[4.5vh] bg-[#FF5000] rounded-3xl text-[1.8vh] cursor-pointer`
                                                                                                        : `justify-center text-white mt-[0.5rem] border-[0.1rem] flex items-center w-[16vw] h-[4.5vh] bg-[${column.color}] rounded-3xl text-[1.8vh] cursor-pointer`
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    handleShowCelular(
                                                                                                        item.ID_LEAD__c
                                                                                                    );
                                                                                                    // navigate(`/leads/${item.ID_LEAD__c}`);
                                                                                                    setOpenDetails(
                                                                                                        uuid()
                                                                                                    );
                                                                                                    setCanOpenDetails(
                                                                                                        true
                                                                                                    );
                                                                                                    setLeadDetails(
                                                                                                        {
                                                                                                            ...item,
                                                                                                            cor_coluna:
                                                                                                                item.SOLICITOU_CONTATO__c ===
                                                                                                                'true'
                                                                                                                    ? 'bg-[#FF5000]'
                                                                                                                    : 'bg-[#001E64]',
                                                                                                            nome_coluna:
                                                                                                                column.name,
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span className="mr-1">
                                                                                                    <AiOutlineEye />
                                                                                                </span>
                                                                                                Detalhes
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                }
                                                            )}
                                                            {
                                                                provided.placeholder
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            </Droppable>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="w-[100%] justify-center">
                            <>Loading</>
                        </div>
                    )}
                </DragDropContext>
            ) : null}
            <ModalConfirmStatus
                openModal={openChangeStatus}
                canOpen={canChangeStatus}
                status={saveStatus}
                lead={leadTransmissao}
                isKanbanResponsive={isKanbanResponsive}
                leads={leads}
                setLeads={setLeads}
            />
            <ModalDetails
                openModal={openDetails}
                canOpen={canOpenDetails}
                lead={leadDetails}
            />
        </div>
    );
}

export default Kanban;

function FireIcon() {
    return (
        <svg
            width="22"
            height="30"
            viewBox="0 0 22 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.142658 21.8707C1.00102 27.0952 5.66894 29.4671 7.8956 30C6.53426 28.7302 4.16461 25.0952 5.57675 20.7143C6.9889 16.3333 10.0416 13.6961 11.3915 12.9252C7.48038 18.8095 10.0416 23.7755 12.5336 23.9456C14.5272 24.0816 14.8641 22.3923 14.7834 21.5306C18.4937 25.2041 16.4678 28.4807 14.991 29.6599C24.7653 25.034 22.2248 16.576 19.7328 12.9252C20.1135 15.1701 18.1407 16.8707 16.2024 15.7823C14.2642 14.6939 14.991 12.9932 15.3025 11.9728C15.614 10.9524 16.3409 8.33333 14.991 5.13605C13.9112 2.57823 11.2415 0.646259 10.0416 0C10.2839 3.43537 8.69166 5.7483 6.51115 8.33333C4.33063 10.9184 -0.930294 15.3401 0.142658 21.8707Z"
                fill="#FF5000"
            />
        </svg>
    );
}
