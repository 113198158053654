import 'react-datepicker/dist/react-datepicker.css';

import { Dialog, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
    AiOutlineCalendar,
    AiOutlineClockCircle,
    AiOutlineClose,
    AiOutlineMail,
    AiOutlineWhatsApp,
} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { api } from '../services/api';
import { delay } from '../utils/delay';
import { getTelephoneOrCellPhoneNumber } from '../utils/getTelephoneOrCellPhoneNumber';

export default function ModalDetails({ openModal, canOpen, lead }: any) {
    const navigate = useNavigate();

    // switch (lead.nome_coluna) {
    //     case 'Novas Oportunidades':
    //         style = 'bg-[#FA6B25] px-3 rounded-md text-white';
    //         break;
    //     case 'Fazer novo contato':
    //         style = 'bg-[#146CFA] px-3 rounded-md text-white';
    //         break;
    //     case 'Em negociação':
    //         style = 'bg-[#00335E] px-3 rounded-md text-white';
    //         break;
    //     case 'Concluído':
    //         style = 'bg-[#006217] px-3 rounded-md text-white';
    //         break;
    // }
    const [open, setOpen] = useState<any>(false);
    const [history, setHistory] = useState<any>([
        { last_modification: '15/01/1992 00:01' },
        { last_modification: '15/01/1992 00:01' },
    ]);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [semInteresseLoading, setSemInteresseLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>(null);

    const [anotacoes, setAnotacoes] = useState('');
    const [percepcao, setPercepcao] = useState('');
    const [loadingSaveDetails, setSaveDetails] = useState(false);

    const [details, setDetails] = useState({
        DATA_ULTIMA_VISUALIZACAO__c: null,
        EMAIL__c: '',
        DATA_SOLICITACAO_CONTATO__c: null,
        ABORDAGEM_N3__c: null,
        EventUuid: '',
        _EventType: '',
        ESTRUTURA_APOIO__c: null,
        OBJETIVO_CAMPANHA__c: '',
        NOME_CORRETOR__c: '',
        CreatedById: '',
        ACAO_APOIO__c: null,
        NOME__c: '',
        PDN_LEADUPDATE__c: '',
        CODIGO_PRODUTOR__c: '',
        ID_MDM__c: '',
        PAPEL__c: '',
        SOLICITOU_CONTATO__c: null,
        TELEFONE__c: null,
        EXPORTADO_CSV__c: null,
        _ObjectType: '',
        STATUS__c: '',
        DATA_CAMPANHA__c: '',
        ABORDAGEM_N2__c: null,
        RATING__c: null,
        LOTE__c: '',
        PDN_NomeEstipulante__c: null,
        DESCRITIVO_CAMPANHA__c: '',
        ABORDAGEM__c: null,
        CELULAR__c: '',
        Campanha__c: '',
        ESTRUTURA_VENDA__c: '',
        ReplayId: '',
        CPF__c: null,
        CreatedDate: 0,
        ID_LEAD__c: '',
        PDN_ProdutoAtual__c: null,
        INSCRICAO__c: null,
        DATA_ULTIMA_ALTERACAO__c: null,
        LOGIN__c: '',
        NOME_ESTIPULANTE__c: '',
        PRODUTO_ATUAL__c: '',
        SEGMENTO__c: '',
        nome_coluna: '',
        cor_coluna: '',
        DATA_AGENDAMENTO__c: { _seconds: 0 },
        PERCEPCAO__c: '',
        CNPJ__c: '',
        MENSAGEM__c: '',
    });
    const cancelButtonRef = useRef(null);

    const setReason = (reason: string) => {
        switch (reason) {
            case 'nao-atendeu':
                return 'Não Atendeu';
            case 'sem-interesse':
                return 'Cliente sem interesse';
            case 'retorno-cliente':
                return 'Aguardando retorno do cliente';
            case 'transmissao':
                return 'Transmissão Realizada';
            case 'preco-alto':
                return 'Preço Alto (Recusa)';
            case 'outra-operadora':
                return 'Optou por outra operadora (Recusa)';
            case 'recusa-credenciada':
                return 'Recusa pela rede credenciada';
            case 'contato-incorreto':
                return 'Dados de contato incorreto';
            case 'retornar-depois':
                return 'Pediu para retornar mais tarde';
            case 'retomado':
                return 'Cliente retomado';
            case 'Lead visualizado':
                return 'Lead visualizado';
            case 'nao-responde':
                return 'Não responde | Sem interação nos contatos realizados';
            case 'dados-invalidos':
                return 'Não responde | Sem interação nos contatos realizados';
            case 'preco':
                return 'Preço';
            case 'fora-rede':
                return 'Fora da Rede Credenciada | Fora da área de Abrangência | Localização';
            case 'concorrencia':
                return 'Concorrência | Fechou com outra Seguradora | Atendido por outro Corretor';
            case 'cnpj-inativo':
                return 'CNPJ Inativo | Emissão de Certidão Negativa';
            case 'mei-menos':
                return 'Lead fora da regra de aceitação | MEI menos de 6 meses';
            case 'pj-1-vida':
                return 'Lead fora da regra de aceitação | PJ para 1 vida';
            case 'incluir-pai-mae':
                return 'Lead fora da regra de aceitação | Incluir pai ou mãe sem plano anterior';
            case 'gympass':
                return 'Já é cliente | Interesse em ativar Gympass ou tirar dúvidas';
            case 'outros-produtos':
                return 'Interesse em outros produtos';
            case 'converteu-odonto':
                return 'Interesse em outros produtos | Converteu Odonto';
            case 'converteu-vida':
                return 'Interesse em outros produtos | Converteu Vida';
            case 'recusa-sulamerica-dps':
                return 'Recusa SulAmérica DPS';
            default:
                return false;
        }
    };
    const getHistoryData = async () => {
        setHistoryLoading(true);

        try {
            const user =
                sessionStorage.getItem('@grana-extra') !== 'null'
                    ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                    : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

            const { data } = await api.get(
                `/v1/leads/consultar-lead-historico/${lead.ID_LEAD__c}`,
                {
                    headers: { Authorization: user.access_token },
                    timeout: 0,
                }
            );
            setHistory(data);
            setHistoryLoading(false);
        } catch (error: any) {
            if (
                error.response.data.data.error_message ===
                'Lead não pertence ao usuário'
            ) {
                toast.error('Lead não pertence mais ao usuário', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });

                setHistory([]);
                setHistoryLoading(false);

                await delay(3000);

                navigate(0);
            } else {
                toast.error('Ocorreu um erro, favor tente novamente', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }

            setHistory([]);
            setHistoryLoading(false);
        }
    };

    // eslint-disable-next-line react/display-name
    const CustomDatepicker = forwardRef(({ value, onClick }: any, ref: any) => (
        <button
            className=" border-[#001E64] border-[0.1rem] px-4 py-2 rounded-full bg-white flex items-center"
            onClick={onClick}
            ref={ref}
        >
            {startDate !== null ? value : '--/--/--'}{' '}
            <span className="ml-4">
                <AiOutlineCalendar size="1.2rem" />
            </span>
        </button>
    ));
    const updateSemInteresse = async () => {
        setSemInteresseLoading(true);
        const isInteressado =
            lead.ABORDAGEM_N2__c === 'sem-interesse'
                ? {
                      id: lead.ID_LEAD__c,
                      abordagem: false,
                      abordagem_n2: 'retomado',
                      abordagem_n3: '',
                      rating: 0,
                      status: null,
                  }
                : {
                      id: lead.ID_LEAD__c,
                      abordagem: false,
                      abordagem_n2: 'sem-interesse',
                      abordagem_n3: '',
                      rating: 0,
                      status: 'Concluido',
                  };
        try {
            const user =
                sessionStorage.getItem('@grana-extra') !== 'null'
                    ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                    : JSON.parse(localStorage.getItem('@grana-extra') || '{}');
            const { data } = await api.patch(
                `/v1/leads/atualizar-lead/`,
                isInteressado,
                { headers: { Authorization: user.access_token } }
            );
            setOpen(false);
            toast.success('Atualizado lead com sucesso', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            location.reload();
        } catch (error) {
            if (
                error.response.data.data.error_message ===
                'Lead não pertence ao usuário'
            ) {
                toast.error('Lead não pertence mais ao usuário', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });

                setSemInteresseLoading(false);
                setOpen(false);

                await delay(3000);

                history(0);
            } else {
                toast.error('Ocorreu um erro, favor tente novamente', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }

            setSemInteresseLoading(false);
            setOpen(false);
        }
    };
    const onSaveDetails = async () => {
        setSaveDetails(true);
        try {
            if (percepcao === 'Não tem interesse') {
                await updateSemInteresse();
            }
            const user =
                sessionStorage.getItem('@grana-extra') !== 'null'
                    ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                    : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

            await api.patch(
                `/v1/leads/atualizar-detalhes-lead`,
                {
                    id: details.ID_LEAD__c,
                    data_agendamento: startDate || null,
                    percepcao: {
                        percepcao: percepcao === '' ? null : percepcao,
                        anotacoes: anotacoes === '' ? null : anotacoes,
                    },
                },
                { headers: { Authorization: user.access_token } }
            );

            toast.success('Detalhes salvo com sucesso', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            setStartDate(null);
            setPercepcao('');
            setAnotacoes('');

            setOpen(false);
            setSaveDetails(false);
            window.location.reload();
        } catch (err) {
            setStartDate(null);
            setPercepcao('');
            setAnotacoes('');

            setOpen(false);
            setSaveDetails(false);
        }
    };

    const handleFormatDate = (date: string) => {
        const mounths = [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
        ];

        const dateSplit = date.split('/');

        const index = parseInt(dateSplit[1], 10) - 1;

        const formatedDate = `${dateSplit[0]} de ${mounths[index]}`;

        return formatedDate;
    };

    useEffect(() => {
        if (canOpen) {
            setOpen(true);
            getHistoryData();
        }
    }, [openModal]);
    useEffect(() => {
        setDetails(lead);
        setStartDate(
            lead.DATA_AGENDAMENTO__c
                ? new Date(lead.DATA_AGENDAMENTO__c?._seconds * 1000)
                : null
        );
        setAnotacoes(lead.ANOTACOES__c || '');
        setPercepcao(lead.PERCEPCAO__c || '');
    }, [lead]);

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="">
                                    <div className=" bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-md">
                                        <div className="sm:flex mb:flex-row sm:flex-col sm:items-start ">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900 flex justify-between items-center"
                                                >
                                                    <span className="flex">
                                                        Detalhes
                                                        <span className="mb:hidden sm:flex">
                                                            &nbsp; - &nbsp;
                                                            <span
                                                                className={`${lead.cor_coluna} px-3 rounded-md text-white`}
                                                            >
                                                                {details?.nome_coluna ||
                                                                    ''}
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <button
                                                        onClick={() => {
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        <AiOutlineClose size="1.5rem" />
                                                    </button>
                                                </Dialog.Title>
                                                <div className="pb-[1rem]">
                                                    <div className="border-b-1 border-[#E3E3E3] h-0 my-[1rem]" />
                                                    <div className="flex mb:flex-col mb:gap-[10px] sm:flex-row">
                                                        <div className="mb:w-full lg-w-[40%] bg-[#E3E3E3] rounded-2xl sm:mr-[2%] mb:p-[20px] sm:p-[2vh]">
                                                            <div className="flex flex-col items-start gap-[7px]">
                                                                <p className="font-bold mb-[1vh] ">
                                                                    Dados do
                                                                    Cliente
                                                                </p>
                                                                <div className="flex">
                                                                    <p className="font-bold">
                                                                        Dados de
                                                                        criação:
                                                                    </p>
                                                                    &nbsp;
                                                                    <p>
                                                                        {format(
                                                                            new Date(
                                                                                details.CreatedDate
                                                                            ),
                                                                            "dd/MM/yyyy 'às' HH:mm"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="flex">
                                                                    <p className="font-bold">
                                                                        ID_MDM:
                                                                    </p>
                                                                    &nbsp;
                                                                    <p>
                                                                        {
                                                                            details.ID_MDM__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="flex">
                                                                    <p className="font-bold">
                                                                        E-mail:
                                                                    </p>
                                                                    &nbsp;
                                                                    <p>
                                                                        {
                                                                            details.EMAIL__c
                                                                        }
                                                                    </p>
                                                                    &nbsp;
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Número
                                                                            de
                                                                            Contato:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {getTelephoneOrCellPhoneNumber(
                                                                            details.TELEFONE__c,
                                                                            details.CELULAR__c
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Nome:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.NOME__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Nome
                                                                            Estipulante:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {details.NOME_ESTIPULANTE__c ||
                                                                            ''}
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Produto
                                                                            Atual:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.PRODUTO_ATUAL__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Segmento:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.SEGMENTO__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p className="">
                                                                        <strong>
                                                                            CPF:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {details
                                                                            .CPF__c
                                                                            ?.length ===
                                                                        11
                                                                            ? details.CPF__c.replace(
                                                                                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                                                  '$1.$2.$3-$4'
                                                                              )
                                                                            : details.CPF__c?.replace(
                                                                                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                                                  '$1 $2 $3/$4-$5'
                                                                              )}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1">
                                                                    <p className="font-bold">
                                                                        CNPJ:
                                                                    </p>
                                                                    <p>
                                                                        {details
                                                                            .CNPJ__c
                                                                            ?.length >
                                                                        11
                                                                            ? details.CNPJ__c?.replace(
                                                                                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                                                  '$1.$2.$3/$4-$5'
                                                                              )
                                                                            : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb:w-full lg-w-[58%] flex flex-col justify-between bg-[#E3E3E3] rounded-2xl mb:p-[20px] sm:p-[2vh]">
                                                            <div className="flex flex-col items-start gap-[7px]">
                                                                <p className="font-bold mb-[1vh]">
                                                                    Dados da
                                                                    Campanha
                                                                </p>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Campanha:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.Campanha__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Lote:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.LOTE__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Data
                                                                            campanha:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.DATA_CAMPANHA__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Objetivo
                                                                            campanha:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.OBJETIVO_CAMPANHA__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="text-start">
                                                                    <p>
                                                                        <strong>
                                                                            Descritivo
                                                                            campanha:
                                                                        </strong>
                                                                        &nbsp;
                                                                        {
                                                                            details.DESCRITIVO_CAMPANHA__c
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-[1.5rem] w-[100%] bg-[#E3E3E3] py-3 rounded-md">
                                                        <div className="flex flex-col justify-between items-start px-3">
                                                            <span className="font-bold my-2">
                                                                Informações
                                                                adicionais:
                                                            </span>
                                                            <textarea
                                                                className={
                                                                    !details.MENSAGEM__c
                                                                        ? 'text-gray-400 w-[100%] rounded-md'
                                                                        : 'bg-white w-[100%] rounded-md'
                                                                }
                                                                rows={3}
                                                                readOnly
                                                                disabled={
                                                                    !details.MENSAGEM__c
                                                                }
                                                            >
                                                                {details?.MENSAGEM__c ||
                                                                    'Nenhuma informação adicional.'}
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="mb:mt-[10px] sm:mt-[1.5rem] bg-[#E3E3E3] rounded-2xl">
                                                            <div className="flex text-start gap-[7px] p-[20px] mb:flex-col sm:flex-row sm:justify-between sm:items-center">
                                                                <p>
                                                                    <strong>
                                                                        Corretora:
                                                                    </strong>
                                                                    &nbsp;
                                                                    {
                                                                        details.NOME_CORRETOR__c
                                                                    }
                                                                </p>
                                                                <p className="mb:block sm:hidden">
                                                                    <strong>
                                                                        EV:
                                                                    </strong>
                                                                    &nbsp;
                                                                    {
                                                                        details.ESTRUTURA_VENDA__c
                                                                    }
                                                                </p>
                                                                <p className="mb:hidden sm:block font-bold pl-[1vw] bg-[#001E64] pr-[1rem] rounded-[0_0.4rem_0.4rem_0] py-[0.7rem] text-white">
                                                                    EV:
                                                                    {
                                                                        details.ESTRUTURA_VENDA__c
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb:block sm:flex justify-between">
                                                        <div className="mb:hidden sm:block mt-[1.5rem] w-[49%] bg-[#E3E3E3] rounded-md p-[1rem]">
                                                            <div className="flex items-start flex-col w-[100%]">
                                                                <p className="mb-[1rem] font-bold">
                                                                    Agendar
                                                                    contato com
                                                                    lead
                                                                </p>
                                                                <DatePicker
                                                                    customInput={
                                                                        <CustomDatepicker />
                                                                    }
                                                                    placeholderText="--/--/--"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    minDate={
                                                                        new Date()
                                                                    }
                                                                    renderCustomHeader={({
                                                                        monthDate,
                                                                        customHeaderCount,
                                                                        decreaseMonth,
                                                                        increaseMonth,
                                                                    }) => (
                                                                        <div>
                                                                            <button
                                                                                aria-label="Previous Month"
                                                                                className={
                                                                                    'react-datepicker__navigation react-datepicker__navigation--previous'
                                                                                }
                                                                                style={
                                                                                    customHeaderCount ===
                                                                                    1
                                                                                        ? {
                                                                                              visibility:
                                                                                                  'hidden',
                                                                                          }
                                                                                        : null
                                                                                }
                                                                                onClick={
                                                                                    decreaseMonth
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className={
                                                                                        'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        '<'
                                                                                    }
                                                                                </span>
                                                                            </button>
                                                                            <span className="react-datepicker__current-month">
                                                                                {monthDate.toLocaleString(
                                                                                    'pt-BR',
                                                                                    {
                                                                                        month: 'long',
                                                                                        year: 'numeric',
                                                                                    }
                                                                                )}
                                                                            </span>
                                                                            <button
                                                                                aria-label="Next Month"
                                                                                className={
                                                                                    'react-datepicker__navigation react-datepicker__navigation--next'
                                                                                }
                                                                                onClick={
                                                                                    increaseMonth
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className={
                                                                                        'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        '>'
                                                                                    }
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    selected={
                                                                        startDate ||
                                                                        null
                                                                    }
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        setStartDate(
                                                                            date
                                                                        );
                                                                    }}
                                                                    monthsShown={
                                                                        1
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="relative py-4 my-[0.8rem]">
                                                                <div className="absolute inset-0 flex items-center">
                                                                    <div className="w-full border-b border-gray-300"></div>
                                                                </div>
                                                            </div>
                                                            <div className=" flex-col h-[5vh]">
                                                                <p className="font-bold">
                                                                    Entrar em
                                                                    contato
                                                                </p>
                                                                <div className="flex gap-2">
                                                                    <button
                                                                        className="text-white bg-[#1BD741] px-[1rem] py-[0.5rem] rounded-md cursor-pointer "
                                                                        onClick={() => {
                                                                            window.open(
                                                                                `https://api.whatsapp.com/send?phone=${details.CELULAR__c}`,
                                                                                '_blank'
                                                                            );
                                                                        }}
                                                                    >
                                                                        <AiOutlineWhatsApp />
                                                                    </button>
                                                                    <a
                                                                        href={`mailto:${details.EMAIL__c}`}
                                                                    >
                                                                        <div className="text-white bg-[#146CFA] px-[1rem] py-[0.5rem] rounded-md">
                                                                            <AiOutlineMail />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb:hidden sm:block mt-[1.5rem] w-[49%] bg-[#E3E3E3] rounded-md">
                                                            <div className="flex justify-cneter items-center flex-col h-[100%]">
                                                                <p className="mt-[1rem] text-start w-[90%] font-bold">
                                                                    Percepção do
                                                                    contato com
                                                                    lead
                                                                </p>
                                                                <div className="flex w-[90%] justify-between my-3">
                                                                    <div className="flex items-center mb-4 mr-2 ">
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setPercepcao(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            defaultChecked={
                                                                                details.PERCEPCAO__c ===
                                                                                'Quente'
                                                                            }
                                                                            id="pme"
                                                                            type="radio"
                                                                            value="Quente"
                                                                            name="proposta"
                                                                            className="checked:dark-blue checked:hover:dark-blue checked:active:dark-blue checked:focus:dark-blue focus:dark-blue focus:outline-none focus:ring-1 focus:ring-dark-blue"
                                                                        />
                                                                        <label
                                                                            htmlFor="pme"
                                                                            className="ml-2 text-sm font-medium text-gray-900"
                                                                        >
                                                                            Quente
                                                                        </label>
                                                                    </div>
                                                                    <div className="flex items-center mb-4 md:mx-2">
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setPercepcao(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            id="pmeMais"
                                                                            defaultChecked={
                                                                                details.PERCEPCAO__c ===
                                                                                'Morno'
                                                                            }
                                                                            type="radio"
                                                                            value="Morno"
                                                                            name="proposta"
                                                                            className="checked:dark-blue checked:hover:dark-blue checked:active:dark-blue checked:focus:dark-blue focus:dark-blue focus:outline-none focus:ring-1 focus:ring-dark-blue"
                                                                        />
                                                                        <label
                                                                            htmlFor="pmeMais"
                                                                            className="ml-2 text-sm font-medium text-gray-900 "
                                                                        >
                                                                            Morno
                                                                        </label>
                                                                    </div>
                                                                    <div className="flex items-center mb-4 md:mx-2">
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setPercepcao(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            defaultChecked={
                                                                                details.PERCEPCAO__c ===
                                                                                'Frio'
                                                                            }
                                                                            id="empresarial"
                                                                            type="radio"
                                                                            value="Frio"
                                                                            name="proposta"
                                                                            className="checked:dark-blue checked:hover:dark-blue checked:active:dark-blue checked:focus:dark-blue focus:dark-blue focus:outline-none focus:ring-1 focus:ring-dark-blue"
                                                                        />
                                                                        <label
                                                                            htmlFor="empresarial"
                                                                            className="ml-2 text-sm font-medium text-gray-900 "
                                                                        >
                                                                            Frio
                                                                        </label>
                                                                    </div>
                                                                    <div className="flex items-center mb-4 md:mx-2">
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setPercepcao(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            defaultChecked={
                                                                                details.PERCEPCAO__c ===
                                                                                'Não tem interesse'
                                                                            }
                                                                            id="vidaIndividual"
                                                                            type="radio"
                                                                            value="Não tem interesse"
                                                                            name="proposta"
                                                                            className="checked:dark-blue checked:hover:dark-blue checked:active:dark-blue checked:focus:dark-blue focus:dark-blue focus:outline-none focus:ring-1 focus:ring-dark-blue"
                                                                        />
                                                                        <label
                                                                            htmlFor="vidaIndividual"
                                                                            className="ml-2 text-sm font-medium text-gray-900 "
                                                                        >
                                                                            Não
                                                                            tem
                                                                            interesse
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <textarea
                                                                    maxLength={
                                                                        500
                                                                    }
                                                                    className="border-[#D2D2D2] border-solid border-[1px] min-h-[6rem] resize-none bg-white w-[90%]"
                                                                    value={
                                                                        anotacoes ||
                                                                        ''
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setAnotacoes(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="Anotações"
                                                                />
                                                                <p className="w-[90%] my-[1rem] text-end text-[0.8rem]">
                                                                    {anotacoes?.length ||
                                                                        0}
                                                                    /500
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="mb:hidden lg:block mt-[1.5rem] bg-[#E3E3E3] rounded-2xl px-[2vh] py-[2vh]">
                                                            <div className="flex flex-col items-start  max-h-[30vh] overflow-y-auto overflow-x-hidden">
                                                                <p className="font-bold pr-[0.2vw]">
                                                                    Histórico:
                                                                </p>
                                                                <div className="flex items-center mb-[1rem] flex-col">
                                                                    {!historyLoading &&
                                                                    history.length >
                                                                        0 ? (
                                                                        <div>
                                                                            <table className="border-spacing-0 w-[80vw]">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className=" w-[80vw]">
                                                                                    {history.map(
                                                                                        (
                                                                                            item: any,
                                                                                            index: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    <tr
                                                                                                        className={
                                                                                                            index %
                                                                                                                2 ===
                                                                                                            0
                                                                                                                ? 'w-[100%] flex items-center justify-between'
                                                                                                                : 'bg-[#efefef] w-[100%] flex items-center justify-between'
                                                                                                        }
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <td className="pl-[0.5rem]">
                                                                                                            <span className="font-bold">
                                                                                                                Motivo:
                                                                                                            </span>
                                                                                                            {(item.TIPO_LOG__c ===
                                                                                                                'Atualizado' &&
                                                                                                                setReason(
                                                                                                                    item.ABORDAGEM_N3__c ===
                                                                                                                        '' ||
                                                                                                                        item.ABORDAGEM_N3__c ===
                                                                                                                            null
                                                                                                                        ? item.ABORDAGEM_N2__c
                                                                                                                        : item.ABORDAGEM_N3__c
                                                                                                                )) ||
                                                                                                                ''}
                                                                                                            {item.TIPO_LOG__c ===
                                                                                                                'Percepção' &&
                                                                                                                'Percepção do contato: ' +
                                                                                                                    item.PERCEPCAO__c}
                                                                                                            {item.TIPO_LOG__c ===
                                                                                                                'Agendamento' &&
                                                                                                                'Agendou contato com lead para: ' +
                                                                                                                    new Intl.DateTimeFormat(
                                                                                                                        'pt-BR',
                                                                                                                        {
                                                                                                                            timeZone:
                                                                                                                                'UTC',
                                                                                                                        }
                                                                                                                    ).format(
                                                                                                                        new Date(
                                                                                                                            item
                                                                                                                                .DATA_AGENDAMENTO__c
                                                                                                                                ?._seconds *
                                                                                                                                1000
                                                                                                                        )
                                                                                                                    )}
                                                                                                            {item.TIPO_LOG__c ===
                                                                                                                'Distribuição' &&
                                                                                                                'Distribuição'}
                                                                                                            {item.TIPO_LOG__c ===
                                                                                                                'Redistribuição' &&
                                                                                                                'Redistribuição'}
                                                                                                            {item.TIPO_LOG__c ===
                                                                                                                'Visualizado' &&
                                                                                                                'Visualizado'}
                                                                                                            {item.TIPO_LOG__c ===
                                                                                                                'Exportação' &&
                                                                                                                'Exportação'}
                                                                                                        </td>
                                                                                                        <td className="">
                                                                                                            <div className="flex items-center justify-end py-[0.8rem] pr-[1rem]">
                                                                                                                <AiOutlineClockCircle />
                                                                                                                <div className="pl-[0.5rem]">
                                                                                                                    {item.DATA_ULTIMA_ALTERACAO__c !==
                                                                                                                        '' &&
                                                                                                                        item.DATA_ULTIMA_ALTERACAO__c}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ) : historyLoading ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            <p>
                                                                                Nenhum
                                                                                histórico
                                                                                para
                                                                                esse
                                                                                lead
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb:hidden lg:flex justify-between">
                                            <button
                                                type="button"
                                                className=" inline-flex  justify-center h-[2.5rem] bg-white px-8 py-2 text-sm font-semibold text-[#001E64] border-[#001E64] rounded-full border-[0.1rem] sm:w-auto "
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancelar
                                            </button>
                                            {loadingSaveDetails === false ? (
                                                <button
                                                    type="button"
                                                    className="inline-flex w-[8rem] justify-center rounded-full bg-[#FA6B25] px-8 py-2 text-sm font-semibold text-white shadow-sm "
                                                    onClick={() =>
                                                        onSaveDetails()
                                                    }
                                                >
                                                    Salvar
                                                </button>
                                            ) : (
                                                <button
                                                    disabled
                                                    className="bg-[#FF5000] flex items-center justify-center text-[#EBEBEB] font-bold border-[0.1rem] w-[10rem] h-[2.5rem] rounded-3xl"
                                                >
                                                    <svg
                                                        className="animate-spin h-5 w-5 mr-3 "
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                                                            className="spinner_P7sC"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                    Processando...
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
