import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import useIsMobile from '../hooks/useIsMobile';
import { api } from '../services/api';

function AtivacaoConta() {
    const [params] = useSearchParams();

    const [windowSize] = useIsMobile();
    const isMobile = windowSize <= 768;

    const [isLoading, setIsLoading] = useState(true);

    const [success, setSuccess] = useState(true);

    const [errorMessage, setErrorMessage] = useState(
        ' Ocorreu um erro. Por favor, tente novamente em instantes'
    );

    useEffect(() => {
        if (!params.get('account_activation_token') && !params.get('id')) {
            setSuccess(false);
            return;
        }

        const account_activation_token = params.get('account_activation_token');

        const id = params.get('id');

        const confirmarEmail = async () => {
            try {
                await api.post('/v1/auth/account-activation', {
                    user_id: id,
                    token: account_activation_token,
                });

                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    setErrorMessage(
                        'Usuário ou token inválido/expirado. Favor solicitar o reenvio da ativação na tela de Login'
                    );
                }
                setIsLoading(false);
                setSuccess(false);
            }
        };

        confirmarEmail();
    }, []);

    return (
        <>
            <NavBar hideBtnCadastrar hideBtnEntrar />

            <div>
                <div
                    className={`w-full px-5 py-16 md:p-20 h-screen ${
                        isMobile ? 'bg-login-mobile' : 'bg-login'
                    } md:grid grid-cols-2 gap-4`}
                >
                    <div>
                        <h4 className="title title-regulamento font-bold text-white mb-5 md:mb-10">
                            Confirmação <br /> de e-mail
                        </h4>
                    </div>

                    <div
                        className="bg-white rounded-3xl py-10 px-5 md:px-12 max-h-[31.25rem] text-black flex flex-col items-center justify-center gap-4"
                        style={{ maxWidth: '520px', zIndex: 2 }}
                    >
                        <img
                            alt=""
                            className={`${isMobile ? 'ml-3' : 'none'}`}
                            style={{
                                maxWidth: `${isMobile ? '250px' : 'auto'}`,
                            }}
                            src="/images/logo-grana-extra-colorido.svg"
                        ></img>
                        {isLoading ? (
                            <div className="w-full flex justify-center mt-4">
                                <ClipLoader size={20} />
                            </div>
                        ) : success ? (
                            <>
                                <CheckCircleIcon className="h-20 w-20 text-success" />
                                <p className="text-[#006217] font-bold text-lg w-[10rem] text-center">
                                    E-mail confirmado com sucesso!!
                                </p>
                                <Link
                                    to="/login"
                                    className="w-full flex justify-center items-center"
                                >
                                    <button className="bg-[#FF5000] w-2/3  font-bold h-[3.25rem] text-white flex items-center text-center justify-center radius-button">
                                        Ir para o login
                                    </button>
                                </Link>
                            </>
                        ) : (
                            <>
                                <InformationCircleIcon className="h-20 w-20 text-[#AA2C23]" />
                                <p className="text-[#AA2C23] font-bold text-lg w-auto max-w-[20rem] text-center">
                                    {errorMessage}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default AtivacaoConta;
