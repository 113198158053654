import { useHeaderContext } from '../contexts/HeaderContext';
import { useMediaQuery } from '../hooks/useMediaQuery';
import NavBarAreaLogada from './NavBarAreaLogada';
import { NotificationButton } from './NotificationButton';
import Sidebar from './Sidebar';

const LayoutLeads = ({ children }: any) => {
    const { elementSize } = useHeaderContext();

    const isMobile = useMediaQuery('(max-width: 768px)');

    return (
        <>
            <NotificationButton
                rightSpace={isMobile ? '4rem' : `${elementSize}px`}
                topSpace={isMobile ? '2rem' : '2.25rem'}
            >
                <NavBarAreaLogada />
            </NotificationButton>
            <div>
                <div style={{ background: '#F4F4F4' }}>
                    <div>{children}</div>
                </div>
            </div>
        </>
    );
};

export default LayoutLeads;
