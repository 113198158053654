import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import DragContext from '../contexts/DragContext';
import RatingFormContext from '../contexts/RatingContext';
import { useProposal } from '../hooks/useProposal';
import { api } from '../services/api';
import { delay } from '../utils/delay';
import Rating from './Rating';

export default function ModalConfirmStatus({
    openModal,
    canOpen,
    status,
    lead,
    isKanbanResponsive,
}: any) {
    const defaultValues = {
        name: 'teste',
        email: '',
        cel: '',
        id: '',
        campaign: '',
        status: '',
        abordagem: {
            success: null,
            step1: '',
            step2: '',
            rating: '',
        },
    };
    const initialProposal = {
        typeProposal: '',
        person: { cpf: null },
        companies: null,
        produto: '',
        numero_proposta: '',
    };
    const { dragState, setDragState } = useContext(DragContext);
    const { ratingState, setRatingState } = useContext(RatingFormContext);
    const { updateProposal, proposal } = useProposal();
    const [open, setOpen] = useState<any>(false);
    const [leadTransmissao, setLeadTransmissao] = useState<any>();
    const [openTransmissao, setOpenTransmissao] = useState<any>(false);
    const [activeButton, setActiveButton] = useState(false);
    const [proposalTransmissao, setProposalTransmissao] =
        useState<any>(initialProposal);
    const [saveStatus, setSaveStatus] = useState(defaultValues);
    const history = useNavigate();
    const [loadingSave, setLoadingSave] = useState(false);

    const cancelButtonRef = useRef(null);
    const cancelButtonTransmissaoRef = useRef(null);

    useEffect(() => {
        if (canOpen) {
            setOpen(true);
            setSaveStatus(status);
            setLeadTransmissao(lead);
        }
    }, [openModal]);

    const saveCardStatus = async () => {
        setLoadingSave(true);

        let svStatus = saveStatus.status || null;
        let svAbordagem = saveStatus.abordagem.success;
        let svAbordagem2 = saveStatus.abordagem.step1;
        let svAbordagem3 = saveStatus.abordagem.step2;

        try {
            const user =
                sessionStorage.getItem('@grana-extra') !== 'null'
                    ? JSON.parse(sessionStorage.getItem('@grana-extra') || '{}')
                    : JSON.parse(localStorage.getItem('@grana-extra') || '{}');

            if (svAbordagem2 === 'transmissao') {
                const { data } = await api.patch(
                    `/v1/leads/atualizar-lead/`,
                    {
                        id: saveStatus.id,
                        abordagem: svAbordagem,
                        abordagem_n2: svAbordagem2,
                        abordagem_n3: svAbordagem3,
                        rating: ratingState,
                        status: 'Concluido',
                    },
                    { headers: { Authorization: user.access_token } }
                );
                toast.success('Atualizado lead com sucesso', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });

                const newProposal = {
                    typeProposal: proposalTransmissao.typeProposal,
                    cpfList:
                        leadTransmissao.CPF__c &&
                        proposalTransmissao.typeProposal === 'Protecao Saude'
                            ? [
                                  {
                                      cpf: leadTransmissao.CPF__c.replace(
                                          /[^\d]/g,
                                          ''
                                      ).replace(
                                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          '$1.$2.$3-$4'
                                      ),
                                      action: {
                                          id: 1,
                                      },
                                  },
                              ]
                            : [],
                    cnpjList:
                        leadTransmissao.CNPJ__c &&
                        proposalTransmissao.typeProposal !== 'Protecao Saude'
                            ? [
                                  {
                                      cnpj: leadTransmissao.CNPJ__c.replace(
                                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                          '$1.$2.$3/$4-$5'
                                      ),
                                      action: {
                                          id: 1,
                                      },
                                  },
                              ]
                            : [],
                    product: {
                        num_saude:
                            proposalTransmissao.produto === 'SAUDE' ||
                            proposalTransmissao.produto === 'SAUDE_ODONTO'
                                ? proposalTransmissao.numero_proposta
                                : null,
                        num_odonto:
                            proposalTransmissao.produto === 'SAUDE_ODONTO'
                                ? proposalTransmissao.numero_proposta
                                : null,
                        num_capital_global:
                            proposalTransmissao.produto === 'CAPITAL_GLOBAL'
                                ? proposalTransmissao.numero_proposta
                                : null,
                        num_protecao_saude:
                            proposalTransmissao.produto === 'PROTECAO_SAUDE'
                                ? proposalTransmissao.numero_proposta
                                : null,
                    },
                    corretora: {
                        name: leadTransmissao.NOME_CORRETOR__c,
                    },
                    productType: proposalTransmissao.produto,
                };
                updateProposal({ ...proposal, ...newProposal });
                setOpenTransmissao(true);
                setOpen(false);
                return;
            }

            const { data } = await api.patch(
                `/v1/leads/atualizar-lead/`,
                {
                    id: saveStatus.id,
                    abordagem: svAbordagem,
                    abordagem_n2: svAbordagem2,
                    abordagem_n3: svAbordagem3,
                    rating: ratingState,
                    status: svStatus,
                },
                { headers: { Authorization: user.access_token } }
            );

            toast.success('Atualizado lead com sucesso', {
                position: 'top-center',
                // autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            setOpen(false);
            setLoadingSave(false);
            setSaveStatus(defaultValues);
            setRatingState(0);
            isKanbanResponsive && window.location.reload();
        } catch (error) {
            !isKanbanResponsive && setDragState(true);

            if (
                error.response.data.data.error_message ===
                'Lead não pertence ao usuário'
            ) {
                toast.error('Lead não pertence mais ao usuário', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });

                setLoadingSave(false);
                setOpen(false);

                await delay(3000);

                history(0);
            } else {
                toast.error('Ocorreu um erro, favor tente novamente', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }

            setLoadingSave(false);
            setOpen(false);
        }
    };

    const validateButtonSave = () => {
        if (
            saveStatus.abordagem.step1 === 'cotacao' &&
            ratingState != 0 &&
            saveStatus.abordagem.success === true
        ) {
            if (saveStatus.abordagem.step2 !== '') {
                return false;
            }
        } else if (
            saveStatus.abordagem.step1 === 'transmissao' &&
            proposalTransmissao.numero_proposta !== '' &&
            proposalTransmissao.typeProposal !== '' &&
            proposalTransmissao.produto !== ''
        ) {
            return false;
        } else if (
            saveStatus.abordagem.step2 !== '' &&
            saveStatus.abordagem.success === false
        ) {
            return false;
        }
        return true;
    };

    const handleTransmissao = () => {
        setOpenTransmissao(false);
        history('/area-logada/propostas/criar');
    };
    useEffect(() => {
        const isButtonActive = validateButtonSave();
        setActiveButton(isButtonActive);
    }, [saveStatus, proposalTransmissao, ratingState]);
    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={() => {
                        // setOpen(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full tems-center items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="">
                                    <div className="bg-[#F4F4F4] rounded-md">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-base leading-6 text-gray-900 flex justify-between items-start flex-col gap-[10px] pt-[15px] px-[20px]"
                                                >
                                                    <p className="font-bold">
                                                        Como foi a abordagem do
                                                        cliente?
                                                    </p>
                                                    <p className="text-start">
                                                        Cliente: &nbsp;
                                                        <span className="font-bold">
                                                            {saveStatus.name}
                                                        </span>
                                                    </p>
                                                </Dialog.Title>
                                                <div>
                                                    <div className="mt-[20px] flex mb:flex-col lg:flex-row justify-center gap-[20px] px-[20px]">
                                                        <button
                                                            onClick={(e) => {
                                                                setSaveStatus({
                                                                    ...saveStatus,
                                                                    abordagem: {
                                                                        ...saveStatus.abordagem,
                                                                        success:
                                                                            true,
                                                                        step1: '',

                                                                        step2: '',
                                                                    },
                                                                });
                                                            }}
                                                            className={`${
                                                                saveStatus
                                                                    .abordagem
                                                                    .success ===
                                                                true
                                                                    ? 'rounded-3xl py-[0.4rem] px-[2rem] border-[0.2vh] border-[#FA6B25] bg-[#FA6B25] text-[white]'
                                                                    : 'rounded-3xl py-[0.4rem] px-[2rem] border-[0.2vh] border-[black]'
                                                            } `}
                                                        >
                                                            Sucesso no contato
                                                        </button>
                                                        <button
                                                            onClick={(e) => {
                                                                setSaveStatus({
                                                                    ...saveStatus,
                                                                    abordagem: {
                                                                        ...saveStatus.abordagem,
                                                                        success:
                                                                            false,
                                                                        step1: '',
                                                                        step2: '',
                                                                    },
                                                                });
                                                            }}
                                                            className={`${
                                                                saveStatus
                                                                    .abordagem
                                                                    .success ===
                                                                false
                                                                    ? 'rounded-3xl py-[0.4rem] px-[2rem] border-[0.2vh] border-[#FA6B25] bg-[#FA6B25] lg:ml-3 text-[white]'
                                                                    : 'rounded-3xl py-[0.4rem] px-[2rem] border-[0.2vh] border-[black] lg:ml-3'
                                                            } `}
                                                        >
                                                            Sem sucesso no
                                                            contato
                                                        </button>
                                                    </div>
                                                    {saveStatus.abordagem
                                                        .success === true && (
                                                        <div className="mt-[1rem] ">
                                                            <div className="px-[20px] mb-[1rem]">
                                                                <select
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSaveStatus(
                                                                            {
                                                                                ...saveStatus,
                                                                                abordagem:
                                                                                    {
                                                                                        ...saveStatus.abordagem,
                                                                                        step1: e
                                                                                            .target
                                                                                            .value,
                                                                                        step2: '',
                                                                                    },
                                                                            }
                                                                        );
                                                                        setRatingState(
                                                                            0
                                                                        );
                                                                        setProposalTransmissao(
                                                                            initialProposal
                                                                        );
                                                                    }}
                                                                    className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]"
                                                                >
                                                                    <option
                                                                        className="text-[#828282]"
                                                                        value=""
                                                                    >
                                                                        Selecionar
                                                                    </option>
                                                                    <option value="cotacao">
                                                                        Cotação
                                                                        Realizada
                                                                    </option>
                                                                    <option value="transmissao">
                                                                        Transmissão
                                                                        Realizada
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            {saveStatus
                                                                .abordagem
                                                                .step1 ===
                                                                'cotacao' && (
                                                                <>
                                                                    <div className="mt-[1rem]">
                                                                        <div className="px-[20px]">
                                                                            <select
                                                                                className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]"
                                                                                value={
                                                                                    saveStatus
                                                                                        .abordagem
                                                                                        .step2
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setSaveStatus(
                                                                                        {
                                                                                            ...saveStatus,
                                                                                            abordagem:
                                                                                                {
                                                                                                    ...saveStatus.abordagem,
                                                                                                    step2: e
                                                                                                        .target
                                                                                                        .value,
                                                                                                },
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <option
                                                                                    className="text-[#828282]"
                                                                                    value=""
                                                                                >
                                                                                    Selecionar
                                                                                </option>
                                                                                <option value="preco-alto">
                                                                                    Preço
                                                                                    Alto
                                                                                    (Recusa)
                                                                                </option>
                                                                                <option value="retorno-cliente">
                                                                                    Aguardando
                                                                                    retorno
                                                                                    do
                                                                                    cliente
                                                                                </option>
                                                                                <option value="outra-operadora">
                                                                                    Optou
                                                                                    por
                                                                                    outra
                                                                                    operadora
                                                                                    (Recusa)
                                                                                </option>
                                                                                <option value="recusa-credenciada">
                                                                                    Recusa
                                                                                    pela
                                                                                    rede
                                                                                    credenciada
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <Rating
                                                                        icon="star"
                                                                        scale={
                                                                            5
                                                                        }
                                                                        fillColor="gold"
                                                                        strokeColor="gold"
                                                                        onClick={() => {
                                                                            console.log(
                                                                                ratingState
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                            {saveStatus
                                                                .abordagem
                                                                .step1 ===
                                                                'transmissao' && (
                                                                <>
                                                                    <div className="px-[20px] bg-[#FFFFFF]">
                                                                        <div className="mt-[1rem] pt-[20px]">
                                                                            <p className="text-start pl-[5px] ">
                                                                                Insira
                                                                                o
                                                                                número
                                                                                da
                                                                                proposta
                                                                            </p>
                                                                            <input
                                                                                className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]"
                                                                                type="number"
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setProposalTransmissao(
                                                                                        {
                                                                                            ...proposalTransmissao,
                                                                                            numero_proposta:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-[1rem]">
                                                                            <p className="text-start pl-[5px]">
                                                                                Tipo
                                                                                da
                                                                                proposta
                                                                            </p>
                                                                            <select
                                                                                className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]"
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setProposalTransmissao(
                                                                                        {
                                                                                            ...proposalTransmissao,
                                                                                            typeProposal:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <option
                                                                                    className="text-[#828282]"
                                                                                    value=""
                                                                                >
                                                                                    Selecionar
                                                                                </option>
                                                                                <option value="PME (3 a 29 Vidas)">
                                                                                    PME
                                                                                    (2
                                                                                    a
                                                                                    29
                                                                                    Vidas)
                                                                                </option>
                                                                                <option value="PME Mais (30 a 99 Vidas)">
                                                                                    PME
                                                                                    Mais
                                                                                    (30
                                                                                    a
                                                                                    99
                                                                                    Vidas)
                                                                                </option>
                                                                                <option value="PME Empresarial (100 a 199 Vidas)">
                                                                                    PME
                                                                                    Empresarial
                                                                                    (100
                                                                                    a
                                                                                    199
                                                                                    Vidas)
                                                                                </option>
                                                                                <option value="Protecao Saude">
                                                                                    Proteção
                                                                                    Saúde
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="mt-[1em] px[20px]">
                                                                            <p className="text-start pl-[5px]">
                                                                                Produto
                                                                            </p>
                                                                            <select
                                                                                className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]"
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setProposalTransmissao(
                                                                                        {
                                                                                            ...proposalTransmissao,
                                                                                            produto:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <option
                                                                                    className="text-[#828282]"
                                                                                    value=""
                                                                                >
                                                                                    Selecionar
                                                                                </option>
                                                                                <option value="SAUDE">
                                                                                    Saúde
                                                                                </option>
                                                                                <option value="SAUDE_ODONTO">
                                                                                    Saúde
                                                                                    e
                                                                                    Odonto
                                                                                </option>
                                                                                <option value="CAPITAL_GLOBAL">
                                                                                    Capital
                                                                                    Global
                                                                                </option>
                                                                                <option value="PROTECAO_SAUDE">
                                                                                    Proteção
                                                                                    Saúde
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}

                                                    {saveStatus.abordagem
                                                        .success === false && (
                                                        <>
                                                            <div className="mt-[1rem] px-[20px] mb-[1rem]">
                                                                <select
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSaveStatus(
                                                                            {
                                                                                ...saveStatus,
                                                                                abordagem:
                                                                                    {
                                                                                        ...saveStatus.abordagem,
                                                                                        step2: e
                                                                                            .target
                                                                                            .value,
                                                                                    },
                                                                            }
                                                                        );
                                                                    }}
                                                                    className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]"
                                                                >
                                                                    <option
                                                                        className="text-[#828282]"
                                                                        value=""
                                                                    >
                                                                        Selecionar
                                                                    </option>
                                                                    <option value="nao-atendeu">
                                                                        Não
                                                                        Atendeu
                                                                    </option>
                                                                    <option value="contato-incorreto">
                                                                        Dados de
                                                                        contato
                                                                        incorreto
                                                                    </option>
                                                                    <option value="retornar-depois">
                                                                        Pediu
                                                                        para
                                                                        retornar
                                                                        mais
                                                                        tarde
                                                                    </option>
                                                                    <option value="nao-responde">
                                                                        Não
                                                                        responde
                                                                        | Sem
                                                                        interação
                                                                        nos
                                                                        contatos
                                                                        realizados
                                                                    </option>
                                                                    <option value="dados-invalidos">
                                                                        Dados
                                                                        Inválidos
                                                                    </option>
                                                                    <option value="preco">
                                                                        Preço
                                                                    </option>
                                                                    <option value="fora-rede">
                                                                        Fora da
                                                                        Rede
                                                                        Credenciada
                                                                        | Fora
                                                                        da área
                                                                        de
                                                                        Abrangência
                                                                        |
                                                                        Localização
                                                                    </option>
                                                                    <option value="concorrencia">
                                                                        Concorrência
                                                                        | Fechou
                                                                        com
                                                                        outra
                                                                        Seguradora
                                                                        |
                                                                        Atendido
                                                                        por
                                                                        outro
                                                                        Corretor
                                                                    </option>
                                                                    <option value="cnpj-inativo">
                                                                        CNPJ
                                                                        Inativo
                                                                        |
                                                                        Emissão
                                                                        de
                                                                        Certidão
                                                                        Negativa
                                                                    </option>
                                                                    <option value="mei-menos">
                                                                        Lead
                                                                        fora da
                                                                        regra de
                                                                        aceitação
                                                                        | MEI
                                                                        menos de
                                                                        6 meses
                                                                    </option>
                                                                    <option value="pj-1-vida">
                                                                        Lead
                                                                        fora da
                                                                        regra de
                                                                        aceitação
                                                                        | PJ
                                                                        para 1
                                                                        vida
                                                                    </option>
                                                                    <option value="incluir-pai-mae">
                                                                        Lead
                                                                        fora da
                                                                        regra de
                                                                        aceitação
                                                                        |
                                                                        Incluir
                                                                        pai ou
                                                                        mãe sem
                                                                        plano
                                                                        anterior
                                                                    </option>
                                                                    <option value="gympass">
                                                                        Já é
                                                                        cliente
                                                                        |
                                                                        Interesse
                                                                        em
                                                                        ativar
                                                                        Gympass
                                                                        ou tirar
                                                                        dúvidas
                                                                    </option>
                                                                    <option value="outros-produtos">
                                                                        Interesse
                                                                        em
                                                                        outros
                                                                        produtos
                                                                    </option>
                                                                    <option value="converteu-odonto">
                                                                        Interesse
                                                                        em
                                                                        outros
                                                                        produtos
                                                                        |
                                                                        Converteu
                                                                        Odonto
                                                                    </option>
                                                                    <option value="converteu-vida">
                                                                        Interesse
                                                                        em
                                                                        outros
                                                                        produtos
                                                                        |
                                                                        Converteu
                                                                        Vida
                                                                    </option>
                                                                    <option value="sem-interesse">
                                                                        Não teve
                                                                        interesse
                                                                    </option>
                                                                    <option value="recusa-sulamerica-dps">
                                                                        Recusa
                                                                        SulAmérica
                                                                        DPS
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                    {saveStatus.abordagem
                                                        .success === null && (
                                                        <>
                                                            <div className="mt-[1rem] mb-[1rem] px-[20px]">
                                                                <select className="w-[100%] rounded-3xl border-[0.2vh] border-[black] py-[0.4rem] px-[1rem]">
                                                                    <option
                                                                        className="text-[#828282]"
                                                                        value="nao-atendeu"
                                                                    >
                                                                        Selecionar
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="flex mb:justify-between lg:justify-end px-[20px] py-[20px] bg-[#FFFFFF] gap-[20px] rounded-t rounded-[15px]">
                                                        <button
                                                            disabled={
                                                                loadingSave ===
                                                                true
                                                            }
                                                            className=" rounded-3xl mr-[0.5rem]"
                                                            onClick={() => {
                                                                if (
                                                                    isKanbanResponsive
                                                                ) {
                                                                    setOpen(
                                                                        false
                                                                    );
                                                                } else {
                                                                    setSaveStatus(
                                                                        defaultValues
                                                                    );
                                                                    setRatingState(
                                                                        0
                                                                    );
                                                                    setDragState(
                                                                        true
                                                                    );
                                                                    setOpen(
                                                                        false
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Cancelar
                                                        </button>
                                                        {loadingSave !==
                                                        true ? (
                                                            <button
                                                                className={
                                                                    activeButton
                                                                        ? 'text-[white] bg-[#AAAAAA] border-[#AAAAAA] border-[0.1rem] rounded-3xl py-[0.4rem] px-[2rem]'
                                                                        : 'text-[white] bg-[#FA6B25] border-[#FA6B25] border-[0.1rem] rounded-3xl py-[0.4rem] px-[2rem]'
                                                                }
                                                                disabled={
                                                                    activeButton
                                                                }
                                                                onClick={() => {
                                                                    !activeButton &&
                                                                        saveCardStatus();
                                                                }}
                                                            >
                                                                Salvar
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="flex text-[white] bg-[#FA6B25] border-[#FA6B25] border-[0.1rem] rounded-3xl  py-[0.4rem] px-[1rem]"
                                                                disabled
                                                            >
                                                                <svg
                                                                    className="animate-spin h-5 w-5 mr-3 "
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                                                                        className="spinner_P7sC"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                                Processando...
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={openTransmissao}>
                <Dialog
                    unmount={openTransmissao === false}
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonTransmissaoRef}
                    onClose={() => {
                        // setOpenTransmissao(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-md">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900 flex justify-between items-center"
                                        >
                                            <span className="w-[100%]">
                                                Transmissão realizada
                                            </span>
                                            <button
                                                onClick={() => {
                                                    setOpenTransmissao(false);
                                                    window.location.reload();
                                                }}
                                            >
                                                <AiOutlineClose size="1.5rem" />
                                            </button>
                                        </Dialog.Title>
                                        <div className="text-[1.2rem] mt-[1rem]">
                                            <p>
                                                Você deseja cadastrar a proposta{' '}
                                                <br />
                                                <span className="font-bold">
                                                    {
                                                        proposalTransmissao.numero_proposta
                                                    }
                                                    (
                                                    {
                                                        proposalTransmissao.typeProposal
                                                    }
                                                    )
                                                </span>{' '}
                                                no grana extra?
                                            </p>
                                        </div>
                                        <div className="flex justify-end mt-[2rem]">
                                            <button
                                                className="rounded-3xl mr-[0.5rem] w-[13rem] border-solid border-[#001E64] border-[0.1rem] text-[#001E64] font-bold"
                                                onClick={() => {
                                                    window.location.reload();
                                                    setOpenTransmissao(false);
                                                }}
                                            >
                                                Ignorar
                                            </button>

                                            <button
                                                onClick={() => {
                                                    handleTransmissao();
                                                }}
                                                className="text-[white] w-[13rem] bg-[#FA6B25] border-[#FA6B25] border-[0.1rem] rounded-3xl py-[0.4rem] px-[2rem]"
                                            >
                                                Cadastrar proposta
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
