/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

const AccordionLayout = ({
    title,
    children,
    index,
    activeIndex,
    setActiveIndex,
}: any) => {
    const handleSetIndex = (index: number) => setActiveIndex(index);
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <div
                onClick={() => handleSetIndex(index)}
                className="flex justify-between p-3 mt-3 rounded bg-grey-accordion cursor-pointer"
            >
                <div className="flex">
                    <div
                        className="text-dark-blue"
                        style={{ maxWidth: isMobile ? '320px' : '600px' }}
                    >
                        {title}
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    {activeIndex === index ? (
                        <img
                            alt=""
                            src="/images/seta-accordion.svg"
                            style={{ transform: 'rotate(180deg)' }}
                        ></img>
                    ) : (
                        <img alt="" src="/images/seta-accordion.svg"></img>
                    )}
                </div>
            </div>

            {activeIndex === index && (
                <div
                    className="shadow-3xl rounded-b-2xl border-t border-zinc-300 shadow-cyan-500/50 p-4 mb-6"
                    style={{ background: 'rgba(248, 248, 248, 0.7)' }}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default AccordionLayout;
