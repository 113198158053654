import { useNavigate } from 'react-router-dom';

function FormChamado() {
    const history = useNavigate();

    return (
        <div className="p-10">
            <div className="w-full flex justify-between">
                <h4 className="title font-bold title-campanhas">
                    Área de suporte
                </h4>
            </div>
            <p className="text-black-2 mt-5 mb-5 font-bold">
                Está com dúvidas sobre suas propostas? <br /> Nos envie uma
                mensagem!
            </p>

            <div className="bg-white rounded-2xl mt-8 p-8 shadow-xl">
                <div className="mb-5 mt-10" style={{ width: '20rem' }}>
                    <label className="text-normal mb-2" htmlFor="proposta">
                        Número de proposta
                    </label>
                    <input
                        className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="proposta"
                        type="text"
                        placeholder="Número de proposta"
                    />
                </div>

                <div className="mb-6" style={{ width: '35rem' }}>
                    <div className="mb-5">
                        <label className="text-normal mb-2" htmlFor="assunto">
                            Assunto
                        </label>
                        <select
                            className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="assunto"
                            defaultValue="Selecione"
                            placeholder="Telefone"
                        >
                            <option value="" disabled selected>
                                Selecione
                            </option>
                        </select>
                    </div>
                    <div className="">
                        <textarea
                            className="border border-dark-blue rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            rows={7}
                            style={{ resize: 'none' }}
                            placeholder="Assunto"
                        />
                    </div>
                </div>

                <div className="border border-grey-border flex justify-between w-full pt-5">
                    <button
                        onClick={() => history('/area-logada-suporte')}
                        className="py-2 mr-2 px-10 text-dark-blue font-bold border border-dark-blue radius-button"
                    >
                        Voltar
                    </button>
                    <button
                        onClick={() => {}}
                        className="py-2 ml-2 px-10 text-white font-bold bg-primary hover:bg-primary-hover radius-button"
                    >
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FormChamado;
