import errorIcon from '../assets/svgs/error-icon.svg';
import { CustomIcon } from './CustomIcon';

type ToastProps = {
    typeMessage: string;
    message: string;
    onClose?: (action: 'open' | 'close', message?: string) => Promise<void>;
};

export const Toast = ({ typeMessage, message, onClose }: ToastProps) => {
    const handleTypeMessage = () => {
        let background;
        let icon;

        switch (typeMessage) {
            case 'error':
                background = 'bg-[#FACED2]';
                icon = <CustomIcon src={errorIcon} />;
                break;

            case 'warn':
                background = 'bg-[#F6E7B1]';
                icon = icon = <CustomIcon src={''} width={49} height={49} />;

                break;

            case 'success':
                background = 'bg-[#C5E7C9]';
                icon = icon = <CustomIcon src={''} width={49} height={49} />;
                break;

            default:
                background = 'bg-gray-300';
                icon = '';
                break;
        }

        const result = {
            background,
            icon,
        };
        return result;
    };

    return (
        <div
            className={`w-full max-w-[328px] md:max-w-[906px] flex justify-center items-center gap-[10px] fixed top-[10px] md:top-16 left-[16px] md:left-[20%] ${
                handleTypeMessage().background
            } py-[20px] rounded-[12px] shadow-lg`}
        >
            <span>{handleTypeMessage().icon}</span>
            {message}
            {/* <span onClick={() => onClose('close')}>
        <CloseIcon />
      </span> */}
        </div>
    );
};
